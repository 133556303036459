import {
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  BoxChart: {
    backgroundColor: '#373435', 
    marginBottom: '20px', 
    width: '100%', 
    borderRadius: '6px',
    paddingTop: '10px'
  }
}));