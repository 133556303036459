import React, { useState } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';

import Pagination from '../Pagination';
import DockCard from '../DockCard';
import ModalConfiguration from '../../components/ModalConfiguration';

import { 
  AiFillTool 
} from 'react-icons/ai';

import { 
  FaArrowUp, 
  FaArrowDown 
} from 'react-icons/fa';

import { 
  useStyles 
} from './styles';

import {  
  Grid,
  Typography,
  Button,
  Grow,
  Checkbox,
  Tooltip,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';

export default function AccordionStore({ 
  title, 
  loading, 
  storeData,
  DockId1,
  DockId2,
  DockId3,
  DockId4,
  DockNameId1,
  DockNameId2,
  DockNameId3,
  DockNameId4,
  DOCKS,
  MODE,
  STATUS
}) {
  const classes = useStyles();

  const [dataCheck , setDataCheck] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [packingperpage,] = useState(30);
  const [modalConfiguration, setModalConfiguration] = useState(false);
  const [order, setOrder] = React.useState(null);
  const [orderBy, setOrderBy] = React.useState('seller');
  const [column, setColumn] = React.useState('seller');

  const indexOfLastPage = currentPage * packingperpage;
  const indexOfFirstPage = indexOfLastPage - packingperpage;
  const currentPacking = stableSort(storeData, getSorting(order, orderBy), orderBy).slice(indexOfFirstPage, indexOfLastPage);

  const handleChangeCheckbox = () => {
    setSelectAll(selectAll === true ? false : true);
    setDataCheck(selectAll === true ? [] : storeData);
  };

  const sortColumn = (property, id) => {
    sort(property);
    setColumn(id);
  };

  function sort(property){
    setOrderBy(property);
    if(order === null){
        setOrder('desc');
    }else if(order === 'asc')
        setOrder('desc');
    else if(order === 'desc'){
        setOrder('asc');
    }
  }

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
    
  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      let order = 0;

      if(column === 'cidade'){
        order = cmp(a[0].address[0], b[0].address[0])
      }else if(column === 'seller'){
        order = cmp(a[0], b[0])
      }else{
        let item1 = (a[0].docks).filter(item => item.id === column);
        let item2 = (b[0].docks).filter(item => item.id === column);

        if(item1.length === 0){
          item1 = [{
            "name": column,
            "id": column,
            "isActive": false,
            "dockTime": "-"
          }]
        }

        if(item2.length === 0){
          item2 = [{
            "name": column,
            "id": column,
            "isActive": false,
            "dockTime": "-"
          }]
        }

        order = cmp(item1[0], item2[0]);
      }

      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map(el => el[0]);
  }
    
  function getSorting(order, orderBy) {
    return order === 'desc' ? 
    (a, b) => desc(a, b, orderBy) 
    : 
    (a, b) => -desc(a, b, orderBy);
  }

  return (
    <>
      <Accordion style={{ width: '100%', boxShadow: 'none' }}>
        <AccordionSummary
          style={{ 
            background: '#212121', 
            boxShadow: 'none', 
            padding: 0, 
            margin: 0 
          }}
          expandIcon={<ExpandMoreIcon style={{ color: '#fff' }}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ 
            background: '#212121', 
            boxShadow: 'none', 
            padding: 0, 
            margin: 0 
          }}
        >
          <Grid 
            container 
            style={{ 
              height: 'auto', 
              width: '100%', 
              marginTop: 30 
            }}
          >
            {loading ?
                <>
                  <Skeleton variant="rect" className={classes.Skeleton}/>
                  <Skeleton variant="rect" className={classes.Skeleton}/>
                  <Skeleton variant="rect" className={classes.Skeleton}/>
                  <Skeleton variant="rect" className={classes.Skeleton}/>
                </>
            :
                <>
                  <Grid item className={classes.HeaderContainer}>
                    <Grid item className={classes.CheckboxContainer}>
                      <Checkbox
                        checked={selectAll}
                        onChange={handleChangeCheckbox}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Grid>

                    {dataCheck.length > 0?
                      <Grow timeout={100} in={dataCheck.length > 0}>
                        <Grid item className={classes.TooltipContainer}>
                          <>
                            <Tooltip title="Configurações" arrow>
                              <IconButton 
                                size="small" 
                                aria-label="delete" 
                                style={{ padding: '12px' }} 
                                onClick={() => setModalConfiguration(true)}
                              >
                                <AiFillTool 
                                  size={20} 
                                  style={{ color: '#fff' }}
                                />
                              </IconButton>
                            </Tooltip>
                          </>
                        </Grid>
                      </Grow>
                    :
                      <Grid container justify="space-between" alignItems="center" style={{ width: '100%', height: '49px' }}>
                        <Grid style={{ display: 'flex', alignItems: 'center', width: '20%', justifyContent: 'center' }}>
                          <Button onClick={() => sortColumn('seller', 'seller')}>
                            <Typography className={classes.Boxwhite}>Loja</Typography>
                            {order === null ? '' : order === 'asc' && orderBy === 'seller' ? <FaArrowDown className={classes.setColorWhite} /> : order === 'desc' && orderBy === 'seller' ? <FaArrowUp className={classes.setColorWhite} /> : ''}
                          </Button>
                        </Grid>

                        <Grid className={classes.boxItem} style={{ display: 'flex', justifyContent: 'center'}}>
                          <Button onClick={() => sortColumn('cidade', 'cidade')}>
                            <Typography className={classes.Boxwhite}>Cidade</Typography>
                            {order === null ? '' : order === 'asc' && orderBy === 'cidade' ? <FaArrowDown className={classes.setColorWhite} /> : order === 'desc' && orderBy === 'cidade' ? <FaArrowUp className={classes.setColorWhite} /> : ''}
                          </Button>
                        </Grid>
                        
                        <Grid className={classes.boxItem} style={{ display: 'flex' }}>
                          <Button onClick={() => sortColumn('isActive', DockId1)}>
                            <Typography className={classes.Boxwhite}>{DockNameId1}</Typography>
                            {order === null ? '' : order === 'asc' && orderBy === 'isActive' && column === DockId1 ? <FaArrowDown className={classes.setColorWhite} /> : order === 'desc' && orderBy === 'isActive' && column === DockId1 ? <FaArrowUp className={classes.setColorWhite} /> : ''}
                          </Button>
                        </Grid>

                        <Grid className={classes.boxItem} style={{ display: 'flex'}}>
                          <Button onClick={() => sortColumn('isActive', DockId2)}>
                            <Typography className={classes.Boxwhite}>{DockNameId2}</Typography>
                            {order === null ? '' : order === 'asc' && orderBy === 'isActive' && column === DockId2 ? <FaArrowDown className={classes.setColorWhite} /> : order === 'desc' && orderBy === 'isActive' && column === DockId2 ? <FaArrowUp className={classes.setColorWhite} /> : ''}
                          </Button>
                        </Grid>
                        
                        <Grid className={classes.boxItem} style={{ display: 'flex'}}>
                          <Button onClick={() => sortColumn('isActive', DockId3)}>
                            <Typography className={classes.Boxwhite}>{DockNameId3}</Typography>
                            {order === null ? '' : order === 'asc' && orderBy === 'isActive' && column === DockId3 ? <FaArrowDown className={classes.setColorWhite} /> : order === 'desc' && orderBy === 'isActive' && column === DockId3 ? <FaArrowUp className={classes.setColorWhite} /> : ''}
                          </Button>
                        </Grid>

                        <Grid className={classes.boxItem} style={{ display: 'flex'}}>
                          <Button onClick={() => sortColumn('isActive', DockId4)}>
                            <Typography className={classes.Boxwhite}>{DockNameId4}</Typography>
                            {order === null ? '' : order === 'asc' && orderBy === 'isActive' && column === DockId4 ? <FaArrowDown className={classes.setColorWhite} /> : order === 'desc' && orderBy === 'isActive' && column === DockId4 ? <FaArrowUp className={classes.setColorWhite} /> : ''}
                          </Button>
                        </Grid>  
                      </Grid>
                    }
                  </Grid>

                  {currentPacking.map(item => {
                      return(
                        <DockCard 
                          item={item} 
                          setDataCheck={setDataCheck} 
                          dataCheck={dataCheck} 
                          checkFlag={selectAll}
                          DockId1={DockId1}
                          DockId2={DockId2}
                          DockId3={DockId3}
                          DockId4={DockId4}
                          currentPage={currentPage}
                        />
                      );
                  })}
                    
                  <Grid container justify="center" alignItems="center">
                    <Pagination
                        packingPerPage={packingperpage} 
                        totalPacking={storeData.length}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                  </Grid>

                  <ModalConfiguration 
                    open={modalConfiguration} 
                    setOpen={setModalConfiguration} 
                    data={dataCheck}
                    DOCKS={DOCKS}
                    MODE={MODE}
                    STATUS={STATUS}
                  />
                </>
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}



