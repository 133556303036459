export const styles = {
  style: {
    backgroundColor: "#DCC6B7",
    textAlign: "center",
    left: "0",
    bottom: "0",
    height: "40px",
    width: "100%",
  },
  phantom: {
    display: 'block',
    height: '0px',
    width: '100%',
  },
  HeartIcon: {
    margin:'8px',
  },
  phraseConfig: {
    justifyContent: 'center', 
    paddingTop: '4px',
  }, 
}