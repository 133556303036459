import {
    makeStyles
  } from '@material-ui/core';
  
export const useStyles = makeStyles(() => ({
    normalButton: {
        background: '#373737', 
        height: 35,
        marginLeft: 5,

        '&:hover': { 
            border: '1px solid #fff',
        }
    },
    csvButton: {
        background: '#008000', 
        height: 35,
        marginLeft: 5,

        '&:hover': { 
            border: '1px solid #fff',
        }
    },
}));