import {
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  heading: {
    fontWeight: 600,
    fontSize: 30,
    color: '#fff',
  },
  boxItem: {
    width: '16%',  
    textAlign: 'center', 
    color: '#fff'
  }
}));