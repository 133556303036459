import { createMuiTheme } from "@material-ui/core/styles";

export const styles = {
  boxPrincipal: {
      width: '70%',
      height: 'auto',
      margin: '0px auto',
  },
  boxPrincipalMobile: {
      width: '95%',
      height: 'auto',
      margin: '30px auto',
  },
  btn: {
      width: '300px',
      height:'40px',
      margin: '10px auto',
      marginBottom: '10px',
      border: '1px solid #fff'
  },
  title: {
      margin: '10px auto',
  }
}

export const materialTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: '#E8D4D1',
        },
      },
      notchedOutline: {
        borderColor: '#fff',
        border: '1px solid #fff',               
      },
      input: {
        color: '#fff',
      },  
    },
    MuiInputLabel: {
      outlined:{
        color: '#fff',
      }
    },
    MuiCardContent:{
      root: {
        padding: '7x',
      }
    },
    MuiCardActions: {
      root: {
        padding: '8px 0px'
      }
    },
    MuiTypography: {
      h6: {
        color: '#fff'
      },
    },
    MuiButton: {
      root: {
        borderColor: '#fff'
      },
      outlined: {
        borderColor: '#fff'
      },
      label: {
        color: '#fff'
      }
    }
  },
});