import React, { useEffect, useState } from 'react';

import { 
  Autocomplete 
} from '@material-ui/lab';

import { 
  ThemeProvider, 
  TextField 
} from '@material-ui/core';

import { 
  theme 
} from './styles';

export default function CheckboxesTags({ 
  UsersBrands, 
  setSelectedBrands 
}) {

  const [brands , setBrands] = useState([]);

  useEffect(() => {
    setBrands(UsersBrands);
  }, [UsersBrands]);

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        multiple
        limitTags={3}
        onChange={(event, newValue) => {
          const array = newValue.map(item => item.rede_lojas);
          setSelectedBrands(array);
        }}
        id="checkboxes-tags-demo"
        options={brands}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            {option.name}
          </React.Fragment>
        )}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <TextField {...params} InputLabelProps={{ shrink: true }} variant="outlined" label="Marcas"/>
        )}
      />
    </ThemeProvider>
  );
}