import {
  makeStyles,
  createMuiTheme
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  BoxDialog: {
    background: '#212121',
    color: '#fff',
  },
  BoxDialogMobile: {
    background: '#212121',
    color: '#fff',
    padding: '8px 10px'
  },
  Button: {
    width: '120px', 
    height: '40px', 
    textTransform: 'uppercase',
    borderRadius: 5,
    background: '#373737',
    marginLeft: 20,

    '&:hover': { 
      border: '1px solid #fff',
    }
  },
  Title: {
    textAlign: 'center', 
    fontSize: 16, 
    flex: 1
  },
  Subtitle: {
    textAlign: 'center', 
    fontSize: 12
  },
  ItemHeader: {
    display: 'flex', 
    alignItems: 'center', 
    flexDirection: 'column'
  },
  smallMargin: {
    marginTop: 15
  },
}));

export const materialTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: '#fff',
        },
      },
      notchedOutline: {
        borderColor: '#fff'
      },
      input: {
        color: '#fff'           
      }  
    },
    MuiInputLabel: {
      outlined: {
        color: '#fff',
      }
    },
    MuiFormHelperText: {
      contained: {
        color: '#fff',
      }
    },
    MuiButton: {
      label: {
        color: '#fff'
      }
    },
    MuiSelect: {
      iconOutlined: {
        color: '#fff'
      }
    },
  },
});