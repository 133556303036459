import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { 
    editUser 
} from '../../services/users';

import { 
    useAuth 
} from '../../context/authContext';

import { 
    styles, 
    materialTheme 
} from './styles';

import { 
    PageBackground, 
    pageGrid 
} from '../../stylesCSS';

import { 
    Grid, 
    TextField, 
    Button, 
    Typography,
    ThemeProvider,
    withStyles
} from '@material-ui/core';

import LoadingModal from '../../components/LoadingModal';
import Footer from '../../components/Footer';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditUsers(props) {
    const { classes } = props;
    const { user } = useAuth();

    const [newPassword, setNewPassword] = useState(null);
    const [name, setName] = useState(null);
    const [password, setPassword] = useState(null);
    const [loadingModalOpen, setLoadingModalOpen] = useState(false);

    const submit = async () => {
        if(password === ""){
          toast.error("Por favor preencher os campos!");
          return;
        }
  
        setLoadingModalOpen(true);
  
        try{
            await editUser(
                name ? name : null,
                user.email,
                password,
                newPassword ? newPassword : null,
            );

            toast.success("Usuário editado com sucesso!");
          
        }catch(err){
          toast.error("Os dados informados estão divergentes");
        }
  
        setLoadingModalOpen(false);
    };

    return (
        <div style={PageBackground}>
            <div style={pageGrid}>
                <ThemeProvider theme={materialTheme}>
                    <Grid container xs={12} className={classes.boxPrincipalMobile}>
                        <Typography className={classes.title} variant="h6" gutterBottom>
                            EDITAR SUAS INFORMAÇÕES
                        </Typography>

                        <TextField
                            id="email"
                            label="E-mail"
                            style={{ margin: 8 }}
                            defaultValue={user.email}
                            disabled
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />

                        <TextField
                            id="name"
                            label="Nome"
                            style={{ margin: 8 }}
                            placeholder="para alterar, digite o novo nome"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            onChange={(e) => setName(e.target.value)}
                        />

                        <TextField
                            id="Newpassword"
                            label="Nova Senha"
                            style={{ margin: 8 }}
                            placeholder="para alterar, digite a nova senha"
                            type="password"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            onChange={(e) => setNewPassword(e.target.value)}
                        />

                        <TextField
                            id="Oldpassword"
                            label="Senha atual *"
                            style={{ margin: 8 }}
                            placeholder="Digite a senha atual"
                            type="password"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        <Button 
                            variant="outlined" 
                            onClick={(e) => { submit() }}
                            disableElevation
                            className={classes.btn}
                        >
                            Editar
                        </Button>
                    </Grid>

                    <ToastContainer
                        position="top-right"
                        autoClose={2500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick={false}
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover={false}
                    />

                    <LoadingModal
                        open={loadingModalOpen}
                        title={"Criando usuário"}
                    />
                </ThemeProvider>
            </div>
            <Footer />
        </div>
    );
}

EditUsers.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
export default withStyles(styles)(EditUsers);