import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';

import {
    FaArrowUp, 
    FaArrowDown 
} from 'react-icons/fa';

import { 
    useStyles, 
    materialTheme 
} from './styles';

import { 
    PageBackground, 
    pageGrid 
} from '../../stylesCSS';

import { 
    DatePicker, 
    MuiPickersUtilsProvider 
} from "@material-ui/pickers";

import { 
    FiCalendar 
} from 'react-icons/fi';

import { 
    getBrandsByUser
} from '../../services/usersBrands';

import {
    getSupervisorsDataStores 
} from '../../services/storeData';

import { 
    useAuth 
} from '../../context/authContext';

import SupervisorCard from '../../components/SupervisorCard';
import Footer from '../../components/Footer';
import AutoCompleteFilterBrands from '../../components/AutoCompleteFilterBrands';
import Pagination from '../../components/Pagination';
import GroupModalsStoreInformation from '../../components/GroupModalsStoreInformation';

import { 
    Grid,
    Typography,
    ThemeProvider,
    Button,
} from '@material-ui/core';

import {
    Skeleton
} from '@material-ui/lab';

export default function SupervisorDashboard(props) {
    const classes = useStyles(props);
    const { user } = useAuth();

    const [initialData, setInitialData] = useState([]);
    const [initialDataAux, setInitialDataAux] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [packingperpage,] = useState(70);
    const [order, setOrder] = React.useState(null);
    const [orderBy, setOrderBy] = React.useState('calories');
    const [endDate, setEndDate] = useState(moment(new Date()));
    const [startDate, setStartDate] = useState(moment(new Date()).subtract(7, 'days'));
    const [UsersBrands, setUsersBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState(user.rede === 0 ? [] : [user.rede]);

    const indexOfLastPage = currentPage * packingperpage;
    const indexOfFirstPage = indexOfLastPage - packingperpage;
    const currentPacking = stableSort(initialData, getSorting(order, orderBy)).slice(indexOfFirstPage, indexOfLastPage);

    const getUsersBrands = async (id) => {
        const response = await getBrandsByUser(id);
        const corporative = response.filter(item => item.rede_lojas !== 0);
        setUsersBrands(corporative);
    };

    useEffect(() => {
        getUsersBrands(user.id);
    }, [user]);

    useEffect(() => {
        if(startDate !== undefined && endDate !== undefined){
            (async () => {
                setLoading(true);
                const supervisorsDataStores = await getSupervisorsDataStores(startDate, endDate);
                
                let i = 0;
                const supervisorsId = supervisorsDataStores.map(item => {
                    const obj = {
                        id: i,
                        ...item
                    }
                    
                    i = i + 1;
                    return obj;
                });

                setInitialData(supervisorsId);
                setInitialDataAux(supervisorsId);
                setLoading(false);
            })();
        }
    }, [startDate, endDate, user.token]);

    useEffect(() => {
        if(selectedBrands.length !== 0){
            const corporative = selectedBrands.find(item => item === 0);

            if(corporative === undefined){
                const arrayPrincipal = [];
    
                initialDataAux.forEach(item => {
                    const found = selectedBrands.find(element => element === item.rede_lojas);

                    if(found){
                        arrayPrincipal.push(item);
                    } 
                })
    
                setInitialData(arrayPrincipal);
            }else{
                setInitialData(initialDataAux);
            } 
        }else{
            setInitialData(initialDataAux);
        }
    }, [selectedBrands, initialDataAux]);

    function sort(property){
        setOrderBy(property);
        if(order === null){
            setOrder('desc');
        }else if(order === 'asc')
            setOrder('desc');
        else if(order === 'desc'){
            setOrder('asc');
        }
    }

    function desc(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
    }
      
    function stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);

        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });

        return stabilizedThis.map(el => el[0]);
    }
      
    function getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
    }
  
    return (
        <>
            <div style={PageBackground}>
                <div style={pageGrid}>
                    <ThemeProvider theme={materialTheme}>
                        <Grid container alignItems="center" justify="space-between" style={{ paddingTop: '20px' }}>
                            <Grid item>
                                {loading ?
                                    <>
                                        <Skeleton variant="rect" style={{background: '#373435', width: '300px', height: '30px', margin: '5px auto', borderRadius: 8 }} />
                                    </>
                                :
                                    <AutoCompleteFilterBrands 
                                        UsersBrands={UsersBrands} 
                                        setSelectedBrands={setSelectedBrands} 
                                        defaultValue={
                                            user.rede === 0 ? 
                                                [] 
                                            : 
                                                [{
                                                    "name": user.marca,
                                                    "rede_lojas": user.rede
                                                }]
                                        } 
                                        width={300}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center" justify="center">
                                    <FiCalendar size={25} style={{ color: '#fff', marginRight: 10 }}/>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            variant="inline"
                                            disabled
                                            style={{ width: '90px' }}
                                            InputProps={{
                                                disableUnderline: true,
                                                color: '#fff',
                                            }}
                                            format="dd/MM/yyyy"
                                            value={startDate}
                                            onChange={setStartDate}
                                        />
                                        <Typography style={{ color: '#fff', margin: '0px 5px', marginRight: 10 }}>-</Typography>
                                        <DatePicker
                                            variant="inline"
                                            disabled
                                            style={{ width: '90px' }}
                                            format="dd/MM/yyyy"
                                            InputProps={{
                                                disableUnderline: true,
                                                color: '#fff',
                                            }}
                                            value={endDate}
                                            onChange={setEndDate}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container style={{ height: 'auto', width: '100%', marginTop: 30 }}>
                            {loading ?
                                <>
                                    <Skeleton variant="rect" style={{background: '#373435', width: '100%', height: '60px', margin: '5px auto', borderRadius: 8 }} />
                                    <Skeleton variant="rect" style={{background: '#373435', width: '100%', height: '60px', margin: '5px auto', borderRadius: 8 }} />
                                    <Skeleton variant="rect" style={{background: '#373435', width: '100%', height: '60px', margin: '5px auto', borderRadius: 8 }} />
                                    <Skeleton variant="rect" style={{background: '#373435', width: '100%', height: '60px', margin: '5px auto', borderRadius: 8 }} />
                                </>
                            :
                                <>
                                    <GroupModalsStoreInformation 
                                        selectedBrands={selectedBrands} 
                                        data={initialData}
                                    />
                                    
                                    <Grid container alignItems="center" justify="space-between" style={{ width: '100%' }}>
                                        <Grid item container style={{ width: '22%', height: 42 }}>
                                            <Button onClick={() => sort('loja')} style={{ width: '100%', height: 40 }}>
                                                <Typography className={classes.titleMenu}>Loja</Typography>
                                                {order === null ? '' : order === 'asc' && orderBy === 'loja' ? <FaArrowDown className={classes.setColorWhite} /> : order === 'desc' && orderBy === 'loja' ? <FaArrowUp className={classes.setColorWhite} /> : ''}
                                            </Button>
                                        </Grid>
                                        <Grid item container style={{ width: '13%', height: 42 }}>
                                            <Button onClick={() => sort('media_romaneio')} style={{ width: '100%', height: 40 }}>
                                                <Typography className={classes.titleMenu}>Tempo Romaneio</Typography>
                                                {order === null ? '' : order === 'asc' && orderBy === 'media_romaneio' ? <FaArrowDown className={classes.setColorWhite} /> : order === 'desc' && orderBy === 'media_romaneio' ? <FaArrowUp className={classes.setColorWhite} /> : ''}
                                            </Button>
                                        </Grid>
                                        <Grid item container style={{ width: '13%', height: 42 }}>
                                            <Button onClick={() => sort('tma_horas')} style={{ width: '100%', height: 40 }}>
                                                <Typography className={classes.titleMenu}>TMA</Typography>
                                                {order === null ? '' : order === 'asc' && orderBy === 'tma_horas' ? <FaArrowDown className={classes.setColorWhite} /> : order === 'desc' && orderBy === 'tma_horas' ? <FaArrowUp className={classes.setColorWhite} /> : ''}
                                            </Button>
                                        </Grid>
                                        <Grid item container style={{ width: '13%', height: 42 }}>
                                            <Button onClick={() => sort('taxa_cancelamento')} style={{ width: '100%', height: 40 }}>
                                                <Typography className={classes.titleMenu}>Recusa</Typography>
                                                {order === null ? '' : order === 'asc' && orderBy === 'taxa_cancelamento' ? <FaArrowDown className={classes.setColorWhite} /> : order === 'desc' && orderBy === 'taxa_cancelamento' ? <FaArrowUp className={classes.setColorWhite} /> : ''}
                                            </Button>
                                        </Grid>
                                        <Grid item container style={{ width: '13%', height: 42 }}>
                                            <Button onClick={() => sort('pedidos_pendentes')} style={{ width: '100%', height: 40 }}>
                                                <Typography className={classes.titleMenu}>Pendentes</Typography>
                                                {order === null ? '' : order === 'asc' && orderBy === 'pedidos_pendentes' ? <FaArrowDown className={classes.setColorWhite} /> : order === 'desc' && orderBy === 'pedidos_pendentes' ? <FaArrowUp className={classes.setColorWhite} /> : ''}
                                            </Button>
                                        </Grid>
                                        <Grid item container style={{ width: '13%', height: 42 }}>
                                            <Button onClick={() => sort('pedidos_pendentes_2dias')} style={{ width: '100%', height: 40 }}>
                                                <Typography className={classes.titleMenu}>Pendentes Antigos</Typography>
                                                {order === null ? '' : order === 'asc' && orderBy === 'pedidos_pendentes_2dias' ? <FaArrowDown className={classes.setColorWhite} /> : order === 'desc' && orderBy === 'pedidos_pendentes_2dias' ? <FaArrowUp className={classes.setColorWhite} /> : ''}
                                            </Button>
                                        </Grid>
                                        <Grid item container style={{ width: '13%', height: 42 }}>
                                            <Button onClick={() => sort('pedidos_totais')} style={{ width: '100%', height: 40 }}>
                                                <Typography className={classes.titleMenu}>Totais</Typography>
                                                {order === null ? '' : order === 'asc' && orderBy === 'pedidos_totais' ? <FaArrowDown className={classes.setColorWhite} /> : order === 'desc' && orderBy === 'pedidos_totais' ? <FaArrowUp className={classes.setColorWhite} /> : ''}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    
                                    {currentPacking.map(item => {
                                        return(
                                            <SupervisorCard item={item}/>
                                        );
                                    })}
                                    
                                    <Grid container justify="center" alignItems="center">
                                        <Pagination
                                            packingPerPage={packingperpage} 
                                            totalPacking={initialData.length}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </ThemeProvider>
                </div>
                <Footer />
            </div>
        </>
    );
}