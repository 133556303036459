import React, { useEffect, useState } from 'react';
import IntegrationRulesEditDesk from '../../PagesDesktop/integrationRulesEdit';
import HomeApp from '../../PagesMobile/integrationRulesEdit';

//Page principal

const Pages = () => {
  const [isDesk, setDesk] = useState(true);
  useEffect(() => {
    setDesk(window.innerWidth > 768);
  }, []);
  return isDesk ? <IntegrationRulesEditDesk /> : <HomeApp />;
};

export default Pages;