import React from 'react';
import DateFnsUtils from '@date-io/date-fns';

import {
  ThemeProvider,
  Grid,
  Typography
} from '@material-ui/core';

import { 
  DatePicker, 
  MuiPickersUtilsProvider 
} from "@material-ui/pickers";

import { 
  theme,
  useStyles
} from './styles';

export default function DateContainer({
  title = "Datas", 
  startDate, 
  setStartDate,
  endDate, 
  setEndDate,
}) {
    const classes = useStyles();

    return (
        <>
            <ThemeProvider theme={theme}>
                <Grid item container justify='flex-start' direction='column' className={classes.container}>
                    <Grid item>
                        <Typography className={classes.title}> {title} </Typography>
                    </Grid>
                    <Grid container justify='space-between' direction='row'>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item className={classes.itemTextField}>
                                <DatePicker
                                    className={classes.dateContent}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    format="dd/MM/yyyy"
                                    value={startDate}
                                    onChange={setStartDate}
                                />
                            </Grid>
                            <Grid item className={classes.itemTextField}>
                                <DatePicker
                                    className={classes.dateContent}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    format="dd/MM/yyyy"
                                    value={endDate}
                                    onChange={setEndDate}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
    );
}