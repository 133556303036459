import { 
  createMuiTheme,
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    fontSize: 25,
    color: '#fff'
  },
  subTitle: {
    fontWeight: 400,
    fontSize: 16,
    color: '#fff'
  }
}));

export const materialTheme = createMuiTheme({
  overrides: {
    MuiInput: {
      root: {
        color: '#fff'
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#fff',              
      },
      input: {
        color: '#fff',
      },
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#fff',
        },
        '&$focused $notchedOutline': {
          borderColor: '#b59494'
        },
      }, 
    },
    MuiInputLabel: {
      outlined:{
        color: '#fff',
        '&$focused': {
          color: '#fff'
        },
        '&:hover': {
          color: 'blue',
          border: 'blue',
        }
      }
    },
    MuiCardContent:{
      root: {
        padding: '7x',
      }
    },
    MuiCardActions: {
      root: {
        padding: '8px 0px'
      }
    },
    MuiTypography: {
      h6: {
        color: '#fff'
      },
    },
    MuiButton: {
      root: {
        borderColor: '#fff'
      },
      outlined: {
        borderColor: '#fff'
      },
      label: {
        color: '#fff'
      }
    },
  },
});