import api from '../../sellerManagementApi';
import { SELLERMANAGEMENTTOKEN, ENCRIPTPASS } from '../../../consts/services';
import CryptoJS from 'crypto-js';


function decryptToken(){
  const bytes = CryptoJS.AES.decrypt(SELLERMANAGEMENTTOKEN, ENCRIPTPASS);
  return bytes.toString(CryptoJS.enc.Utf8);
}

export const createPickUpPoint = async (sellerId, filial, businessHour) => {
  const response = await api.post(`/logistic/pickup/${sellerId}` , { 
    filial: filial, 
    businessHour: businessHour
   }, {
    headers: {
      'authorization': decryptToken() 
    }
  });

  return response.data;
}