import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';

import { 
    materialTheme 
} from './styles';

import { 
    useAuth 
} from '../../context/authContext';

import { 
    PageBackground, 
    pageGrid 
} from '../../stylesCSS';

import { 
    FiCalendar 
} from 'react-icons/fi';

import {  
    Grid,
    Divider,
    Typography,
    IconButton,
    ThemeProvider
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';

import { 
    DatePicker, 
    MuiPickersUtilsProvider 
} from "@material-ui/pickers";

import { 
    getBrandGraphyIndicators, 
    getBrandIndicators 
} from '../../services/brandIndicators';

import { 
    getStoreIndicators, 
    getCDIndicators, 
    getShareIndicators, 
    getCollectionsIndicators 
} from '../../services/businessUnit';

import { 
    getBrandsByUser 
} from '../../services/usersBrands';

import { 
    getStoreDataPrincipal 
} from '../../services/storeData';

import Footer from '../../components/Footer';
import IndicatorsBox from '../../components/IndicatorsBox';
import AccordionBusiness from '../../components/AccordionBusiness';
import AccordionStore from '../../components/AccordionStore';
import AutoCompleteFilterBrands from '../../components/AutoCompleteFilterBrands';
import { AUTHORIZATIONTOKENFUNCTION } from '../../consts/services';
import { useCallback } from 'react';

export default function PrincipalDashboard(props) {
    const { user } = useAuth();

    const [brandIndicators, setBrandIndicators] = useState([]);
    const [StoreIndicators, setStoreIndicators] = useState([]);
    const [StoreData, setStoreData] = useState([]);
    const [GraphyIndicators, setGraphyIndicators] = useState([]);
    const [CDIndicators, setCDIndicators] = useState([]);
    const [ShareIndicators, setShareIndicators] = useState([]);
    const [CollectionsIndicators, setCollectionsIndicators] = useState([]);
    const [endDate, setEndDate] = useState(moment(new Date()));
    const [startDate, setStartDate] = useState(moment(new Date()).subtract(7, 'days'));
    const [UsersBrands, setUsersBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([user.rede]);
    const [selectedUnit, setSelectedUnit] = useState('TOTAL');
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    
    const getBrandIndicatorsFunction = async (startDate, endDate, brands) => {
        const response = await getBrandIndicators(startDate, endDate, brands);
        setBrandIndicators(response);
    };

    const getUsersBrands = async (id) => {
        const response = await getBrandsByUser(id);
        setUsersBrands(response);
    };

    const getBrandGraphyIndicatorsFunction = async (startDate, endDate, brands) => {
        const response = await getBrandGraphyIndicators(startDate, endDate, brands);
        setGraphyIndicators(response);
    };

    const getCDIndicatorsFunction = async (startDate, endDate, brands) => {
        const response = await getCDIndicators(startDate, endDate, brands);
        setCDIndicators(response);
    };

    const getStoreIndicatorsFunction = async (startDate, endDate, brands) => {
        const response = await getStoreIndicators(startDate, endDate, brands);
        setStoreIndicators(response);
    };

    const getStoreDataFunction = async (startDate, endDate, brands) => {
        const response = await getStoreDataPrincipal(startDate, endDate, brands);
        setStoreData(response);
    };

    const getShareIndicatorsFunction = async (startDate, endDate, brands) => {
        const response = await getShareIndicators(startDate, endDate, brands);
        setShareIndicators(response);
    };

    const getCollectionsIndicatorsFunction = async (startDate, endDate, brands) => {
        const response = await getCollectionsIndicators(startDate, endDate, brands);
        setCollectionsIndicators(response);
    };

    const getToken = async () => {
        const response = await AUTHORIZATIONTOKENFUNCTION();
        setToken(response);
    };

    useEffect(() => {
        getUsersBrands(user.id);
        getToken();
    }, [user]);

    const changeData = useCallback(async () => {
        if(selectedBrands.length !== 0 && token !== null){
            setLoading(true);
            await Promise.all([
                getBrandIndicatorsFunction(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), selectedBrands),
                getCDIndicatorsFunction(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), selectedBrands),
                getStoreIndicatorsFunction(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), selectedBrands),
                getBrandGraphyIndicatorsFunction(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), selectedBrands),
                getShareIndicatorsFunction(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), selectedBrands),
                getCollectionsIndicatorsFunction(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), selectedBrands),

                getStoreDataFunction(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), selectedBrands),
            ]);
            setLoading(false);
        }
    }, [startDate, endDate, selectedBrands, token]);

    useEffect(() => {
        if(selectedBrands.length !== 0 && token !== null){
            (async () => {
                await changeData();
            })();
        }
        // eslint-disable-next-line
    }, [selectedBrands, token]);

    const getFormattedValue = (value) => {
        const newValue = value > 1000000 ? `R$ ${(value / 1000000).toFixed(2)} mi`  :  `R$ ${(value / 1000).toFixed(2)} mil`;
        return newValue;
    }

    return(
        <>
            <div style={PageBackground}>
                <div style={pageGrid}>
                    <Grid container alignItems="center" justify="space-between" style={{ paddingTop: '20px' }}>
                        <Grid item>
                            <AutoCompleteFilterBrands 
                                UsersBrands={UsersBrands}
                                setSelectedBrands={setSelectedBrands}
                            />
                        </Grid>
                        <Grid item container justify='space-between' alignItems='center' direction='row' style={{ width: '300px' }}>
                            <Grid item>
                                <Grid container alignItems="center" justify="center">
                                    <FiCalendar size={25} style={{ color: '#fff', marginRight: 10 }}/>
                                    <ThemeProvider theme={materialTheme}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                variant="inline"
                                                style={{ width: '90px' }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    color: '#fff',
                                                }}
                                                format="dd/MM/yyyy"
                                                value={startDate}
                                                onChange={setStartDate}
                                            />
                                            <Typography style={{ color: '#fff', margin: '0px 5px', marginRight: 10 }}>-</Typography>
                                            <DatePicker
                                                variant="inline"
                                                style={{ width: '90px' }}
                                                format="dd/MM/yyyy"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    color: '#fff',
                                                }}
                                                value={endDate}
                                                onChange={setEndDate}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </ThemeProvider>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => changeData()} color="primary" aria-label="search">
                                    <SearchIcon style={{ color: '#fff' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                
                    <Grid container>
                        <Typography 
                            style={{ 
                                fontWeight: 600,
                                fontSize: 30,
                                color: '#fff',
                                padding: '30px 0'
                            }}
                        >
                            Visão Geral
                        </Typography>
                    </Grid>

                    <Grid container alignItems="center" justify="space-between">
                        <IndicatorsBox 
                            title="Receita Captada" 
                            value={getFormattedValue(brandIndicators.receita)} 
                            loading={loading} 
                        />
                        <IndicatorsBox 
                            title="Pedidos Captados" 
                            value={brandIndicators.pedidos_totais} 
                            loading={loading} 
                        />
                        <IndicatorsBox 
                            title="TMA" 
                            value={`${brandIndicators.TMA} h`} 
                            loading={loading} 
                        />
                        <IndicatorsBox 
                            title="Cancelamento" 
                            value={`${brandIndicators.Cancelamento} %`} 
                            loading={loading} 
                        />
                        <IndicatorsBox 
                            title="Mais Pedidos" 
                            value={brandIndicators.moreOrders} 
                            loading={loading} 
                        />
                    </Grid>
                    
                    <Grid 
                        container 
                        style={{ 
                            width: '100%', 
                            margin: '30px 0px 10px 0px', 
                            background: '#fff' 
                        }} 
                        alignItems="center"
                    >
                        <Divider light />
                    </Grid>
                    
                    <Grid container style={{ width: '100%' }} alignItems="center">
                        <AccordionBusiness 
                            selectedUnit={selectedUnit} 
                            setSelectedUnit={setSelectedUnit} 
                            collectionsIndicators={CollectionsIndicators} 
                            shareIndicators={ShareIndicators} 
                            graphyIndicators={GraphyIndicators} 
                            storeIndicators={StoreIndicators} 
                            CDIndicators={CDIndicators} 
                            title={"Unidades de negócio"} 
                            loading={loading}
                        />
                    </Grid>

                    <Grid 
                        container 
                        style={{ 
                            width: '100%', 
                            margin: '30px 0px 10px 0px', 
                            background: '#fff' 
                        }} 
                        alignItems="center"
                    >
                        <Divider light />
                    </Grid>
                    
                    <Grid container style={{ width: '100%' }} alignItems="center">
                        <AccordionStore 
                            title={"Lojas"} 
                            loading={loading} 
                            storeData={StoreData} 
                            selectedBrands={selectedBrands}
                        />
                    </Grid>
                </div>

                <Footer />
            </div>
        </>
    );
}