import {
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  autoCompleteContainer: {
    width: '95%', 
    marginBottom: 20
  },
  title: {
    fontWeight: 600,
    fontSize: 30,
    color: '#fff',
    padding: '30px 0'
  }
}));