import React from 'react';

import {
  ThemeProvider,
  Checkbox,
  TextField
} from '@material-ui/core';

import { 
  Autocomplete 
} from '@material-ui/lab';

import { 
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon
} from '@material-ui/icons';

import { 
  theme
} from './styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags({ data, setSelected, title }) {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          limitTags={1}
          size="small"
          options={data}
          disableCloseOnSelect
          getOptionLabel={(option) => option }
          onChange={(event, newValue) => {
            setSelected(newValue);
          }}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </React.Fragment>
          )}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField 
              {...params} 
              InputLabelProps={{
                  shrink: true,
              }} 
              variant="outlined" 
              label={title}
            />
          )}
        />
      </ThemeProvider>
    </>
  );
}