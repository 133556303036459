import api from '../api';
import moment from 'moment';
import { AUTHORIZATIONTOKENFUNCTION } from '../../consts/services';

export const getMoreOrders= async (startDate, endDate, brands) => {
  const response = await api.post(`/storeData/moreOrders/rank?startDate=${startDate}&endDate=${endDate}` ,{
    brands: brands
  }, {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}

export const getSupervisorsDataStores = async (startDate, endDate) => {
  const response = await api.get(`/storeData?startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`, {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}

export const getStoreRevenues = async (supervisor, startDate, endDate) => {
  const response = await api.get(`/storeData/revenues/${supervisor}?startDate=${startDate}&endDate=${endDate}` , {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}

export const getSupervisors = async () => {
  const response = await api.get(`/storeData/supervisors` , {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}

export const getStoreDataPrincipal= async (startDate, endDate, brands) => {
  const response = await api.post(`/storeData/principal?startDate=${startDate}&endDate=${endDate}` ,{
    brands: brands
  }, {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}

export const createStoreLog = async (brandId, sellerId) => {
  const response = await api.post(`/storeData/create/log` , {
    sellerId: sellerId,
    brandId: brandId
  }, {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}

export const setDeflator= async (seller, value) => {
  const response = await api.put(`/storeData/setDeflator` ,{
    seller: seller,
    valor: value
  }, {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}

export const setStore = async (seller, status, message) => {
  const response = await api.put(`/storeData` ,{
    seller: seller,
    status: status,
    message: message
  }, {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}