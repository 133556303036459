import React from 'react';

import { 
  ThemeProvider, 
  TextField, 
  MenuItem 
} from '@material-ui/core';

import { 
  theme, 
  useStyles 
} from './styles';

export default function MultilineTextFields({ 
  handleChange, 
  actual, 
  data, 
  title 
}) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          id="outlined-select-currency"
          select
          label={title}
          value={actual}
          onChange={(event) => handleChange(event.target.value)}
          size="small"
          variant="outlined"
          color="#fff"
        >
          {data.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField> 
      </div>
    </form>
    </ThemeProvider>
  );
}