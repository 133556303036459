import React, { useState } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import Pagination from '../../components/Pagination';
import IntegrationRuleCard from '../IntegrationRuleCard';
import Carousel from '../IntegrationRuleCarousel';

import { 
  Link 
} from 'react-router-dom';

import { 
  FaArrowUp, 
  FaArrowDown,
  FaArrowRight
} from 'react-icons/fa';

import { 
  useStyles 
} from './styles';

import {  
  Grid,
  Typography,
  Button,
  Grow,
  Checkbox,
} from '@material-ui/core';

export default function AccordionIntegrationRule({ 
  loading, 
  storeData 
}) {
  const classes = useStyles();

  const [dataCheck , setDataCheck] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [packingperpage,] = useState(30);
  const [order, setOrder] = React.useState(null);
  const [orderBy, setOrderBy] = React.useState('sellerId');
  const [objActual, setObjActual] = useState({ 
    link: 'collectionsLength', 
    name: 'Coleções', 
    icon: <FaArrowRight size={20}/>, 
    title: 'Coleções' 
  });

  const indexOfLastPage = currentPage * packingperpage;
  const indexOfFirstPage = indexOfLastPage - packingperpage;
  const currentPacking = stableSort(storeData, getSorting(order, orderBy)).slice(indexOfFirstPage, indexOfLastPage);

  const handleChangeCheckbox = () => {
    setSelectAll(selectAll === true ? false : true);
    setDataCheck(selectAll === true ? [] : storeData);
  };

  function sort(property){
    setOrderBy(property);
    if(!order){
        setOrder('desc');
    }else if(order === 'asc')
        setOrder('desc');
    else if(order === 'desc'){
        setOrder('asc');
    }
  }

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }

    return 0;
  }
    
  function stableSort(array, cmp) {
      const stabilizedThis = array.map((el, index) => [el, index]);

      stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });

      return stabilizedThis.map(el => el[0]);
  }
    
  function getSorting(order, orderBy) {
      return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  return (
    <>
      <Grid style={{ width: '100%', boxShadow: 'none' }}>
        <Grid 
          container 
          style={{ 
            height: 'auto', 
            width: '100%', 
            marginTop: 30 
          }}
        >
          {loading ?
            <>
              <Skeleton variant="rect" className={classes.Skeleton}/>
              <Skeleton variant="rect" className={classes.Skeleton}/>
              <Skeleton variant="rect" className={classes.Skeleton}/>
              <Skeleton variant="rect" className={classes.Skeleton}/>
            </>
          :
            <>
              <Grid container alignItems="center" justify="space-between" style={{ width: '100%', margin: '5px auto', paddingTop: 10 }}>
                {loading ?  
                  <Skeleton variant="rect" width={'100%'} height={48} />
                :
                  <Carousel
                    setObjActual={setObjActual} 
                    width="100%"
                  />
                }
              </Grid>
              <Grid item className={classes.containerTransparentTitleTable} >
                <Grid item className={classes.boxCheck} >
                  <Checkbox
                    checked={selectAll}
                    onChange={handleChangeCheckbox}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Grid>

                {dataCheck.length > 0 ?
                  <Grow timeout={100} in={dataCheck.length > 0}>
                    <Link to={{ pathname: '/integration/edit', state: { integrationRulesData: dataCheck }}} style={{ textDecoration: 'none' }}>
                      <Grid item style={{ display: 'flex', alignItems: 'center', height: '49px' }}>
                        <Button variant="contained" size="medium" style={{ background: '#373435', color: '#fff', borderColor: '#fff' }}>editar regras de integração</Button>
                      </Grid>
                    </Link>
                  </Grow>
                :
                  
                    <Grid className={classes.boxItem}>
                      <Button onClick={() => sort(objActual.link)}>
                        <Typography className={classes.headerTableTypography}>{objActual.name}</Typography>
                        {order === null ? '' : order === 'asc' && orderBy === objActual.link ? <FaArrowDown className={classes.setColorWhite} /> : order === 'desc' && orderBy === objActual.link ? <FaArrowUp className={classes.setColorWhite} /> : ''}
                      </Button>
                    </Grid>
                }
              </Grid>
              
              {currentPacking.map(item => {
                return(
                  <IntegrationRuleCard 
                    item={item}
                    objActual={objActual}
                    setDataCheck={setDataCheck} 
                    dataCheck={dataCheck} 
                    checkFlag={selectAll}
                  />
                );
              })}
              
              <Grid container justify="center" alignItems="center">
                <Pagination
                  packingPerPage={packingperpage} 
                  totalPacking={storeData.length}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </Grid>
            </>
          }
        </Grid>
      </Grid>
    </>
  );
}



