import React from 'react';
import SwipeableViews from 'react-swipeable-views';

import {
  ThemeProvider,
  Chip, 
  Avatar
} from '@material-ui/core';

import { 
  INDICATORS 
} from '../../consts/IntegrationCarousel';

import { 
  styles, 
  theme 
} from './styles';

function DemoWidth({ setObjActual, width }) {

  const handleClick = (item) => {
    setObjActual(item);
  };

  return (
    <ThemeProvider theme={theme}>
      <SwipeableViews 
        style={{ width: width ? width : '90%', margin: '0 auto' }} 
        slideStyle={styles.slideContainer}
      >
        {INDICATORS.map(item => (
          <Chip
            avatar={
              <Avatar style={{ color: '#fff', background: 'transparent' }}>
                {item.icon}
              </Avatar>
            }
            label={item.name}
            onClick={() => handleClick(item)}
            variant="outlined"
            color="primary"
          />
        ))}
      </SwipeableViews>
    </ThemeProvider>
  );
}

export default DemoWidth;