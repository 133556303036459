import React, { useEffect, useState } from "react";

/**
 * Libraries Imports
 */

import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Material-Ui Imports
 */

import { Grid, Typography, Button, Divider } from "@material-ui/core";

/**
 *  Components Imports
 */

import TextFieldIntegrationRules from "../../components/TextFieldIntegrationRules";
import DoubleTextFieldIntegrationRules from "../../components/DoubleTextFieldIntegrationRules";
import AutoCompleteChooseMultipleItems from "../../components/AutoCompleteChooseMultipleItems";
import AutoCompleteChooseBrand from "../../components/AutoCompleteChooseBrand";
import Footer from "../../components/Footer";
import LoadingModal from "../../components/LoadingModal";

/**
 *  Styles Imports
 */

import { useStyles } from "./styles";

import { PageBackground, pageGrid } from "../../stylesCSS";

/**
 *  Consts Imports
 */

import { SomaGroupBrands } from "../../consts/brands";

/**
 *  Services Imports
 */

import {
  insertStoreOnOrchestrator,
  insertVtexKeysOnStore,
  insertVtexPricingOnStore,
} from "../../services/logisticSetup/sellers";

import { createCarrier } from "../../services/logisticSetup/shippingPolicies";
import { createDock } from "../../services/logisticSetup/docks";
import { createWareHouse } from "../../services/logisticSetup/warehouses";
import { createPickUpPoint } from "../../services/logisticSetup/pickUpPoint";
import { createStoreLog } from "../../services/storeData";

/**
 * Context Imports
 */

import { useLogistic } from "../../context/logisticContext";

/**
 *  Principal Function Component
 */

export default function IntegrationRulesDash(props) {
  const classes = useStyles(props);
  const {
    getShippingPoliciesByBrandId,
    getDocksByBrandId,
    getWarehousesByBrandId,
  } = useLogistic();

  const SOMABRANDS = SomaGroupBrands.map((item) => {
    return {
      key: item.brandName,
      value: item.brandId,
    };
  });

  const [loadingModalOpen, setLoadingModalOpen] = useState(false);

  /**
   * First line
   */

  const [store, setStore] = useState("");
  const [filial, setFilial] = useState("");
  const [CNPJ, setCNPJ] = useState("");
  const [brand, setBrand] = React.useState(() => {
    const brandName = localStorage.getItem(`@maisomni:favoriteBrand`)
      ? localStorage.getItem(`@maisomni:favoriteBrand`)
      : "";
    const item = SOMABRANDS.find((item) => item.key === brandName);
    return item !== undefined ? item.value : "";
  });

  /**
   * Second line
   */

  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [filialCode, setFilialCode] = useState("");
  const [supervisor, setSupervisor] = useState("");

  /**
   * Store business hour
   */

  const [weekOpenTime, setWeekOpenTime] = useState("");
  const [weekCloseTime, setWeekCloseTime] = useState("");

  const [saturdayOpenTime, setSaturdayOpenTime] = useState("");
  const [saturdayCloseTime, setSaturdayCloseTime] = useState("");

  const [sundayOpenTime, setSundayOpenTime] = useState("");
  const [sundayCloseTime, setSundayCloseTime] = useState("");

  /**
   * Logistic
   */

  const [selectedShippingPolicies, setSelectedShippingPolicies] = useState([]);
  const [shippingPolicies, setShippingPolicies] = useState([]);
  const [allShippingPolicies, setAllShippingPolicies] = useState([]);

  const [selectedDocks, setSelectedDocks] = useState([]);
  const [docks, setDocks] = useState([]);
  const [allDocks, setAllDocks] = useState([]);

  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [allWarehouses, setAllWarehouses] = useState([]);

  /**
   * Logistic Api's
   */

  useEffect(() => {
    getShippingPoliciesByBrandId(brand)
      .then((response) => {
        const modifiedArray = response.map(({ idVtex }) => {
          return {
            key: idVtex,
            value: idVtex,
          };
        });

        setAllShippingPolicies(response);
        setShippingPolicies(modifiedArray);
      })
      .catch((error) => {
        console.log(error);
      });

    getDocksByBrandId(brand)
      .then((response) => {
        const modifiedArray = response.map(({ idVtex }) => {
          return {
            key: idVtex,
            value: idVtex,
          };
        });

        setAllDocks(response);
        setDocks(modifiedArray);
      })
      .catch((error) => {
        console.log(error);
      });

    getWarehousesByBrandId(brand)
      .then((response) => {
        const modifiedArray = response.map(({ dock }) => {
          return {
            key: dock.idVtex,
            value: dock.idVtex,
          };
        });

        setAllWarehouses(response);
        setWarehouses(modifiedArray);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [
    brand,
    getShippingPoliciesByBrandId,
    getDocksByBrandId,
    getWarehousesByBrandId,
  ]);

  /**
   * Functions
   */

  const createStore = async () => {
    try {
      setLoadingModalOpen(true);

      //Format business Hour
      const businessHour = [];

      for (let i = 0; i <= 6; i++) {
        businessHour.push({
          dayOfWeek: i,
          open:
            i === 0
              ? sundayOpenTime
              : i === 6
              ? saturdayOpenTime
              : weekOpenTime,
          close:
            i === 0
              ? sundayCloseTime
              : i === 6
              ? saturdayCloseTime
              : weekCloseTime,
        });
      }

      //insert pricing information on vtex
      await insertVtexPricingOnStore(store);

      // Insert store on orchestrator
      await insertStoreOnOrchestrator(
        store,
        filial,
        filialCode,
        city,
        state,
        brand,
        supervisor,
        businessHour,
        CNPJ
      );

      //insert vtex keys on store
      await insertVtexKeysOnStore(store, brand);

      //insert pick-up point
      await createPickUpPoint(store, filial, businessHour);

      if (selectedShippingPolicies.length > 0) {
        const shippingPoliciesFiltered = selectedShippingPolicies.map(
          (item) => {
            return allShippingPolicies.find(
              (element) => element.idVtex === item
            );
          }
        );

        await createCarrier(store, shippingPoliciesFiltered, businessHour);
      }

      if (selectedDocks.length > 0) {
        const docksFiltered = selectedDocks.map((item) => {
          return allDocks.find((element) => element.idVtex === item);
        });

        await createDock(store, docksFiltered);
      }

      if (selectedWarehouses.length > 0) {
        const warehousesFiltered = selectedWarehouses.map((item) => {
          return allWarehouses.find((element) => element.dock.idVtex === item);
        });

        await createWareHouse(store, warehousesFiltered);
      }

      await createStoreLog(brand, store);

      setLoadingModalOpen(false);
      toast.success("loja criada com sucesso!");
    } catch (err) {
      setLoadingModalOpen(false);
      toast.error(err.response.data.error);
    }
  };

  return (
    <>
      <div style={PageBackground}>
        <div style={pageGrid}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.headerContainer}
          >
            <Grid item container className={classes.autoWidth}>
              <Typography className={classes.title}>
                Criação de lojas
              </Typography>
            </Grid>

            <Grid item container spacing={2} className={classes.autoWidth}>
              <Grid item>
                <Link
                  to={{ pathname: "/automatization/setup" }}
                  className={classes.retireTextDecoration}
                >
                  <Button
                    variant="contained"
                    size="medium"
                    className={classes.button}
                  >
                    Configurações
                  </Button>
                </Link>
              </Grid>

              <Grid item>
                <Link
                  to={{ pathname: "/automatization/upload" }}
                  className={classes.retireTextDecoration}
                >
                  <Button
                    variant="contained"
                    size="medium"
                    className={classes.button}
                  >
                    Uploads
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Divider className={classes.dividerPrincipal} />

          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.headerContainer}
          >
            <Grid item container className={classes.autoWidth}>
              <Typography className={classes.subtitle}>Informações</Typography>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            className={classes.headerContainer}
          >
            <Grid item className={classes.contentTextField}>
              <TextFieldIntegrationRules
                rows={1}
                objectToRender={store}
                title={"sellerId"}
                stateToChange={setStore}
              />
            </Grid>
            <Grid item className={classes.contentTextField}>
              <TextFieldIntegrationRules
                rows={1}
                objectToRender={filial}
                title={"Filial"}
                stateToChange={setFilial}
              />
            </Grid>
            <Grid item className={classes.contentTextField}>
              <AutoCompleteChooseBrand
                title={"Marca"}
                itemsToChoose={SOMABRANDS}
                brand={brand}
                setBrand={setBrand}
                storageName={"@maisomni:favoriteBrand"}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            className={classes.headerContainer}
          >
            <Grid item className={classes.contentTextFieldSubSection}>
              <TextFieldIntegrationRules
                rows={1}
                objectToRender={city}
                title={"Cidade"}
                stateToChange={setCity}
              />
            </Grid>
            <Grid item className={classes.contentTextFieldSubSection}>
              <DoubleTextFieldIntegrationRules
                rows={1}
                title={"Estado e Código filial"}
                firstObject={state}
                firstStateToChange={setState}
                firstPlaceholder={"PR"}
                secondObject={filialCode}
                secondStateToChange={setFilialCode}
                secondPlaceholder={"123456"}
              />
            </Grid>
            <Grid item className={classes.contentTextFieldSubSection}>
              <TextFieldIntegrationRules
                rows={1}
                objectToRender={supervisor}
                title={"E-mail do supervisor"}
                stateToChange={setSupervisor}
              />
            </Grid>
            <Grid item className={classes.contentTextFieldSubSection}>
              <TextFieldIntegrationRules
                rows={1}
                objectToRender={CNPJ}
                title={"CNPJ"}
                stateToChange={setCNPJ}
              />
            </Grid>
          </Grid>

          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.headerContainer}
          >
            <Grid item container className={classes.autoWidth}>
              <Typography className={classes.subtitle}>
                Horário de funcionamento
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            className={classes.headerContainer}
          >
            <Grid item className={classes.contentTextField}>
              <DoubleTextFieldIntegrationRules
                rows={1}
                title={"Segunda a sexta"}
                firstObject={weekOpenTime}
                firstStateToChange={setWeekOpenTime}
                secondObject={weekCloseTime}
                secondStateToChange={setWeekCloseTime}
              />
            </Grid>
            <Grid item className={classes.contentTextField}>
              <DoubleTextFieldIntegrationRules
                rows={1}
                title={"Sábado"}
                firstObject={saturdayOpenTime}
                firstStateToChange={setSaturdayOpenTime}
                secondObject={saturdayCloseTime}
                secondStateToChange={setSaturdayCloseTime}
              />
            </Grid>
            <Grid item className={classes.contentTextField}>
              <DoubleTextFieldIntegrationRules
                rows={1}
                title={"Domingo"}
                firstObject={sundayOpenTime}
                firstStateToChange={setSundayOpenTime}
                secondObject={sundayCloseTime}
                secondStateToChange={setSundayCloseTime}
              />
            </Grid>
          </Grid>

          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.headerContainer}
          >
            <Grid item container className={classes.autoWidth}>
              <Typography className={classes.subtitle}>Logística</Typography>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            className={classes.headerContainer}
          >
            <Grid item xs={12}>
              <AutoCompleteChooseMultipleItems
                title={"Adicionar Transportadoras"}
                itemsToChoose={shippingPolicies}
                setItems={setSelectedShippingPolicies}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            className={classes.headerContainer}
          >
            <Grid item xs={12}>
              <AutoCompleteChooseMultipleItems
                title={"Adicionar Docas"}
                itemsToChoose={docks}
                setItems={setSelectedDocks}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            className={classes.headerContainer}
          >
            <Grid item xs={12}>
              <AutoCompleteChooseMultipleItems
                title={"Adicionar Docas ao estoque"}
                itemsToChoose={warehouses}
                setItems={setSelectedWarehouses}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            justify="flex-end"
            className={classes.headerContainer}
          >
            <Button
              onClick={() => createStore()}
              variant="contained"
              size="medium"
              className={classes.createStoreButton}
            >
              Criar loja
            </Button>
          </Grid>
        </div>
        <Footer />

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={true}
        />

        <LoadingModal open={loadingModalOpen} title={"Criando loja"} />
      </div>
    </>
  );
}
