export const SCENARIOS = [
  {
    value: 'conservative',
    label: 'conservador',
  },
  {
    value: 'normal',
    label: 'normal',
  },
  {
    value: 'aggressive',
    label: 'agressivo',
  },
];

export const MODE = [
  {
    value: 'activate',
    label: 'Ativar',
  },
  {
    value: 'disable',
    label: 'Desativar',
  },
];

export const REASONS = [
  {
    value: 'Poucos suprimentos (caixa, papel de seda, etc)',
    label: 'Poucos suprimentos (caixa, papel de seda, etc)',
  },
  {
    value: 'Troca de coleção',
    label: 'Troca de coleção',
  },
  {
    value: 'Problema operacional',
    label: 'Problema operacional',
  },
  {
    value: 'Loja encerrando operações',
    label: 'Loja encerrando operações',
  },
  {
    value: 'Decisão da marca',
    label: 'Decisão da marca',
  },
  {
    value: 'Outros',
    label: 'Outros',
  },
]