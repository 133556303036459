import { 
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  boxItem: {
    width: '12%',  
    textAlign: 'center', 
    color: '#fff',
  },
  ItemTypography: {
    fontSize: 15
  },
  BoxTable: {
    width: '100%', 
    background: '#373435', 
    borderRadius: 8, 
    padding: 10
  }
}));