import {
  makeStyles,
  createMuiTheme
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  BoxDialog: {
    background: '#212121',
    color: '#fff',
  },
  BoxDialogMobile: {
    background: '#212121',
    color: '#fff',
    padding: '8px 10px'
  },
  Button: {
    width: '240px', 
    height: '40px', 
    textTransform: 'uppercase',
    borderRadius: 5,
    marginBottom: 15,
    color: '#fff'
  },
}));

export const materialTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: '#fff',
        },
      },
      notchedOutline: {
        borderColor: '#fff'
      },
      input: {
        color: '#fff'           
      }  
    },
    MuiInputLabel: {
      outlined: {
        color: '#fff',
      }
    },
    MuiFormHelperText: {
      contained: {
        color: '#fff',
      }
    },
  },
});