export const MODE = [
  {
    value: 1,
    label: 'Ativar/Inativar',
  },
  {
    value: 2,
    label: 'Mudança de Tempo',
  },
];

export const STATUS = [
  {
    value: 1,
    label: 'Ativar',
  },
  {
    value: 2,
    label: 'Inativar',
  },
];

export const DOCKS = [
  {
    value: 'Sequoia',
    label: 'Sequoia',
  },
  {
    value: 'TotalExpress',
    label: 'Total Express',
  },
  {
    value: 'Loggi',
    label: 'Loggi',
  },
  {
    value: 'Retirada',
    label: 'Retirada',
  },
];

export const CD_DOCKS = [
  {
    value: 'TotalExpress',
    label: 'Total Express',
  },
  {
    value: 'yellowcomum',
    label: 'Yellow Economy',
  },
  {
    value: 'yellowExpress',
    label: 'Yellow Express',
  },
  {
    value: 'yellowSuperExpressa',
    label: 'Yellow Super Expressa',
  },
];