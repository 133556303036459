import React from 'react';

import { 
  FaBox, 
  FaBoxOpen, 
  FaClock, 
  FaBoxes, 
  FaPercentage, 
  FaTruck 
} from 'react-icons/fa';

export const INDICATORS = [
  { 
    link: 'media_romaneio', 
    name: 'Tempo Romaneio', 
    icon: <FaTruck size={20}/>, 
    title: 'Média de dias de encerramento dos romaneios' 
  },
  { 
    link: 'tma_horas', 
    name: 'TMA', 
    icon: <FaClock size={20}/>, 
    title: 'Média de dias' 
  },
  { 
    link: 'taxa_cancelamento', 
    name: 'Recusa', 
    icon: <FaPercentage size={20}/>, 
    title: 'Taxa de Cancelamento' 
  },
  { 
    link: 'pedidos_pendentes', 
    name: 'Pedidos Pendentes', 
    icon: <FaBoxOpen size={20}/>, 
    title: 'Pedidos Pendentes' 
  },
  { 
    link: 'pedidos_pendentes_2dias', 
    name: 'Pendentes antigos', 
    icon: <FaBoxes size={20}/>, 
    title: 'Pedidos Pendentes (+2)' 
  },
  { 
    link: 'pedidos_totais', 
    name: 'Pedidos Totais', 
    icon: <FaBox size={20}/>, 
    title: 'Pedidos Totais' 
  },
];