import React, { useEffect, useState } from 'react';
import OrchestratorSetupDesktop from '../../PagesDesktop/orchestratorSetup';

//Page principal

const Pages = () => {
  const [isDesk, setDesk] = useState(true);
  useEffect(() => {
    setDesk(window.innerWidth > 768);
  }, []);
  return isDesk ? <OrchestratorSetupDesktop /> : <OrchestratorSetupDesktop />;
};

export default Pages;