import React, { useEffect, useState } from 'react';

import { 
  Grid, 
  ThemeProvider,
  Accordion,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Typography,
  Tooltip,
  Button
} from '@material-ui/core';

import { 
  FaPlay, 
  FaPause, 
  FaStop 
} from 'react-icons/fa';

import { 
  useAuth 
} from '../../context/authContext';

import { 
  useStyles, 
  materialTheme 
} from './styles';

export default function ActionsInAccordionSummary({ 
  item,
  objActual,
  setDataCheck, 
  dataCheck, 
  checkFlag,
  currentPage 
}) {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const { user } = useAuth();

  const userCanViewThis = (user) => {
    if(user.permissions.isAdministrator || user.permissions.isManagement){
      return true;
    }

    return false;
  };

  const chooseColumn = (link, item) => {
    let data = '';

    if(link === 'media_romaneio'){
        data = item.media_romaneio;
    }else if(link === 'tma_horas'){
        data = item.tma_horas;
    }else if(link === 'taxa_cancelamento'){
        data = item.taxa_cancelamento;
    }else if(link === 'pedidos_pendentes_2dias'){
        data = item.pedidos_pendentes_2dias;
    }else if(link === 'pedidos_totais'){
        data = item.pedidos_totais;
    }else if(link === 'openOrders'){
      data = `${item.openOrders} | ${item.capacidade}`;
    }else if(link === 'deflator'){
      data = item.deflator;
    }
    
    return (
      <Tooltip title={objActual.title} arrow>
        <span style={{ width: '30%' }}>
          <Button disabled className={classes.selectedIndicatorBox}>
            <Grid className={classes.selectedIndicatorGrid}>
              {/* {objActual.icon} */}
              <Typography 
                style={{ 
                  marginLeft: '5px', 
                  minWidth: '60px', 
                  color: link !== 'tma_horas' ? 
                    '#fff' 
                  : item.tma_horas <= 2 ? 
                    '#028A0F' 
                  : item.tma_horas > 3 ? 
                    '#ff1100' 
                  : 
                    '#fff' 
                }}
              >
                {data}
              </Typography>
            </Grid>
          </Button>
        </span>
      </Tooltip>                           
    );
  }

  const getIcon = (item) => {
    if(item.ligado === false){
      return <FaStop size={16} style={{ marginRight: '10px', color: '#d62b2b' }}/>
    }

    if(item.abaixo_do_teto === false){
      return <FaPause size={16} style={{ marginRight: '10px', color: '#d62b2b' }}/>
    }

    if(item.abrangente === true){
      return <FaPlay size={16} style={{ marginRight: '10px', color: '#ffd700' }}/>
    }

    return <FaPlay size={16} style={{ marginRight: '10px', color: '#42a31c' }}/>
  }

  useEffect(() => {
    if(checkFlag === true){
      setChecked(true);
    }else{
      setChecked(false);
    }
  }, [checkFlag]);

  useEffect(() => {
    if(dataCheck.length === 0){
      setChecked(false);
    }
  }, [dataCheck]);

  useEffect(() => {
    const sellerIsChecked = dataCheck.find(element => element.loja === item.loja);

    if(sellerIsChecked){
      setChecked(true);
    }else{
      setChecked(false);
    }

  }, [currentPage, dataCheck, item.loja]);

  const handleCheckBox = (event) => {
    if(event.target.checked === true){
      const arr = [...dataCheck, item]
      setDataCheck(arr)
    }else if(event.target.checked === false){
      const arr = dataCheck.filter(element => {return item.loja !== element.loja});
      setDataCheck(arr)
    }
    event.stopPropagation();
  };

  return (
    <div className={classes.root}>
      <ThemeProvider theme={materialTheme}>
        <Accordion 
          expanded={false} 
          defaultExpanded={true} 
          className={checked ? classes.AccordionSelected : classes.Accordion}
        >
          <AccordionSummary>
            {userCanViewThis(user) ?
              <FormControlLabel
                checked={checked}
                aria-label="Acknowledge"
                onClick={(event) => handleCheckBox(event)}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox color={"#fff"}/>}
                style={{ width: '5%' }}
              /> 
            : 
              <Grid style={{ width: '5%' }}></Grid>
            }

            <Grid 
              container 
              justify="space-between" 
              alignItems="center" 
              style={{ width: '100%' }}
            >
              <Grid 
                style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  width: '60%',
                  marginLeft: 15 
                }}
              >
                {getIcon(item)}

                <Grid 
                  container 
                  item 
                  style={{ 
                    flexDirection: 'column', 
                    maxWidth: '150px' 
                  }}
                >
                  <Grid item style={{ maxWidth: '150px' }}>
                    <Typography 
                      style={{ 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis', 
                        color: '#fff' 
                      }}
                    >
                      {item.loja}</Typography>
                  </Grid>
                  <Grid item style={{ maxWidth: '150px' }}>
                    <Typography 
                      style={{ 
                        color: '#808080', 
                        fontSize: 12, 
                        fontWeight: 500, 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis', 
                        whiteSpace: 'nowrap' 
                      }}
                    >
                      {item.filial}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {chooseColumn(objActual.link, item)}
            </Grid>
          </AccordionSummary>
        </Accordion>
      </ThemeProvider>
    </div>
  );
}