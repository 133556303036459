import {
  createMuiTheme
} from '@material-ui/core';

export const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: '#fff',
        },
      },
      notchedOutline: {
        borderColor: '#fff'
      }
    },
    MuiSelect: {
      iconOutlined: {
        color: '#fff'
      },
      outlined: {
        color: '#fff'
      }
    },
    MuiInputLabel: {
      outlined: {
        color: '#fff'
      },
      marginDense: {
        color: '#fff'
      }
    },
    MuiAutocomplete: {
      popupIndicator: {
        color: '#fff'
      },
      clearIndicator: {
        color: '#fff'
      }
    }
  },
});