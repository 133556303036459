import React, { useState } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';

import Pagination from '../../components/Pagination';
import AdmCardMobile from '../AdmCard';
import ModalDeflator from '../ModalDeflator';
import ModalTurnOnOff from '../../componentsMobile/ModalTurnOnOff';
import ModalCapacity from '../../componentsMobile/ModalCapacity';
import GroupModalsStoreInformation from '../GroupModalsStoreInformation';
import Carousel from '../AdmCarousel';

import { 
  GoVerified 
} from 'react-icons/go';

import { 
  MdPlaylistAddCheck 
} from 'react-icons/md';

import { 
  BsBarChartFill 
} from 'react-icons/bs';

import { 
  FaArrowUp, 
  FaArrowDown,
  FaTruck 
} from 'react-icons/fa';

import { 
  useAuth 
} from '../../context/authContext';

import { 
  useStyles 
} from './styles';

import {  
  Grid,
  Typography,
  Button,
  Grow,
  Checkbox,
  Tooltip,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';

export default function AccordionStore({ 
  title, 
  loading, 
  storeData, 
  selectedBrands 
}) {
  const classes = useStyles();
  const { user } = useAuth();

  const [dataCheck , setDataCheck] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [openDeflator, setOpenDeflator] = useState(false);
  const [openTurnOn, setOpenTurnOn] = useState(false);
  const [openCapacity, setOpenCapacity] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [packingperpage,] = useState(30);
  const [order, setOrder] = React.useState(null);
  const [orderBy, setOrderBy] = React.useState('loja');
  const [objActual, setObjActual] = useState({ link: 'media_romaneio', name: 'Romaneios', icon: <FaTruck size={20}/>, title: 'Média de dias' });

  const indexOfLastPage = currentPage * packingperpage;
  const indexOfFirstPage = indexOfLastPage - packingperpage;
  const currentPacking = stableSort(storeData, getSorting(order, orderBy)).slice(indexOfFirstPage, indexOfLastPage);

  const handleChangeCheckbox = () => {
    setSelectAll(selectAll === true ? false : true);
    setDataCheck(selectAll === true ? [] : storeData);
  };

  const userCanViewThis = (user) => {
    if(user.permissions.isAdministrator || user.permissions.isManagement){
      return true;
    }

    return false;
  };

  function sort(property){
    setOrderBy(property);
    if(order === null){
        setOrder('desc');
    }else if(order === 'asc')
        setOrder('desc');
    else if(order === 'desc'){
        setOrder('asc');
    }
  }

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }

    return 0;
  }
    
  function stableSort(array, cmp) {
      const stabilizedThis = array.map((el, index) => [el, index]);

      stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });

      return stabilizedThis.map(el => el[0]);
  }
    
  function getSorting(order, orderBy) {
      return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  return (
    <>
      <Accordion style={{ width: '100%', boxShadow: 'none', background: 'none', border: 'none' }}>
        <AccordionSummary
          style={{ 
            background: '#212121', 
            boxShadow: 'none', 
            padding: 0, 
            margin: 0 
          }}
          expandIcon={<ExpandMoreIcon style={{ color: '#fff' }}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ 
            background: '#212121', 
            boxShadow: 'none', 
            padding: 0, 
            margin: 0 
          }}
        >
          <Grid 
            container 
            style={{ 
              height: 'auto', 
              width: '100%', 
              marginTop: 30 
            }}
          >
            {loading ?
              <>
                <Skeleton variant="rect" className={classes.Skeleton}/>
                <Skeleton variant="rect" className={classes.Skeleton}/>
                <Skeleton variant="rect" className={classes.Skeleton}/>
                <Skeleton variant="rect" className={classes.Skeleton}/>
              </>
            :
              <>
                <GroupModalsStoreInformation
                  selectedBrands={selectedBrands} 
                  data={storeData}
                />

                <Grid container alignItems="center" justify="space-between" style={{ width: '100%', margin: '5px auto', paddingTop: 10 }}>
                  {loading ?  
                    <Skeleton variant="rect" width={'100%'} height={48} />
                  :
                    <Carousel
                      setObjActual={setObjActual} 
                      width="100%"
                    />
                  }
                </Grid>

                <Grid item style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '5px auto', padding: '0px 16px', height: '49px' }}>
                  <Grid item style={{ width: '5%', marginLeft: '-8px', marginRight: '16px' }}>
                    {userCanViewThis(user) ?
                      <Checkbox
                        checked={selectAll}
                        onChange={handleChangeCheckbox}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    :
                      null
                    }
                  </Grid>

                  {dataCheck.length > 0 && userCanViewThis(user) ?
                    <Grow timeout={100} in={dataCheck.length > 0}>
                      <Grid item style={{ display: 'flex', alignItems: 'center', height: '49px', justifyContent: 'space-around', width: '100%' }}>
                        <>
                          <Tooltip title="Deflator" arrow>
                            <IconButton size="small" aria-label="def" className={classes.buttonGrow} onClick={() => setOpenDeflator(true)}>
                              <BsBarChartFill size={28} style={{ color: '#fff' }}/>
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Capacidade" arrow>
                            <IconButton size="small" aria-label="cap" className={classes.buttonGrow} onClick={() => setOpenCapacity(true)}>
                              <MdPlaylistAddCheck size={28} style={{ color: '#fff' }}/>
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Ligar/Desligar" arrow>
                            <IconButton size="small" aria-label="lig" className={classes.buttonGrow} onClick={() => setOpenTurnOn(true)}>
                              <GoVerified size={28} style={{ color: '#fff' }}/>
                            </IconButton>
                          </Tooltip>
                        </>
                      </Grid>
                    </Grow>
                  :
                    <Grid style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                      <Button onClick={() => sort(objActual.link)}>
                        <Typography className={classes.Boxwhite}>{objActual.name}</Typography>
                        {order === null ? '' : order === 'asc' && orderBy === objActual.link ? <FaArrowDown className={classes.setColorWhite} /> : order === 'desc' && orderBy === objActual.link ? <FaArrowUp className={classes.setColorWhite} /> : ''}
                      </Button>
                    </Grid>
                  }
                </Grid>

                <ModalDeflator open={openDeflator} setOpen={setOpenDeflator} data={dataCheck} />
                <ModalTurnOnOff open={openTurnOn} setOpen={setOpenTurnOn} data={dataCheck} />
                <ModalCapacity open={openCapacity} setOpen={setOpenCapacity} data={dataCheck} />
                
                {currentPacking.map(item => {
                  return(
                    <AdmCardMobile 
                      item={item}
                      objActual={objActual}
                      setDataCheck={setDataCheck} 
                      dataCheck={dataCheck} 
                      checkFlag={selectAll}
                      currentPage={currentPage}
                    />
                  );
                })}
                
                <Grid container justify="center" alignItems="center">
                  <Pagination
                    packingPerPage={packingperpage} 
                    totalPacking={storeData.length}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </Grid>
              </>
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}



