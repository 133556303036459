export const styles = {
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: '2px',
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid gray`,
    backgroundColor: 'gray',
    opacity: 1,
  },
  checked: {},
  focusVisible: {},
}