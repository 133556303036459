import api from '../../sellerManagementApi';
import { SELLERMANAGEMENTTOKEN, ENCRIPTPASS } from '../../../consts/services';
import CryptoJS from 'crypto-js';


function decryptToken(){
  const bytes = CryptoJS.AES.decrypt(SELLERMANAGEMENTTOKEN, ENCRIPTPASS);
  return bytes.toString(CryptoJS.enc.Utf8);
}

export const insertStoreOnOrchestrator = async (sellerId, filial, filialCode, city, state, brandId, supervisorEmail, businessHour, CNPJ) => {
  const response = await api.post(`/sellers`, {
    sellerId: sellerId,
    CNPJ: CNPJ,
    filial: filial.toUpperCase(),
    filialCode: filialCode,
    city: city.toUpperCase(),
    state: state.toUpperCase(),
    brandId: brandId,
    supervisorEmail: supervisorEmail,
    businessHour: businessHour
  }, {
    headers: { 'authorization': decryptToken() }
  });

  return response.data;
}

export const insertVtexKeysOnStore = async (sellerId, brandId) => {
  const response = await api.post(`/sellers/keys`, {
    brandId: brandId,
	  sellerId: sellerId
  }, {
    headers: { 'authorization': decryptToken() }
  });

  return response.data;
}

export const insertVtexPricingOnStore = async (sellerId) => {
  const response = await api.post(`/sellers/pricing`, {
	  sellerId: sellerId
  }, {
    headers: { 'authorization': decryptToken() }
  });

  return response.data;
}