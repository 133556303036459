import api from '../api';
import sellerManagementApi from '../sellerManagementApi';
import { AUTHORIZATIONTOKEN, SELLERMANAGEMENTTOKEN, ENCRIPTPASS } from '../../consts/services';
import CryptoJS from 'crypto-js';

function decryptToken(){
  const bytes = CryptoJS.AES.decrypt(SELLERMANAGEMENTTOKEN, ENCRIPTPASS);
  return bytes.toString(CryptoJS.enc.Utf8);
}

export const getEmbracingFiliais = async (startDate, endDate, brandId) => {
  const response = await api.get(`brand/${brandId}?startDate=${startDate}&endDate=${endDate}`, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const getAllSellersByBrandId = async (brandId) => {
  const response = await sellerManagementApi.get(`/sellers/brand/${brandId}`, {
    headers: { 'authorization': decryptToken() }
  });

  return response.data;
}