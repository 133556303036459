import React, { useEffect, useState } from 'react';

import { 
  Grid, 
  ThemeProvider,
  Accordion,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Typography
} from '@material-ui/core';

import { 
  useStyles, 
  materialTheme 
} from './styles';

export default function ActionsInAccordionSummary({ 
    item,
    setDataCheck, 
    dataCheck, 
    checkFlag,
    DockId1,
    currentPage
}) {
    const classes = useStyles();
    const [checked, setChecked] = useState(false);

    const getTypography = (id) => {
        const dock = (item.docks).filter(item => item.id === id);

        if(dock.length === 0){
            return "-"
        }
    
        const active = dock[0].isActive && dock[0].isActive === true ? 'ATIVO' : 'INATIVO';
        return `${dock[0].dockTime} | ${active}`
    }

    useEffect(() => {
        if(dataCheck.length === 0){
            setChecked(false);
        }
    }, [dataCheck]);

    useEffect(() => {
        if(checkFlag === true){
            setChecked(true);
        }else{
            setChecked(false);
        }
    }, [checkFlag]);
    
    useEffect(() => {
        const sellerIsChecked = dataCheck.find(element => element.seller === item.seller);

        if(sellerIsChecked){
            setChecked(true);
        }else{
            setChecked(false);
        }
    }, [currentPage, dataCheck, item.seller]);

    const handleCheckBox = (event) => {
        if(event.target.checked === true){
          const arr = [...dataCheck, item]
          setDataCheck(arr)
        }else if(event.target.checked === false){
          const arr = dataCheck.filter(element => { return item.seller !== element.seller});
          setDataCheck(arr)
        }
        event.stopPropagation();
    };

    return (
        <div className={classes.root}>
            <ThemeProvider theme={materialTheme}>
                <Accordion 
                    expanded={false} 
                    defaultExpanded={true} 
                    className={checked ? classes.AccordionSelected : classes.Accordion}
                >
                    <AccordionSummary>
                        <FormControlLabel
                            checked={checked}
                            aria-label="Acknowledge"
                            onClick={(event) => handleCheckBox(event)}
                            onFocus={(event) => event.stopPropagation()}
                            control={<Checkbox color='primary'/>}
                        /> 

                        <Grid container justify="space-between" alignItems="center" style={{ width: '100%' }}>
                            <Grid item container className={classes.boxNameSeller}>
                                <Grid container item className={classes.contentNameSeller}>
                                    <Grid item style={{ maxWidth: '200px' }}>
                                        <Typography className={classes.typographySeller}>
                                            {item.seller}
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ maxWidth: '200px' }}>
                                        <Typography className={classes.typographyFilial}>
                                            {item.filial}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            <Grid className={classes.boxItem}>
                                <Typography className={classes.Boxwhite}>
                                    {getTypography(DockId1)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                </Accordion>
            </ThemeProvider>
        </div>
    );
}