import api from '../api';
import { AUTHORIZATIONTOKENFUNCTION } from '../../consts/services';

export const createUser = async (name, email, password, perfil, brandId) => {
  const response = await api.post(`/users/create` , {
    name: name,
    email: email,
    password: password,
    id_perfil: perfil,
    brandId: brandId
  }, {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}

export const editUser = async (name, email, password, newPassword) => {
  const response = await api.put(`/users/edit` , {
    name: name,
    email: email,
    oldPassword: password,
    password: newPassword,
  }, {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}

