import api from '../api';
import { AUTHORIZATIONTOKENFUNCTION } from '../../consts/services';

export const getBrandsByUser = async (id) => {
  const response = await api.get(`/usersBrands/brands/${id}` , {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}

export const AssociateBrandsUser= async (id, brands) => {
  const response = await api.post(`/usersBrands/edit/${id}` ,{
    brands: brands
  }, {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}

export const AssociateSellersUser= async (id, sellers) => {
  const response = await api.put(`/usersBrands/edit/${id}` ,{
    sellers: sellers
  }, {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}

export const GetStoresWithoutUserAssociation = async () => {
  const response = await api.get(`/usersBrands`, {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}