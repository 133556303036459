import {
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  heading: {
    fontWeight: 600,
    fontSize: 30,
    color: '#fff',
  },
  boxItem: {
    width: '16%',  
    textAlign: 'center', 
    color: '#fff'
  },
  skeleton: {
    background: '#373435', 
    borderRadius: 8, 
    padding: 10 
  },
  bigContainerBackground: {
    width: '49%', 
    height: 490
  },
  smallContainer: {
    width: '49%', 
    height: 330
  },
  bigContainerGraphy: {
    width: '49%', 
    height: 490, 
    background: '#373435', 
    borderRadius: 8, 
    padding: 10
  },
  smallContainerBackground: {
    width: '49%', 
    height: 330, 
    background: '#373435', 
    borderRadius: 8, 
    padding: 10 
  },
  contentBackground: {
    width: '100%', 
    height: 330, 
    background: '#373435', 
    borderRadius: 8, 
    padding: 10
  }
}));