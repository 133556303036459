import React, { useCallback, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {  
    Grid,
    Typography,
    TextField,
    ThemeProvider,
    Button
} from '@material-ui/core';

import { 
    useStyles, 
    theme 
} from './styles';

export default function ChooseListItemsAndAdd({ title, itemsToChoose = null, setList, type = "autocomplete", mode="single" }) {
    const classes = useStyles();

    const [item, setItem] = useState("");

    const addElement = useCallback((item) => {
        if(item === ""){
            return null
        }

        if(mode === "Double"){
            const splitItems = item.split(',');

            if(splitItems.length === 1){
                setList(prevArray => [...prevArray, item]);
                setItem("");
            }

            if(splitItems.length > 1){
                splitItems.forEach(ref => {
                    setList(prevArray => [...prevArray, ref.trim()]);
                })
                setItem("");
            }
        }

        if(mode === "single"){
            setList(prevArray => [...prevArray, item]);
            setItem("");
        }
    }, [setList, mode]);

    return (
        <>
            <Grid item container justify='flex-start' direction='column' className={classes.container}>
                <Grid item>
                    <Typography className={classes.title}> 
                        {title} 
                    </Typography>
                </Grid>
                <Grid item>
                    <ThemeProvider theme={theme}>
                        <Grid container alignItems='center' justify='space-between'>
                            <Grid item style={{ flex: 1, marginRight: 10 }}>
                                {type === "textField" ?
                                    <TextField
                                        id="ChooseListItemsAndAdd"
                                        size='small'
                                        multiline
                                        rows={1}
                                        value={item}
                                        onChange={(e) => setItem(e.target.value === null ? "" : e.target.value)}
                                        variant='outlined'
                                        style={{ width: '100%' }}
                                    />
                                :
                                    <Autocomplete
                                        limitTags={1}
                                        disableClearable
                                        inputValue={item}
                                        onChange={(event, newValue) => {
                                            setItem(newValue.value);
                                        }}
                                        size="small"
                                        id="autoCompleteChooseListItemsAndAdd"
                                        options={itemsToChoose}
                                        getOptionLabel={(option) => option.key}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" autoComplete='off'/>
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                <Button onClick={() => addElement(item)} style={{ minWidth: 15, background: '#212121', color:'#fff', padding: '6px 14px' }}>+</Button>
                            </Grid>
                        </Grid>
                    </ThemeProvider>
                </Grid>
            </Grid>
        </>
    );
}