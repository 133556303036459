import {
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  heading: {
    fontWeight: 600,
    fontSize: 30,
    color: '#fff',
  },
  containerTransparentTitleTable: {
    display: 'flex', 
    alignItems: 'center', 
    width: '100%', 
    margin: '5px auto',
    height: '49px', 
    justifyContent: 'space-between',
  },
  boxItem: {
    display: 'flex',
    color: '#fff', 
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
  },
  headerTableTypography: {
    fontWeight: 800, 
    color: '#fff',
    fontSize: 12,
  },
  setColorWhite: {
    color: '#fff',
    fontSize: 12,
    paddingLeft: 6,
  },
  Skeleton: {
    background: '#373435', 
    width: '100%', 
    height: '60px', 
    margin: '5px auto', 
    borderRadius: 8
  }
}));