import React from 'react';

import { 
  FaArrowRight
} from 'react-icons/fa';

export const INDICATORS = [
  { 
    link: 'collectionsLength', 
    name: 'Coleções', 
    icon: <FaArrowRight size={20}/>, 
    title: 'Coleções' 
  },
  { 
    link: 'minimumPrice', 
    name: 'Preço Mínimo', 
    icon: <FaArrowRight size={20}/>, 
    title: 'Preço mínimo' 
  },
  { 
    link: 'integrateProductsLength', 
    name: 'Forçar produtos', 
    icon: <FaArrowRight size={20}/>, 
    title: 'Forçar produtos' 
  },
  { 
    link: 'deleteProductsLength', 
    name: 'Excluir produtos', 
    icon: <FaArrowRight size={20}/>, 
    title: 'Excluir produtos' 
  },
  { 
    link: 'tablePrice', 
    name: 'Tabela preço', 
    icon: <FaArrowRight size={20}/>, 
    title: 'Tabela preço' 
  },
];