import React from 'react';
import Routes from './routes';
import { Paper } from '@material-ui/core';
import './global.css';

import { AuthProvider } from './context/authContext';
import { HookThemeProvider } from './context/themeContext';
import { LogisticProvider } from './context/logisticContext';

export default function App() {
  return (
    <LogisticProvider>
    <HookThemeProvider>
      <AuthProvider>
          <Paper elevation={0}>
            <Routes />
          </Paper>
      </AuthProvider>
    </HookThemeProvider>
    </LogisticProvider>
  );
}

