import api from '../api';
import { AUTHORIZATIONTOKEN } from '../../consts/services';

export const ajustSellerCapacityScenarios = async (scenario, sellersArray) => {
  const response = await api.put(`/capacity/scenarios` , {
    scenario: scenario, 
    sellersArray: sellersArray,
  }, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}