import {
  makeStyles,
  createMuiTheme
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    fontSize: 30,
    color: '#fff',
  },
  subtitle: {
    fontWeight: 600,
    fontSize: 24,
    color: '#fff',
  },
  button: {
    background: '#373435', 
    color: '#fff', 
    borderColor: '#fff',
    boxShadow: 'none',
    minWidth: 150,

    '&:hover': {
      background: '#5c5758',
    }
  },
  createStoreButton: {
    background: '#373435', 
    color: '#fff', 
    borderColor: '#fff',
    boxShadow: 'none',
    minWidth: '30%',
    minHeight: 40,

    '&:hover': {
      background: '#5c5758',
    }
  },
  retireTextDecoration: {
    textDecoration: 'none'
  },
  autoWidth: {
    width: 'auto'
  },
  headerContainer: {
    padding: '15px 0'
  },
  contentTextField: {
    width: '40%',
  },
  dividerPrincipal: {
    background: '#373435',
    height: '3px'
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  dropZone: {
    background: 'transparent',
    border: '1px solid #fff',
    color: '#fff'
  },
  image: {
    color: '#fff',
    fontSize: 45
  }
}));

export const theme = createMuiTheme({
  overrides: {
    MuiDropzoneSnackbar: {
      errorAlert: {
        backgroundColor: "#cd4c4c",
        color: "#000"
      },
      successAlert: {
        backgroundColor: "#FAA",
        color: "#000"
      },
      infoAlert: {
        backgroundColor: "#FAA",
        color: "#000"
      },
      warningAlert: {
        backgroundColor: "#cd4c4c",
        color: "#000"
      }
    },
    MuiDropzonePreviewList: {
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
        marginTop: 20,
        margin: 'none'
      },
      imageContainer: {
        padding: '32px 0px',
      },
      removeButton: {
        background: '#373435',
        color: '#fff',

        '&:hover': {
          color: '#222',
        }
      }
    },
    MuiDropzoneArea: {
      textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      text: {
        marginBottom: 10,
        fontSize: '15px',
        fontWeight: 600
      },
      icon: {
        color: '#fff'
      }
    },
    MuiTypography: {
      body1: {
        fontSize: '12px',
        fontWeight: 500
      }
    }
  }
});