import React, { useEffect, useState } from 'react';

import { 
  Grid, 
  ThemeProvider,
  Accordion,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Typography
} from '@material-ui/core';

import { 
  useStyles, 
  materialTheme 
} from './styles';

export default function ActionsInAccordionSummary({ 
  item,
  setDataCheck, 
  dataCheck, 
  checkFlag,
  DockId1,
  DockId2,
  DockId3,
  DockId4,
  currentPage
}) {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  const getTypography = (id) => {
    const dock = (item.docks).filter(item => item.id === id);

    if(dock.length === 0){
      return "-"
    }
   
    const active = dock[0].isActive && dock[0].isActive === true ? 
      'ATIVO' 
    : 
      'INATIVO';

    return `${dock[0].dockTime} | ${active}`
  }

  useEffect(() => {
    if(dataCheck.length === 0){
      setChecked(false);
    }
  }, [dataCheck]);

  useEffect(() => {
    if(checkFlag === true){
      setChecked(true);
    }else{
      setChecked(false);
    }
  }, [checkFlag]);

  useEffect(() => {
    const sellerIsChecked = dataCheck.find(element => element.seller === item.seller);

    if(sellerIsChecked){
      setChecked(true);
    }else{
      setChecked(false);
    }

  }, [currentPage, dataCheck, item.seller]);

  const handleCheckBox = (event) => {
    if(event.target.checked === true){
      const arr = [...dataCheck, item]
      setDataCheck(arr)
    }else if(event.target.checked === false){
      const arr = dataCheck.filter(element => { return item.seller !== element.seller});
      setDataCheck(arr)
    }
    event.stopPropagation();
  };

  return (
    <div className={classes.root}>
      <ThemeProvider theme={materialTheme}>
        <Accordion 
          expanded={false} 
          defaultExpanded={true}
          className={checked ? classes.AccordionSelected : classes.Accordion}
        >
          <AccordionSummary>
            <FormControlLabel
              checked={checked}
              aria-label="Acknowledge"
              onClick={(event) => handleCheckBox(event)}
              onFocus={(event) => event.stopPropagation()}
              control={<Checkbox color={"#fff"}/>}
              style={{ width: '5%' }}
            /> 

            <Grid 
              container 
              justify="space-between" 
              alignItems="center" 
              style={{ width: '100%' }}
            >
              <Grid 
                style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  width: '20%' 
                }}
              >
                <Grid 
                  container 
                  item 
                  style={{ 
                    flexDirection: 'column', 
                    maxWidth: '150px' 
                  }}
                >
                  <Grid item style={{ maxWidth: '200px' }}>
                    <Typography 
                      style={{ 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis', 
                        color: '#fff',
                        fontSize: 14,
                        fontWeight: 900
                      }}
                    >
                      {item.seller}</Typography>
                  </Grid>
                  <Grid item style={{ maxWidth: '200px' }}>
                    <Typography 
                      style={{ 
                        color: '#808080', 
                        fontSize: 12, 
                        fontWeight: 500, 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis', 
                        whiteSpace: 'nowrap' 
                      }}
                    >
                      {item.filial}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classes.boxItem}>
                <Typography className={classes.Boxwhite}>
                  {`${item.address[0].cidade} | ${item.address[0].estado}`}
                </Typography>
              </Grid>
              
              <Grid className={classes.boxItem}>
                <Typography className={classes.Boxwhite}>
                  {getTypography(DockId1)}
                </Typography>
              </Grid>

              <Grid className={classes.boxItem}>
                <Typography className={classes.Boxwhite}>
                  {getTypography(DockId2)}
                </Typography>
              </Grid>
              
              <Grid className={classes.boxItem}>
                <Typography className={classes.Boxwhite}>
                  {getTypography(DockId3)}
                </Typography>
              </Grid>

              <Grid className={classes.boxItem}>
                <Typography className={classes.Boxwhite}>
                  {getTypography(DockId4)}
                </Typography>
              </Grid>  
            </Grid>
          </AccordionSummary>
        </Accordion>
      </ThemeProvider>
    </div>
  );
}