import { 
  makeStyles,
  createMuiTheme
} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

export const materialTheme = createMuiTheme({
  overrides: {
    MuiPaginationItem: {
      rounded: {
        color: '#fff',
      },
    },
  },
});