import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        background: '#373435',
        borderRadius: 8 
      },
      divider: {
        background: '#645e60',
        height: '2px',
        width: '100%',
        margin: '0 auto'
      },
      typography: {
        fontWeight: 600, 
        color: '#fff', 
        minWidth: 30
      },
      typographyNoRegisters: {
        color: '#645e60',
        fontWeight: 900, 
        minWidth: 30
      }
}));