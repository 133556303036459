import React, { useState } from 'react';

import { 
  Edit as EditIcon, 
  AddCircle as AddCircleIcon 
} from '@material-ui/icons';

import { ToastContainer, toast } from 'react-toastify';

import {  
    Button,
    Grid,
    Typography,
    Input,
    Popover
} from '@material-ui/core';

import { 
  EditFiliaisInformation 
} from '../../services/branch';

import PopoverCreateBranchState from '../PopoverCreateBranchState';

import { 
  useStyles 
} from './styles';

export default function InfoPopoverBrazilMap(props) {
  const classes = useStyles(props);

  const [editFilial, setEditFilial] = useState("");
  const [editCodRedeLojas, setEditCodRedeLojas] = useState("");
  const [editRedeLojas, setEditRedeLojas] = useState("");
  const [newFilial, setNewFilial] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorAddPopover, setAnchorAddPopover] = useState(null);
  
  //Função que cria a requisição para atualizar a filial
  const updateFilialOfStateRedeLoja = async () => {
    //Se a filial for modificada...
    if(newFilial !== "") {
      try{
        await EditFiliaisInformation(
          props.state, 
          editCodRedeLojas, 
          newFilial
        );
        toast.success("Filial alterada com sucesso! Por favor, atualize a página para ver a modificação");
      }catch{
        toast.error("Erro ao alterar Filial");
      }
    }
  }

  const handleClose = () => {
      setAnchorEl(null);
  }

  const handleCloseAddPopover = () => {
      setAnchorAddPopover(null);
  }

  const printEditPopup = (siglaState, cod_filial, cod_rede_lojas, rede_lojas) => {
    return (
      <div>
        <Grid xs={12} className={classes.grid}>
          <Typography className={classes.siglaState}>
            {siglaState} - {rede_lojas}
          </Typography>
        </Grid>
        <Grid xs={12} className={classes.gridCenter}>
          <Grid className={classes.gridInputCodFilial}>
            <Typography className={classes.sectionNameTitle}>
              Codigo da Nova Filial:
            </Typography>
            <Input 
              className={classes.inputCodFilial} 
              defaultValue={cod_filial} 
              onChange={(e) => {setNewFilial(e.target.value)}} 
              disableUnderline={true} 
            />
          </Grid>
          <Button 
            className={classes.updateButton} 
            onClick={updateFilialOfStateRedeLoja}
          >
            Alterar
          </Button>
        </Grid>
      </div>
    )
  }

  const openUpdatePopover = Boolean(anchorEl);
  const idUpdatePopover = openUpdatePopover ? 'simple-popover' : undefined;

  const openAddPopover = Boolean(anchorAddPopover);
  const idAddPopover = openAddPopover ? 'simple-popover' : undefined;

  return (
    <div>
      <Grid xs={12} className={classes.grid}>
        <Typography className={classes.siglaState}>
          {props.state}
        </Typography>
      </Grid>
      {
        props.array.map((element) => (
          <Grid xs={12} className={classes.grid}>
            <Typography className={classes.rede_loja}>
              {element["DESC_REDE_LOJAS"]}:
            </Typography>
            <Grid className={classes.gridRowSpaceBetween}>
              <Grid className={classes.gridRow}>
                <Typography className={classes.filial}>
                  {element["CODIGO_FILIAL"]}
                </Typography>
                <Typography className={classes.filial}>
                  {element["FILIAL"]}
                </Typography>
              </Grid>
              <button 
                className={classes.editIcon} 
                onClick={(e) => {
                  setAnchorEl(e.currentTarget); 
                  setEditFilial(element["CODIGO_FILIAL"].trim()); 
                  setEditCodRedeLojas(element["REDE_LOJAS"].trim()); 
                  setEditRedeLojas(element["DESC_REDE_LOJAS"].trim());
                }}
              >
                <EditIcon />
              </button>
            </Grid>
          </Grid>
        ))
      }

      <Grid xs={12} className={classes.gridAddButton}>
        <button 
          className={classes.createIcon} 
          onClick={(e) => {setAnchorAddPopover(e.currentTarget);}}
        >
          <AddCircleIcon />
        </button>
      </Grid>

      <Popover
        id={idUpdatePopover}
        open={openUpdatePopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
        }}
        transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
        }}
      >
        {
          printEditPopup(props.state, editFilial, editCodRedeLojas, editRedeLojas)
        }
      </Popover>

      <PopoverCreateBranchState
        id={idAddPopover}
        open={openAddPopover}
        anchorEl={anchorAddPopover}
        onClose={handleCloseAddPopover}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
        }}
        transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
        }}
        siglaState={props.state}
        toast={toast}
      />

      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover={false}
      />
    </div>
  );
}