import React, { useState } from 'react';

import { 
  ajustSellerCapacityScenarios 
} from '../../services/capacity';

import { 
  materialTheme, 
  useStyles 
} from './styles';

import LoadingModal from '../LoadingModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Typography,
    ThemeProvider,
    TextField,
    MenuItem
} from '@material-ui/core';

import { SCENARIOS } from '../../consts/sellerStatus';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, setOpen, data }) {
    const classes = useStyles();
    const [loadingModalOpen, setLoadingModalOpen] = useState(false);
    const [scenario, setScenario] = useState("");

    const onClose = () => {
      setOpen(false);
    }

    const handleChange = (event) => {
      setScenario(event.target.value);
    };

    const submitConfigurations = async (data) => {
      try{
        setLoadingModalOpen(true);
        await ajustSellerCapacityScenarios(scenario, data);
        toast.success(`Cenário das lojas atualizado para ${scenario}`);
        setLoadingModalOpen(false);
      }catch(err){
        toast.error(`Erro ao realizar troca das lojas para o cenário ${scenario}`);
      }
    }

    return (
      <>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => onClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          maxWidth='sm' 
          fullWidth={false}
        >
          <ThemeProvider theme={materialTheme}>
            <DialogTitle className={classes.BoxDialog} id="alert-dialog-slide-title">
              <Grid 
                container 
                alignItems="center" 
                justify="space-between" 
                direction="row"
              >
                <Grid item className={classes.ItemHeader}>
                  <Typography className={classes.Title}>
                    Configurações de capacidade
                  </Typography>
                </Grid>              
              </Grid>
            </DialogTitle>
            <DialogContent className={classes.BoxDialog}>
              <DialogContentText id="alert-dialog-slide-description">
                <Grid container className={classes.smallMargin} direction="column" justify="flex-start" alignItems="flex-start">
                  <Grid item>
                    <Typography className={classes.Subtitle}>
                      {data.length === 1 ? `Você selecionou ${data.length} loja` : `Você selecionou ${data.length} lojas`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      id="select-deflator"
                      select
                      size="small"
                      label="novo cenário"
                      value={scenario}
                      onChange={handleChange}
                      variant="outlined"
                      className={classes.textFieldSelect}
                    >
                      {SCENARIOS.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>

                <Grid className={classes.smallMargin} container justify="flex-end" alignItems="center">
                  <Button className={classes.Button} onClick={() => onClose()}>
                    Voltar
                  </Button>
                  <Button className={classes.Button} onClick={() => submitConfigurations(data)}>
                    Confirmar
                  </Button>
                </Grid>
              </DialogContentText>
            </DialogContent>
          </ThemeProvider> 
        </Dialog>
        
        <LoadingModal
          open={loadingModalOpen}
          title={"Ajustando status da loja"}
        />

        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={true}
        />
      </>
    );
}