import { 
  makeStyles,
  createMuiTheme
} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  BoxDialog: {
    background: '#212121',
    color: '#fff',
  },
  Title: {
    textAlign: 'center', 
    fontSize: 18, 
    flex: 1
  },
  StoreSituationBox: {
    padding: 10, 
    width: '90%', 
    height: 60, 
    margin: '0 auto', 
    flexDirection: 'row', 
    borderRadius: 8
  },
  SituationItem: {
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center'
  },
  ContainerFAQ: {
    background: '#373435', 
    padding: 10, 
    width: '90%', 
    height: '100%', 
    margin: '0 auto', 
    flexDirection: 'column', 
    borderRadius: 8
  },
  BoxFAQ: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    padding: 5
  },
  TypographyTitleFAQ: {
    width: '30%', 
    fontSize: 16, 
    fontWeight: 600, 
    color: '#fff', 
    padding: 10, 
    textAlign: 'center'
  },
  TypographyDataFAQ: {
    color: '#fff', 
    width: '60%', 
    textAlign: 'center'
  }
}));

export const materialTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#fff',
      },
      input: {
        color: '#fff'
      }  
    },
    MuiInputLabel: {
      outlined: {
        color: '#fff',
      }
    },
    MuiFormHelperText: {
      contained: {
        color: '#fff',
      }
    },
    MuiButton: {
      label: {
        color: '#fff'
      }
    },
  },
});