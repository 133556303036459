import React from 'react';

import { 
  IoIosArrowBack 
} from 'react-icons/io';

import { 
  FaPlay, 
  FaPause, 
  FaStop 
} from 'react-icons/fa';

import { 
  useStyles, 
  materialTheme 
} from './styles';

import {
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Typography,
    Divider,
    ThemeProvider
} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ 
  open, 
  setOpen 
}) {
  const classes = useStyles();

  const onClose = () => {
    setOpen(false);
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth='md' fullWidth={true}
      >
        <ThemeProvider theme={materialTheme}>
          <DialogTitle 
            className={classes.BoxDialog} 
            style={{ textAlign: 'center', fontSize: 18 }} 
            id="alert-dialog-slide-title"
          >
            <Grid 
              container 
              alignItems="center" 
              justifyContent="flex-start" 
              direction="row"
            >
              <Button onClick={() => onClose()} color="secondary">
                <IoIosArrowBack size={18} style={{ color: '#fff'}}/>
              </Button>
              <Typography className={classes.Title}>
                Indicadores
              </Typography>
            </Grid>
          </DialogTitle>
          <DialogContent className={classes.BoxDialog}>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid 
                container 
                justify="space-between" 
                className={classes.StoreSituationBox}
              >
                <Grid item className={classes.SituationItem}>
                  <FaPlay style={{ marginRight: '10px', color: '#42a31c' }}/>
                  <Typography style={{ color: '#fff' }}>
                    Ligado
                  </Typography>
                </Grid>
                <Grid item className={classes.SituationItem}>
                  <FaPause style={{ marginRight: '10px', color: '#d62b2b' }}/>
                  <Typography style={{ color: '#fff' }}>
                    Teto batido
                  </Typography>
                </Grid>
                <Grid item className={classes.SituationItem}>
                  <FaStop style={{ marginRight: '10px', color: '#d62b2b' }}/>
                  <Typography style={{ color: '#fff' }}>
                    Desligado
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.ContainerFAQ}>
                <Grid item className={classes.BoxFAQ}>
                  <Typography className={classes.TypographyTitleFAQ}>
                    Tempo Romaneio
                  </Typography>
                  <Typography className={classes.TypographyDataFAQ}>
                    É o tempo médio, em dias, que a loja leva para encerrar os romaneios
                  </Typography>
                </Grid>
                <Grid 
                  container 
                  style={{ width: '100%', background: '#fff' }} 
                  alignItems="center"
                >
                  <Divider light />
                </Grid>
                <Grid item className={classes.BoxFAQ}>
                  <Typography className={classes.TypographyTitleFAQ}>
                    TMA
                  </Typography>
                  <Typography className={classes.TypographyDataFAQ}>
                    Representa o tempo médio, em horas, que a loja leva para atender os pedidos omni
                  </Typography>
                </Grid>
                <Grid 
                  container 
                  style={{ width: '100%', background: '#fff' }} 
                  alignItems="center"
                >
                  <Divider light />
                </Grid>
                <Grid item className={classes.BoxFAQ}>
                  <Typography className={classes.TypographyTitleFAQ}>
                    Recusa
                  </Typography>
                  <Typography className={classes.TypographyDataFAQ}>
                    Representa a taxa de cancelamento da loja. (cancelados / total)
                  </Typography>
                </Grid>
                <Grid 
                  container 
                  style={{ width: '100%', background: '#fff' }} 
                  alignItems="center"
                >
                  <Divider light />
                </Grid>
                <Grid item className={classes.BoxFAQ}>
                  <Typography className={classes.TypographyTitleFAQ}>
                    Pendentes
                  </Typography>
                  <Typography className={classes.TypographyDataFAQ}>
                    São os pedidos que estão com o status igual a "pendente" ou "em separação
                  </Typography>
                </Grid>
                <Grid 
                  container 
                  style={{ width: '100%', background: '#fff' }} 
                  alignItems="center"
                >
                  <Divider light />
                </Grid>
                <Grid item className={classes.BoxFAQ}>
                  <Typography className={classes.TypographyTitleFAQ}>
                    Pendentes antigos
                  </Typography>
                  <Typography className={classes.TypographyDataFAQ}>
                    São os pedidos pendentes há mais de 2 dias
                  </Typography>
                </Grid>
                <Grid 
                  container 
                  style={{ width: '100%', background: '#fff' }} 
                  alignItems="center"
                >
                  <Divider light />
                </Grid>
                <Grid item className={classes.BoxFAQ}>
                  <Typography className={classes.TypographyTitleFAQ}>
                    Totais
                  </Typography>
                  <Typography className={classes.TypographyDataFAQ}>
                    Quantidade total de pedidos capturados pela loja nos últimos 7 dias
                  </Typography>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </ThemeProvider> 
      </Dialog>
    </div>
  );
}