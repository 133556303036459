import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import {
    Grid,
    Typography
} from '@material-ui/core';

import { 
  useStyles 
} from './styles';

export default function IndicatorsBox({ 
  title, 
  value, 
  loading 
}) {
  const classes = useStyles();

  return(
    <>
      {loading === true ?
        <Skeleton 
          variant="rect" 
          className={classes.Skeleton} 
          height={120} 
        />
      :
        <Grid container
          className={classes.ContainerBg}
          alignItems="center"
          justify="center"
          direction="column"
        >
          <Typography className={classes.Title}>
            {title}
          </Typography>

          <Typography className={classes.Value}>
            {value}
          </Typography>
        </Grid>
      }               
    </>
  );
}