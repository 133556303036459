import React, { useState, useEffect } from 'react';

/**
 * CSS imports 
*/

import { 
  useStyles, 
  materialTheme 
} from './styles';

/**
 * Library Imports 
*/

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Typography,
    ThemeProvider
} from '@material-ui/core';

/**
 * Component imports 
*/

import LoadingModal from '../LoadingModal';
import TextFieldIntegrationRules from '../../components/TextFieldIntegrationRules';
import AutoCompleteChooseItem from '../../components/AutoCompleteChooseItem';
import AutoCompleteChooseMultipleItems from '../../components/AutoCompleteChooseMultipleItems';

/**
 * Context imports  
*/

import { useLogistic } from '../../context/logisticContext';

/**
 * Services imports 
*/

import { addShippingPolicies } from '../../services/logisticSetup/shippingPolicies';
import { addDocks, addShippingPolicieOnDocks } from '../../services/logisticSetup/docks';
import { addWarehouses } from '../../services/logisticSetup/warehouses';

/**
 * Modal transition 
*/

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalAddLogisticStore({ 
  open, 
  setOpen,
  brand,
  type
}) {
    const classes = useStyles();
    const { getShippingPoliciesByBrandId, getDocksByBrandId } = useLogistic();
    const [loadingModalOpen, setLoadingModalOpen] = useState(false);

    //Commom
    const [idVtex, setIdVtex] = useState("");
    const [name, setName] = useState("");

    //ShippingPolicies
    const [method, setmethod] = useState("");

    //Docks
    const [costTime, setCostTime] = useState(0);
    const [costTimeOverhead, setCostTimeOverhead] = useState(0);
    const [dockShippingPolicie, setDockShippingPolicie] = useState([]);

    //warehouses
    const [cost, setCost] = useState(0);
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState("");
    const [dockWarehouse, setDockWarehouse] = useState("");

    //all shippingPolicies list
    const [allShippingPolicies, setAllShippingPolicies] = useState([]);

    //all docks list
    const [allDocks, setAllDocks] = useState([]);

    useEffect(() => {
        if(type === 2){
            getShippingPoliciesByBrandId(brand)
            .then(response => {
                const shippingPolicies = response.map(item => {
                    return {
                        key: item.idVtex,
                        value: item.idVtex
                    }
                })

                setAllShippingPolicies(shippingPolicies);
            })
        }

        if(type === 3){
            getDocksByBrandId(brand)
            .then(response => {
                const docks = response.map(item => {
                    return {
                        key: item.idVtex,
                        value: item.idVtex
                    }
                })

                setDockWarehouse(docks[0]);
                setAllDocks(docks);
            })
        }
    }, [type, brand, getShippingPoliciesByBrandId, getDocksByBrandId]);

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    const onClose = () => {
        setIdVtex("");
        setName("");
        setOpen(false);
    }

    const onSubmit = async () => {
        setLoadingModalOpen(true);

        try{
            if(type === 1){
                await addShippingPolicies(idVtex, name, method, brand);
            }
    
            if(type === 2){
                const dockCreated = await addDocks(idVtex, name, costTime, costTimeOverhead, brand);
                
                if(dockShippingPolicie.length > 0){
                    await addShippingPolicieOnDocks(dockCreated.idVtex, dockShippingPolicie, brand);
                }
            }
    
            if(type === 3){
                await addWarehouses(dockWarehouse.key ? dockWarehouse.key : null, days, hours, cost, brand);
            }
    
            setIdVtex("");
            setName("");
            setOpen(false);
    
            setLoadingModalOpen(false);
            toast.success("configurações realizadas com sucesso");
            await timeout(2500);
            window.location.reload();
        }catch(err){
            setLoadingModalOpen(false);
            toast.error(err.response.data.error);
        }
    }

    return (
        <>
            <ThemeProvider theme={materialTheme}>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => onClose()}
                    aria-labelledby="modalEditTitle"
                    aria-describedby="modalEditDescription"
                    fullWidth={true}
                >  
                    <DialogTitle 
                        className={classes.BoxDialog} 
                        style={{ textAlign: 'center', fontSize: 18 }} 
                        id="modalEditTitle"
                    >
                        <Grid container alignItems="center" justify="space-between" direction="row">
                            <Grid item className={classes.ItemHeader}>
                                <Typography className={classes.Title}>
                                    {`Adicionar ${type === 1? 'Transportadoras' : type === 2 ? 'Docas' : ' docas ao estoque'}`}
                                </Typography>
                            </Grid>              
                        </Grid>
                    </DialogTitle>
                    <DialogContent className={classes.BoxDialog}>
                        <DialogContentText id="modalEditDescription">
                            {type === 1 ?
                                <Grid container direction='column' justify='space-between' alignItems='center'>
                                    <Grid item className={classes.textFieldContentFullwidth}>
                                        <TextFieldIntegrationRules 
                                            rows={1}
                                            objectToRender={idVtex}
                                            title={'ID'}
                                            stateToChange={setIdVtex}
                                        />
                                    </Grid>
                                    <Grid item className={classes.textFieldContentFullwidth}>
                                        <TextFieldIntegrationRules 
                                            rows={1}
                                            objectToRender={name}
                                            title={'Nome'}
                                            stateToChange={setName}
                                        />
                                    </Grid>
                                    <Grid item className={classes.textFieldContentFullwidth}>
                                        <TextFieldIntegrationRules 
                                            rows={1}
                                            objectToRender={method}
                                            title={'Método de envio'}
                                            stateToChange={setmethod}
                                        />
                                    </Grid>
                                </Grid>
                            : type === 2 ?
                                <>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item className={classes.textFieldContentFullwidth}>
                                            <TextFieldIntegrationRules 
                                                rows={1}
                                                objectToRender={idVtex}
                                                title={'ID'}
                                                stateToChange={setIdVtex}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item className={classes.textFieldContent}>
                                            <TextFieldIntegrationRules 
                                                rows={1}
                                                objectToRender={costTime}
                                                title={'custo'}
                                                stateToChange={setCostTime}
                                            />
                                        </Grid>
                                        <Grid item className={classes.textFieldContent}>
                                            <TextFieldIntegrationRules 
                                                rows={1}
                                                objectToRender={costTimeOverhead}
                                                title={'custo indireto'}
                                                stateToChange={setCostTimeOverhead}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item className={classes.textFieldContentFullwidth}>
                                            <AutoCompleteChooseMultipleItems 
                                                title={"Transportadora associada"}
                                                itemsToChoose={allShippingPolicies}
                                                setItems={setDockShippingPolicie}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item className={classes.textFieldContentFullwidth}>
                                            <TextFieldIntegrationRules 
                                                rows={1}
                                                objectToRender={name}
                                                title={'Nome'}
                                                stateToChange={setName}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            :
                                <>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item className={classes.textFieldContentFullwidth}>
                                            <AutoCompleteChooseItem
                                                title={"Doca associada"}
                                                itemsToChoose={allDocks}
                                                item={dockWarehouse}
                                                setItem={setDockWarehouse}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item className={classes.textFieldContent}>
                                            <TextFieldIntegrationRules 
                                                rows={1}
                                                objectToRender={days}
                                                title={'Dias'}
                                                stateToChange={setDays}
                                            />
                                        </Grid>
                                        <Grid item className={classes.textFieldContent}>
                                            <TextFieldIntegrationRules 
                                                rows={1}
                                                objectToRender={hours}
                                                title={'Horas'}
                                                stateToChange={setHours}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item className={classes.textFieldContentFullwidth}>
                                            <TextFieldIntegrationRules 
                                                rows={1}
                                                objectToRender={cost}
                                                title={'Custo'}
                                                stateToChange={setCost}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        <Grid 
                            style={{ marginTop: 60 }} 
                            container 
                            justify="flex-end" 
                            alignItems="center"
                        >
                            <Grid item>
                                <Button className={classes.Button} onClick={() => onClose()}>
                                    Voltar
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button className={classes.Button} onClick={() => onSubmit()}>
                                    Confirmar
                                </Button>
                            </Grid>
                        </Grid>
                        </DialogContentText>
                    </DialogContent> 
                </Dialog>
            </ThemeProvider>

            <LoadingModal
                open={loadingModalOpen}
                title={"Ajustando configurações"}
            />

            <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover={false}
            />
      </>
    );
}