import {
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  observation: {
    color: '#000',
    padding: 5,
  },
  siglaState: {
    fontWeight: "bold",
    color: '#000',
    padding: 5,
  },
  createIcon: {
    padding: 10,
    borderRadius: 5,
    backgroundColor: "#DDDDDD",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: 'pointer',
  },
  gridAddButton: {
    display: "flex", 
    flexDirection: "row", 
    justifyContent: "center", 
    alignItems: "center",
  },
}));