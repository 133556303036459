import React from 'react';
import Pagination from '@material-ui/lab/Pagination';

import { 
  ThemeProvider 
} from '@material-ui/core/styles';

import { 
  materialTheme, 
  useStyles 
} from './styles';

export default function PaginationRounded({
  packingPerPage, 
  totalPacking, 
  currentPage, 
  setCurrentPage
}) {
  const classes = useStyles();
  const pageNumbers = [];

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  for(let i = 1; i <= Math.ceil(totalPacking / packingPerPage); i++){
    pageNumbers.push(i);
  }

  return (
    <ThemeProvider theme={materialTheme}>
      <div className={classes.root}>
        <Pagination 
          page={currentPage} 
          onChange={handleChange} 
          count={pageNumbers.length} 
          shape="rounded" 
        />
      </div>
    </ThemeProvider>
  );
}