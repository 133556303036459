import { createMuiTheme } from "@material-ui/core/styles";

export const styles = {
  boxPrincipal: {
    width: '60%',
    height: 'auto',
    margin: '0px auto',
  },
  boxPrincipalMobile: {
    width: '95%',
    height: 'auto',
    margin: '0px auto',
  },
  btn: {
    width: '300px',
    height:'40px',
    margin: '10px auto',
    marginBottom: '10px',
  },
  title: {
    margin: '10px auto',
  }
}

export const materialTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#fff',              
      },
      input: {
        color: '#fff',
      },
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#fff',
        },
      }, 
    },
    MuiInputLabel: {
      outlined:{
        color: '#fff',
        '&:hover': {
          color: 'blue',
          border: 'blue',
        }
      }
    },
    MuiCardContent:{
      root: {
        padding: '7x',
      }
    },
    MuiCardActions: {
      root: {
        padding: '8px 0px'
      }
    },
    MuiTypography: {
      h6: {
        color: '#fff'
      },
    },
    MuiButton: {
      root: {
        borderColor: '#fff'
      },
      outlined: {
        borderColor: '#fff'
      },
      label: {
        color: '#fff'
      }
    },
  },
});