import React, { useEffect, useState } from 'react';

import {  
    Grid,
    Typography
} from '@material-ui/core';

import { 
  getFiliaisInformation 
} from '../../services/branch';

import { 
  pageGrid, 
  PageBackground 
} from '../../stylesCSS';

import { 
  useStyles 
} from './styles';

import LoadingModal from '../LoadingModal';
import BrazilMap from '../BrazilMap';
import Footer from '../Footer';

export default function BranchesOfStates(props) {
  const classes = useStyles(props);
  
  const [infoBranchesOfStates, setInfoBranchesOfStates] = useState({});
  const [loadingModalOpen, setLoadingModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      setLoadingModalOpen(true);
      let aux = {};

      try{
        const response = await getFiliaisInformation();

        response.forEach(function(info) {
          if(aux[info["UF"]]) {
            aux[info["UF"]].push({
              CODIGO_FILIAL: info["CODIGO_FILIAL"],
              DESC_REDE_LOJAS: info["DESC_REDE_LOJAS"],
              FILIAL: info["FILIAL"],
              REDE_LOJAS: info["REDE_LOJAS"],
            })
          } else {
            aux[info["UF"]] = [{
              CODIGO_FILIAL: info["CODIGO_FILIAL"],
              DESC_REDE_LOJAS: info["DESC_REDE_LOJAS"],
              FILIAL: info["FILIAL"],
              REDE_LOJAS: info["REDE_LOJAS"],
            }]
          }
        });

        setInfoBranchesOfStates(aux);
      }catch{
        console.error("Erro ao Carregar Informações das Filiais de cada Estado");
      }

      setLoadingModalOpen(false);
    })();
  }, [props])

  return(
    <div style={PageBackground}>
      <div style={pageGrid}>
        <Grid xs={12} style={{ marginTop: '40px' }}>
          <Typography 
            variant="h4" 
            gutterBottom 
            className={classes.sectionNameTitle}
          >
            Filiais das Redes Loja
          </Typography>
        </Grid>
        <Grid xs={12} style={{ marginTop: '10px' }}>
          <Typography 
            variant="body2" 
            gutterBottom 
            className={classes.observation}
          >
            Clique em um estado para ver suas filiais
          </Typography>
        </Grid>
        <Grid xs={12} 
          container
          spacing={0}
          direction="column"
          alignItems="center"
          style={{ marginBottom: 10 }}
        >
          <BrazilMap states={infoBranchesOfStates} />
        </Grid>

        <LoadingModal
          open={loadingModalOpen}
          title={"Carregando Dados"}
        />
      </div>
      <Footer />
    </div>
  );
}