import React from "react";

import { MdDashboard } from "react-icons/md";

import {
  FaUserEdit,
  FaUserPlus,
  FaTruck,
  FaStore,
  FaNetworkWired,
} from "react-icons/fa";

import { AiOutlineStock, AiOutlineAppstore } from "react-icons/ai";

import { GoGraph, GoTools } from "react-icons/go";

export const getMenu = (user) => {
  if (!user) return [];

  if (user.permissions.isAdministrator) {
    return [
      {
        link: "dashboard",
        title: "Dashboard",
        icon: <MdDashboard size={18} />,
      },
      {
        link: "dashboard/supervisor",
        title: "Indicadores",
        icon: <GoGraph size={18} />,
      },
      {
        link: "deflator",
        title: "Deflator por referência",
        icon: <AiOutlineStock size={18} />,
      },
      {
        link: "users/create",
        title: "Criar usuários",
        icon: <FaUserPlus size={18} />,
      },
      {
        link: "users/edit",
        title: "Editar perfil",
        icon: <FaUserEdit size={18} />,
      },
      {
        link: "integration",
        title: "Regras de integração",
        icon: <FaNetworkWired size={18} />,
      },
      {
        link: "automatization",
        title: "Criação de loja",
        icon: <AiOutlineAppstore size={18} />,
      },
      {
        link: "orchestrator",
        title: "Configurações de orquestrador",
        icon: <GoTools size={18} />,
      },
      {
        link: "logistic",
        title: "Logística",
        icon: <FaTruck size={18} />,
      },
      {
        link: "branches_of_states",
        title: "Filiais por Estados",
        icon: <FaStore size={18} />,
      },
    ];
  } else if (user.permissions.isSupervisor) {
    return [
      {
        link: "dashboard/supervisor",
        title: "Dashboard",
        icon: <MdDashboard size={18} />,
      },
      {
        link: "users/edit",
        title: "Editar perfil",
        icon: <FaUserEdit size={18} />,
      },
    ];
  } else if (user.permissions.isEcommerce) {
    return [
      {
        link: "dashboard/ecommerce",
        title: "Dashboard",
        icon: <MdDashboard size={18} />,
      },
      {
        link: "users/edit",
        title: "Editar perfil",
        icon: <FaUserEdit size={18} />,
      },
    ];
  } else if (user.permissions.isManagement) {
    return [
      {
        link: "dashboard/management",
        title: "Dashboard",
        icon: <MdDashboard size={18} />,
      },
      {
        link: "deflator",
        title: "Deflator por referência",
        icon: <AiOutlineStock size={18} />,
      },
      {
        link: "users/edit",
        title: "Editar perfil",
        icon: <FaUserEdit size={18} />,
      },
      {
        link: "integration",
        title: "Regras de integração",
        icon: <FaNetworkWired size={18} />,
      },
      {
        link: "orchestrator/scenarios",
        title: "Cenários do orquestrador",
        icon: <FaNetworkWired size={18} />,
      },
      {
        link: "logistic",
        title: "Logística",
        icon: <FaTruck size={18} />,
      },
    ];
  }
};
