import React, { useEffect, useState } from 'react';
import moment from 'moment';

import TableBusinessIndicatorsMobile from '../TableBusinessIndicators';
import ChartBrandIndicators from '../../components/ChartBrandIndicators';
import ChartShareIndicators from '../../components/ChartShareIndicators';
import AutoCompleteIndicators from '../../components/AutoCompleteIndicators';
import ChartDoughnut from '../../components/ChartDoughnut';

import Skeleton from '@material-ui/lab/Skeleton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { 
  useStyles 
} from './styles';

import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';

import { 
  INDICATORS, 
  UNITS 
} from '../../consts/accordionBusiness';

export default function AccordionBusiness({ 
  title, 
  CDIndicators, 
  storeIndicators, 
  graphyIndicators, 
  shareIndicators, 
  collectionsIndicators, 
  loading 
}) {
  const classes = useStyles();

  const [indicator, setIndicator] = useState('Cancelamento');
  const [indicatorUnit, setIndicatorUnit] = useState('TOTAL');
  const [dataGraphy, setDataGraphy] = useState([]);
  const [titleDataGraphy, setTitleDataGraphy] = useState([]);

  const filterStoreByUnit = (indicator, array) => {
    let filteredArray = [];

    if(indicator === 'TOTAL'){
      filteredArray = array.filter(item => { return item.unidade_negocio === 'TOTAL'});
    }else if(indicator === 'LOJAS'){
      filteredArray = array.filter(item => item.unidade_negocio === 'LOJAS');
    }else if(indicator === 'CD_RJ'){
      filteredArray = array.filter(item => item.unidade_negocio === 'CD_RJ');
    }else if(indicator === 'CD_SP'){
      filteredArray = array.filter(item => item.unidade_negocio === 'CD_SP');
    }

    return filteredArray;
  };

  const filterStoreByIndicator = (indicator, array) => {
    let filteredArray = [];

    if(indicator === 'TMA'){
      filteredArray = array.map(item => item.TMA);
    }else if(indicator === 'Cancelamento'){
      filteredArray = array.map(item => item.cancelamento);
    }else if(indicator === 'Pedidos'){
      filteredArray = array.map(item => item.pedidos);
    }

    return filteredArray;
  };

  useEffect(() => {
    const array = [];

    graphyIndicators.forEach(item => {
      if(item.unidade_negocio === 'TOTAL'){
        array.push(moment(item.data_captura).format("DD/MM"))
      }  
    });

    setTitleDataGraphy(array);
  }, [graphyIndicators]);

  useEffect(() => {
    let array = [];

    array = filterStoreByUnit(indicatorUnit, graphyIndicators);
    array = filterStoreByIndicator(indicator, array);

    setDataGraphy(array);
  }, [graphyIndicators, indicator, indicatorUnit]);

  const handleChangeOptionIndicator = (option) => {
    setIndicator(option);
  };

  const handleChangeOptionIndicatorUnit = (option) => {
    setIndicatorUnit(option);
  };

  return (
    <>
      <Accordion style={{ width: '100%', boxShadow: 'none', background: 'none', border: 'none' }}>
        <AccordionSummary
          style={{ 
            background: '#212121', 
            boxShadow: 'none', 
            padding: 0, 
            margin: 0 
          }}
          expandIcon={<ExpandMoreIcon style={{ color: '#fff' }}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ 
            background: '#212121', 
            boxShadow: 'none', 
            padding: 0, 
            margin: 0 
          }}
        >
          <Grid container direction="column">
            <Grid 
              container 
              direction="column" 
              justify="space-between" 
              alignItems="center" 
              style={{ marginBottom: 20 }}
            >
              {loading === true ?
                <Skeleton 
                  variant="rect" 
                  style={{ 
                    background: '#373435', 
                    borderRadius: 8, 
                    padding: 10 
                  }} 
                  width="100%" 
                  height={'auto'} 
                />
              :
                <Grid 
                  container 
                  direction="column" 
                  justify="space-between" 
                  style={{ 
                    width: '100%', 
                    height: 'auto' 
                  }}
                >
                  <Grid item style={{ marginBottom: 20 }}>
                    <TableBusinessIndicatorsMobile 
                      data={storeIndicators} 
                      titleTable={"Lojas"}
                    />
                  </Grid>

                  <Grid item style={{ marginBottom: 20 }}>
                    <TableBusinessIndicatorsMobile 
                      data={CDIndicators} 
                      titleTable={"CD"}
                    />
                  </Grid>
                </Grid>
              }

              {loading === true ?
                <Skeleton 
                  variant="rect" 
                  style={{ 
                    background: '#373435', 
                    borderRadius: 8, 
                    padding: 10 
                  }} 
                  width="100%" 
                  height={'auto'} 
                />
              :
                <Grid 
                  container 
                  style={{ 
                    width: '100%', 
                    height: 'auto', 
                    background: '#373435', 
                    borderRadius: 8, 
                    padding: 10
                  }}
                >
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                      <AutoCompleteIndicators 
                        handleChange={handleChangeOptionIndicatorUnit} 
                        actual={indicatorUnit} 
                        data={UNITS} 
                        title={"Unidades"}
                      />
                    </Grid>
                    <Grid item>
                      <AutoCompleteIndicators 
                        handleChange={handleChangeOptionIndicator} 
                        actual={indicator} 
                        data={INDICATORS} 
                        title={"Indicadores"}
                      />
                    </Grid>
                  </Grid>

                  <Grid container style={{ width: '100%', height: 400 }}>
                    <ChartBrandIndicators  
                      graphyTitle={titleDataGraphy} 
                      graphyData={dataGraphy} 
                      indicator={indicator}
                    />
                  </Grid>
                </Grid>
              }
            </Grid>

            <Grid 
              container 
              direction="column" 
              justify="space-between" 
              alignItems="center" 
              style={{ marginBottom: 20 }}
            >
              {loading === true ?
                <Skeleton 
                  variant="rect" 
                  style={{ 
                    background: '#373435', 
                    borderRadius: 8, 
                    padding: 10 
                  }} 
                  width="100%" 
                  height={330} 
                />
              :
                <Grid 
                  container 
                  style={{ 
                    width: '100%', 
                    height: 330, 
                    background: '#373435', 
                    borderRadius: 8, 
                    padding: 10,
                    marginBottom: 20
                  }}
                >
                  <Grid 
                    item 
                    container 
                    style={{ 
                      width: '100%' , 
                      marginBottom: 10 
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 600,
                        fontSize: 18,
                        color: '#fff',
                      }}
                    >
                      Share pedidos
                    </Typography>
                  </Grid>
                  <Grid item container style={{ width: '100%', height: 290 }}>
                    <ChartShareIndicators 
                      shareIndicators={shareIndicators}
                    />
                  </Grid> 
                </Grid>
              }
              

              <Grid 
                container 
                direction="row" 
                justify="space-between" 
                alignItems="center" 
                style={{ 
                  width: '100%', 
                  height: 330 
                }}
              >
                {loading === true ?
                  <Skeleton 
                    variant="rect" 
                    style={{ 
                      background: '#373435', 
                      borderRadius: 8, 
                      padding: 10 
                    }} 
                    width="100%" 
                    height={330} 
                  />
                :
                  <Grid 
                    container 
                    style={{ 
                      width: '100%', 
                      height: 330, 
                      background: '#373435', 
                      borderRadius: 8, 
                      padding: 10
                    }}
                  >
                    <Grid item container style={{ width: '100%' , marginBottom: 10 }}>
                      <Typography
                        style={{
                          fontWeight: 600,
                          fontSize: 18,
                          color: '#fff',
                        }}
                      >
                        Venda por coleção
                      </Typography>
                    </Grid>
                    <Grid item container style={{ width: '100%', height: 290 }}>
                      <ChartDoughnut 
                        collectionsIndicators={collectionsIndicators}
                      />
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}