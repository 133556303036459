import { 
  makeStyles,
  createMuiTheme
} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  BoxDialog: {
    background: '#212121',
    color: '#fff',
  },
  BoxDialogMobile: {
    background: '#212121',
    color: '#fff',
    padding: '8px 10px'
  },
  HeaderMenu: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    background: '#373435', 
    borderRadius: 8, 
    padding: 8, 
    marginBottom: 10, 
    flexDirection: 'column', 
    width: '100%',
  },
  Title: {
    fontSize: 16, 
    color: '#fff', 
    fontWeight: 900, 
    paddingRight: 10
  },
  subTitle: {
    fontSize: 12, 
    color: '#fff'
  },
  sellersNameTypography: {
    width: '30%', 
    color: '#fff', 
    textAlign: 'center', 
    whiteSpace: 'nowrap', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis'
  },
  dataNameTypography: {
    width: '10%', 
    color: '#fff', 
    textAlign: 'center'
  },
  HeaderBg: {
    width: '100%', 
    margin: '5px auto', 
    height: 'auto', 
    display: 'flex', 
    justifyContent: 'space-between'
  },
  dataBg: {
    width: '100%', 
    margin: '5px auto', 
    height: '40px', 
    background: '#373435', 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    borderRadius: 8
  },
  skeleton: {
    background: '#373435', 
    width: '100%', 
    height: '60px', 
    margin: '5px auto', 
    borderRadius: 8
  }
}));

export const materialTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#fff',
      },
      input: {
        color: '#fff'
      }  
    },
    MuiInputLabel: {
      outlined: {
        color: '#fff',
      }
    },
    MuiFormHelperText: {
      contained: {
        color: '#fff',
      }
    },
    MuiButton: {
      label: {
        color: '#fff'
      }
    },
    MuiInput: {
      root: {
        color: '#fff'
      },
    },
    MuiInputBase: {
      input: {
        cursor: 'pointer'
      }
    }
  },
});