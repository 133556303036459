import {
  makeStyles,
  createMuiTheme
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  title: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '24px',
    letterSpacing: '0.04em',
    color: '#333'
  },
  subTitle: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.04em',
    textAlign: 'left',
    color: '#808080'
  },
  Accordion: {
    borderRadius: '0px', 
    boxShadow: 'none', 
    border: '2px solid #373435',
    '&:hover': { 
      boxShadow: "2px 2px #BFBFBF"
    },
    width: '100%', 
    margin: '5px auto', 
    height: 'auto', 
    background: '#373435'
  },
  AccordionSelected: {
    width: '100%', 
    borderRadius: '0px', 
    boxShadow: 'none', 
    border: '2px solid #BFBFBF',
    margin: '5px auto', 
    height: 'auto', 
    background: '#353836'
  },
  Hover: {
    '&:hover &__no-hover': {
      opacity: 0,
    },
  
    '&:hover &__hover': {
      opacity: 1,
    }
  },
  boxStore: {
    display: 'flex',
    color: '#fff', 
    width: '33%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '30px',
  },
  boxCheck: {
    display: 'flex',
    color: '#fff', 
    width: '2%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
  },
  boxItem: {
    display: 'flex',
    color: '#fff', 
    width: '13.4%',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
  },
  headerTableTypography: {
    fontWeight: 800, 
    color: '#fff',
    fontSize: 12,
  },
  containerTitleStore: {
    flexDirection: 'column', 
    maxWidth: '30%',
    paddingLeft: 10
  }
}));

export const materialTheme = createMuiTheme({
  overrides: {
    MuiAccordionSummary: {
      content: {
        margin: '0px 0px'
      }
    }
  },
});