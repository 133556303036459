import React, { useEffect, useState } from 'react';

import { 
  Doughnut 
} from 'react-chartjs-2';

import { 
  useStyles 
} from './styles';

function gerar_cor(opacidade = 1) {
  let r = Math.random() * 255;
  let g = Math.random() * 255;
  let b = Math.random() * 255;
  
  return `rgba(${r}, ${g}, ${b}, ${opacidade})`;
}

export default function Chart({ collectionsIndicators }) {
  const classes = useStyles();
  const [colors, setColors] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if(collectionsIndicators !== undefined){
      let totalAux = 0;
      const arrayColors = collectionsIndicators.map(item => {
        totalAux = totalAux + item.quantidade;
        return gerar_cor(1);
      })

      setColors(arrayColors);
      setTotal(totalAux);
    }
  }, [collectionsIndicators]);

  const data = {
    labels: collectionsIndicators ? 
        collectionsIndicators.map(item => `${item.marca} - ${item.colecao}`) 
      : 
        null,
    datasets: [{
      data: collectionsIndicators ? 
        collectionsIndicators.map(item => 
          (((item.quantidade / total) * 100)).toFixed(2)
        ) 
      : 
        null,
      borderColor: colors,
      backgroundColor: colors,
      hoverBackgroundColor: colors,
      hoverBorderColor: '#222',
    }]  
  };

  return(
    <div className={classes.BoxChart}>
      <Doughnut
        data={data}
        width={'100%'}
        height={'auto'}
        options={{
          responsive: true, 
          maintainAspectRatio: false,
          legend: {
            display: false,
            position: 'bottom'
          },         
        }}
      />       
    </div>
  );
}