import React, { createContext, useContext } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

export const ThemeContext = createContext({});

export const HookThemeProvider = ({ children }) => {
  const themeActual = createMuiTheme({
    palette:{
      primary:{
        main: '#DCC6B7'
      },
    },
  });

  return (
    <ThemeProvider theme={themeActual}>
    <ThemeContext.Provider value={{ theme: themeActual }}>
      {children}
    </ThemeContext.Provider>
    </ThemeProvider>
  );
};

export function useTheme(){
  const context = useContext(ThemeContext);

  if(!context){
    throw new Error("Não existe contexto ThemeProvider");
  }

  return context;
}