import {
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    fontSize: 30,
    color: '#fff',
  },
  subTitle: {
    fontWeight: 600,
    fontSize: 24,
    color: '#fff',
  },
  button: {
    background: '#373435', 
    color: '#fff', 
    borderColor: '#fff',
    boxShadow: 'none',
    minWidth: 150,

    '&:hover': {
      background: '#5c5758',
    }
  },
  smallButton: {
    background: '#373435', 
    color: '#fff', 
    borderColor: '#fff',
    boxShadow: 'none',
    minWidth: 40,

    '&:hover': {
      background: '#5c5758',
    }
  },
  retireTextDecoration: {
    textDecoration: 'none'
  },
  autoWidth: {
    width: 'auto'
  },
  headerContainer: {
    padding: '15px 0',
  },
  containerLogisticHeader: {
    padding: '0 0 15px 0',
  },
  dividerPrincipal: {
    background: '#373435',
    height: '3px'
  },
  dividerSecondary: {
    background: '#373435',
    height: '4px',
    width: '100px',
    margin: '0 auto'
  },
  containerLogistic: {
    margin: '30px 0'
  },
  contentTextField: {
    width: '40%',
  },
  typographyNoContent: {
    fontWeight: 600,
    fontSize: 14,
    color: '#828181',
  },
  containerNoContent: {
    background: '#373435', 
    padding: '10px', 
    borderRadius: 8
  }
}));