import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    Typography,
    withStyles
} from '@material-ui/core';

import { 
  Favorite as FavoriteIcon 
} from '@material-ui/icons';

import { 
  styles 
} from './styles';

function Footer(props) {
  const { classes } = props;

  return(
    <>           
      <Grid className={classes.style}>
        <Grid container className={classes.phraseConfig}>
          <Typography 
            variant="overline" 
            display="inline" 
            gutterBottom
          >
            FEITO COM
          </Typography>
          <Grid item className={classes.HeartIcon}>
            {<FavoriteIcon style={{fontSize:'1em'}} />}
          </Grid>
          <Typography 
            variant="overline" 
            display="inline" 
            gutterBottom
          >
            PELO SOMALABS
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(Footer);