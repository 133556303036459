import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';

import { 
  FiCalendar 
} from 'react-icons/fi';

import {
  Skeleton
} from '@material-ui/lab';

import { 
  DatePicker, 
  MuiPickersUtilsProvider 
} from "@material-ui/pickers";

import { 
  useAuth 
} from '../../context/authContext';

import { 
  getStoreRevenues 
} from '../../services/storeData';

import { 
  IoIosArrowBack 
} from 'react-icons/io'

import { 
  useStyles, 
  materialTheme 
} from './styles';

import {
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Typography,
    IconButton,
    ThemeProvider
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ 
  open, 
  setOpen,
  selectedBrands 
}) {
    const classes = useStyles();
    const { user } = useAuth();
    const [endDate, setEndDate] = useState(moment().clone().endOf('month').format('YYYY-MM-DD'));
    const [startDate, setStartDate] = useState(moment().clone().startOf('month').format('YYYY-MM-DD'));
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    moment.locale('pt-br');

    moment.updateLocale('pt', {
        months : [
            "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
            "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
        ]
    });

    const getFormattedValue = (value, mobile = false) => {
        if(mobile === true){
        const newValue = value > 1000000 ? `${(value / 1000000).toFixed(0)} mi`  :  `${(value / 1000).toFixed(0)} mil`;
        return newValue;
        }

        const newValue = value > 1000000 ? `${(value / 1000000).toFixed(2)} mi`  :  `${(value / 1000).toFixed(2)} mil`;
        return newValue;
    }

    const onClose = () => {
        setOpen(false);
    }

    const handleDateStartChange = (date) => {
        setStartDate(moment(date).format('YYYY-MM-DD'));
    };

    const handleDateEndChange = (date) => {
        setEndDate(moment(date).format('YYYY-MM-DD'));
    };

    const changeData = useCallback(async () => {
        if(selectedBrands.length > 0){
            (async () => {
                setLoading(true);

                const response = await getStoreRevenues(user.id, startDate, endDate);
                const corporative = selectedBrands.find(element => element === 0);

                let newSelectedBrands = selectedBrands;
            
                if (corporative === 0){
                    newSelectedBrands = [];
                }
            
                if(newSelectedBrands !== undefined && newSelectedBrands.length !== 0){
                    const newData = [];
            
                    response.forEach((item) => {
                    const findItem = selectedBrands.find(element => { 
                        return element === item.rede_lojas 
                    })
            
                    if(findItem !== undefined){
                        newData.push(item);
                    }
                    });
                    setData(newData);
                }else{
                    setData(response);
                }
                setLoading(false);
            })();
        }
    }, [user, selectedBrands, startDate, endDate]);

    useEffect(() => {
        if(selectedBrands.length > 0){
            (async () => {
                await changeData();
            })();
        }
        // eslint-disable-next-line
    }, [user, selectedBrands]);

    return (
        <div>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => onClose()}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
            fullScreen={true}
        >
            <ThemeProvider theme={materialTheme}>
                <DialogTitle 
                    className={classes.BoxDialogMobile} 
                    style={{ textAlign: 'center', fontSize: 18 }} 
                    id="alert-dialog-slide-title"
                >
                    <Grid container alignItems="center" justify="space-between" direction="row">
                        <Grid item className={classes.containerTitle}>
                            <Button onClick={() => onClose()} style={{ minWidth: 0 }}>
                                <IoIosArrowBack size={18} style={{ color: '#fff'}}/>
                            </Button>
                            <Typography style={{ textAlign: 'center', fontSize: 18, marginLeft: 8 }}>
                                Faturamento Lojas
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.BoxDialogMobile}>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Grid container justify="center" alignItems="center">
                            <Grid item container justify='space-between' alignItems='center' direction='row' style={{ width: '300px', margin: '20px auto' }}>
                                <Grid item>
                                    <Grid container alignItems="center" justify="center">
                                        <FiCalendar size={25} style={{ color: '#fff', marginRight: 10 }}/>
                                        <ThemeProvider theme={materialTheme}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                variant="inline"
                                                style={{ width: '90px' }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    color: '#fff',
                                                }}
                                                format="dd/MM/yyyy"
                                                value={moment(startDate)}
                                                onChange={handleDateStartChange}
                                            />
                                            <Typography 
                                                style={{ 
                                                color: '#fff', 
                                                margin: '0px 5px', 
                                                marginRight: 10 
                                                }}
                                            >
                                                -
                                            </Typography>
                                            <DatePicker
                                                variant="inline"
                                                style={{ width: '90px' }}
                                                format="dd/MM/yyyy"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    color: '#fff',
                                                }}
                                                value={moment(endDate)}
                                                onChange={handleDateEndChange}
                                            />
                                            </MuiPickersUtilsProvider>
                                        </ThemeProvider>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={() => changeData()} color="primary" aria-label="search">
                                        <SearchIcon style={{ color: '#fff' }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>

                        {loading ?
                            <>
                                <Skeleton variant="rect" className={classes.skeleton} />
                                <Skeleton variant="rect" className={classes.skeleton} />
                                <Skeleton variant="rect" className={classes.skeleton} />
                                <Skeleton variant="rect" className={classes.skeleton} />
                            </> 
                        :
                            <>
                                <Grid container alignItems="center">
                                    <Grid container className={classes.ContainerHeader}>
                                        <Typography className={classes.DataTypographySmall}>
                                            POS
                                        </Typography>
                                        <Typography className={classes.DataTypographyBig}>
                                            Loja
                                        </Typography>
                                        <Typography className={classes.DataTypographyNormal}>
                                            R$
                                        </Typography>
                                    </Grid>
                                    {data.map((item, index) => {
                                        return (
                                            <Grid container className={classes.ContainerData}>
                                                <Typography className={classes.DataTypographySmall}>
                                                    {`${index + 1}º`}
                                                </Typography>
                                                <Typography className={classes.DataTypographyBig}>
                                                    {item.loja}
                                                </Typography>                           
                                                <Typography className={classes.DataTypographyNormal}>
                                                    {getFormattedValue(item.receita, true)}
                                                </Typography>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </>
                        }
                    </DialogContentText>
                </DialogContent>
            </ThemeProvider> 
        </Dialog>
        </div>
    );
}