import {
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    fontSize: 30,
    color: '#fff',
  },
  subtitle: {
    fontWeight: 600,
    fontSize: 24,
    color: '#fff',
  },
  auxiliarText: {
    fontWeight: 600,
    fontSize: 18,
    color: '#fff',
  },
  button: {
    background: '#373435', 
    color: '#fff', 
    borderColor: '#fff',
    boxShadow: 'none',
    minWidth: 150,

    '&:hover': {
      background: '#5c5758',
    }
  },
  retireTextDecoration: {
    textDecoration: 'none'
  },
  autoWidth: {
    width: 'auto'
  },
  headerContainer: {
    padding: '15px 0',
  },
  contentTextField: {
    width: '30%',
  },
  dividerPrincipal: {
    background: '#373435',
    height: '3px'
  },
  createStoreButton: {
    background: '#373435', 
    color: '#fff', 
    borderColor: '#fff',
    boxShadow: 'none',
    width: '240px',
    margin: 5,
    minHeight: 40,

    '&:hover': {
      background: '#5c5758',
    }
  },
  saveDataButton: {
    background: '#373435', 
    color: '#fff', 
    borderColor: '#fff',
    boxShadow: 'none',
    width: '240px',
    minHeight: 40,

    '&:hover': {
      background: '#5c5758',
    }
  },
  skeletonButton: {
    width: '240px',
    padding: 10,
    margin: 5,
    background: '#373435',
    borderRadius: 8,
    minHeight: 40
  },
  skeletonContainer: {
    width: '100%',
    padding: 10,
    margin: 5,
    background: '#373435',
    borderRadius: 8,
    minHeight: 300 
  }
}));