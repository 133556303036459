import api from '../api';
import { AUTHORIZATIONTOKEN } from '../../consts/services';

export const ajustCapacityNumberScenarios = async (scenario, value, brandId) => {
  const response = await api.put(`/scenarios/capacity/${brandId}` , {
    scenario: scenario, 
    value: value,
  }, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const ajustDeflatorNumberScenarios = async (scenario, minValue, maxValue, brandId) => {
  const response = await api.put(`/scenarios/deflator/${brandId}` , {
    scenario: scenario, 
    minValue: minValue,
    maxValue: maxValue
  }, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}