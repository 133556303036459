import React, { useEffect, useState } from 'react';

import { 
  Autocomplete 
} from '@material-ui/lab';

import { 
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon
} from '@material-ui/icons';

import { 
  TextField, 
  Checkbox 
} from '@material-ui/core';

import { 
  ThemeProvider 
} from '@material-ui/core/styles';

import { 
  theme 
} from './styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags({ 
  UsersBrands, 
  setSelectedBrands 
}) {

  const [brands , setBrands] = useState([]);

  useEffect(() => {
    setBrands(UsersBrands);
  }, [UsersBrands]);

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        multiple
        limitTags={3}
        onChange={(event, newValue) => {
          const array = newValue.map(item => item);
          setSelectedBrands(array);
        }}
        id="checkboxes-tags-demo"
        options={brands}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </React.Fragment>
        )}
        style={{ width: '100%', margin: 8 }}
        renderInput={(params) => (
          <TextField {...params} InputLabelProps={{ shrink: true }} variant="outlined" label="Lojas"/>
        )}
      />
    </ThemeProvider>
  );
}