import {
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  sectionNameTitle: {
    color: '#000',
  },
  grid: {
    padding: 5,
  },
  gridCenter: {
    padding: 5,
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
  },
  siglaState: {
    fontWeight: "bold",
    color: '#000'
  },
  createButton: {
    paddingLeft: 5,
    backgroundColor: "#55AA55",
    display: "flex",
    marginTop: 5,
    color: '#000',
  },
  gridInputCodFilial: {
    display: "flex", 
    flexDirection: "row", 
    justifyContent: "space-between", 
    alignItems: "center",
  },
  inputCodFilial: {
    padding: 5,
    marginLeft: 5,
    backgroundColor: "#DDDDDD",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: 70,
  },
}));