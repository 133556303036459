import React from 'react';

import { 
  FaBox, 
  FaBoxOpen, 
  FaClock, 
  FaBoxes, 
  FaPercentage, 
  FaTruck, 
  FaPlay, 
  FaPause, 
  FaStop 
} from 'react-icons/fa';

import { 
  Grid,
  Typography,
  ThemeProvider,
  Button,
  Card,
  Tooltip,
} from '@material-ui/core';

import { 
  theme, 
  useStyles 
} from './styles';

export default function MultilineTextFields({ 
  item 
}) {
  const classes = useStyles();

  return (
    <>
      <ThemeProvider theme={theme}>
        <Card style={{ width: '100%', margin: '5px auto', height: 'auto', background: '#373435' }}>
          <span>
            <Button disabled className={classes.BoxStore}>
              <Grid style={{ display: 'flex', alignItems: 'center' }}>
                {
                  item.ligado ?
                    (item.abaixo_do_teto === false ?
                      <FaPause size={16} className={classes.pause}/>
                    :
                    item.ligado === true ?
                      <FaPlay size={16} className={classes.play}/>
                    :
                      <FaStop size={16} className={classes.pause}/>
                    )
                  :
                  <FaStop size={16} className={classes.pause}/>
                }
                <Grid item className={classes.boxStoreTypography}>
                  <Typography className={classes.store}>
                    {item.loja}
                  </Typography>
                  <Typography className={classes.filial}>
                    {item.filial}
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </span>

          <Tooltip title="Média de dias de encerramento dos romaneios" arrow>
              <span>
                  <Button disabled className={classes.boxItem}>
                  <Grid style={{ display: 'flex', padding: '15px 0' }}>
                      <FaTruck className={classes.Boxwhite} size={20}/>
                      <Typography className={classes.typographyIndicators}>
                        {`${item.media_romaneio} dias`}
                      </Typography>
                  </Grid>
                  </Button>
              </span>
          </Tooltip>

          <Tooltip title="TMA" arrow>
              <span>
                <Button disabled className={classes.boxItem}>
                  <Grid style={{ display: 'flex', padding: '15px 0' }}>
                    <FaClock className={classes.Boxwhite} size={20}/>
                    <Typography 
                      style={{ 
                        marginLeft: '10px', 
                        minWidth: '60px', 
                        fontWeight: 800, 
                        color: item.tma_horas <= 2 ? 
                          '#028A0F' 
                        : item.tma_horas > 3 ? 
                          '#ff1100' 
                        : 
                          '#fff' 
                      }}
                    >
                      {`${item.tma_horas} h`}
                    </Typography>
                  </Grid>
                </Button>
              </span>
          </Tooltip>

          <Tooltip title="Taxa de Cancelamento" arrow>
              <span>
                  <Button disabled className={classes.boxItem}>
                  <Grid style={{ display: 'flex', padding: '15px 0' }}>
                      <FaPercentage className={classes.Boxwhite} size={20}/>
                      <Typography className={classes.typographyIndicators}>
                        {item.taxa_cancelamento}
                      </Typography>
                  </Grid>
                  </Button>
              </span>
          </Tooltip>

          <Tooltip title="Pedidos Pendentes" arrow>
              <span>
                  <Button disabled className={classes.boxItem}>
                  <Grid style={{ display: 'flex', padding: '15px 0' }}>
                      <FaBoxOpen className={classes.Boxwhite} size={20}/>
                      <Typography className={classes.typographyIndicators}>
                        {item.pedidos_pendentes}
                      </Typography>
                  </Grid>
                  </Button>
              </span>
          </Tooltip>

          <Tooltip title="Pedidos Pendentes com mais de 2 dias" arrow>
              <span>
                  <Button disabled className={classes.boxItem}>
                  <Grid style={{ display: 'flex', padding: '15px 0' }}>
                      <FaBoxes className={classes.Boxwhite} size={20}/>
                      <Typography className={classes.typographyIndicators}>
                        {item.pedidos_pendentes_2dias}
                      </Typography>
                  </Grid>
                  </Button>
              </span>
          </Tooltip>

          <Tooltip title="Pedidos Totais" arrow>
              <span>
                  <Button disabled className={classes.boxItem}>
                  <Grid style={{ display: 'flex', padding: '15px 0' }}>
                      <FaBox className={classes.Boxwhite} size={20}/>
                      <Typography className={classes.typographyIndicators}>
                        {item.pedidos_totais}
                      </Typography>
                  </Grid>
                  </Button>
              </span>
          </Tooltip> 
        </Card>
      </ThemeProvider>
    </>
  );
}