import React from 'react';

import { 
  Grid,
  Typography
} from '@material-ui/core';

import { 
  FaTrophy 
} from 'react-icons/fa';

import { 
  useStyles
} from './styles';

export default function ActionsInAccordionSummary({ 
  seller, 
  supervisor, 
  color, 
  recused,
  invoiced,
  points,
}) {
  const classes = useStyles();

  return (
    <>
      <Grid item className={classes.HeaderMenu}>
        <Grid item container alignItems="center" justify="flex-start">
          <Grid item container style={{ width: 'auto' }}>
            <FaTrophy size={45} color={color} style={{ paddingRight: 10 }}/>
          </Grid>
          <Grid item container direction="column" style={{ width: 'auto' }}>
            <Typography className={classes.seller} >{seller ? seller : 'seller'}</Typography>
            <Typography className={classes.supervisor} >{supervisor ? `${supervisor}` : 'supervisor'}</Typography>
          </Grid>
        </Grid>

        <Grid item container direction="row" alignItems="center" justify="space-between" style={{ paddingTop: 8 }}>
          <Grid item container direction="column" justify="center" alignItems="center" style={{ width: '80px' }}>
            <Typography className={classes.title}>{invoiced ? `${invoiced}` : '0'}</Typography>
            <Typography className={classes.subtitle}>Faturados</Typography>
          </Grid>

          <Grid item container direction="column" justify="center" alignItems="center" style={{ width: '80px' }}>
            <Typography className={classes.title}>{recused ? `${recused}` : '0'}</Typography>
            <Typography className={classes.subtitle}>Recusados</Typography>
          </Grid>

          <Grid item container direction="column" justify="center" alignItems="center" style={{ width: '80px' }}>
            <Typography className={classes.title}>{points ? `${points}` : '0'}</Typography>
            <Typography className={classes.subtitle}>Pontos</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}