import React, { useState } from 'react';

import {  
    Button,
    Grid,
    Typography,
    Input,
    Popover
} from '@material-ui/core';

import { 
  useStyles 
} from './styles';

import { 
  postFiliaisInformation 
} from '../../services/branch';

export default function PopoverCreateBranchState(props) {
  const classes = useStyles(props);

  const [newRedeLojas, setNewRedeLojas] = useState("");
  const [newFilial, setNewFilial] = useState("");    

  const createFilialOfStateRedeLoja = async () => {
    //Se a filial for modificada...
    if(newFilial !== "") {
      try{
        await postFiliaisInformation(
          props.siglaState, 
          newRedeLojas, 
          newFilial
        );
        props.toast.success("Filial alterada com sucesso! Por favor, atualize a página para ver a modificação");
      }catch{
        props.toast.error("Erro ao Adicionar Filial");
      }
    }
  }

  return(
    <Popover
    id={props.id}
    open={props.open}
    anchorEl={props.anchorEl}
    onClose={props.onClose}
    anchorOrigin={props.anchorOrigin}
    transformOrigin={props.transformOrigin}
    >
      <Grid xs={12} className={classes.grid}>
        <Typography className={classes.siglaState}>
          {props.siglaState}
        </Typography>
      </Grid>
      <Grid xs={12} className={classes.gridCenter}>
        <Grid 
          className={classes.gridInputCodFilial} 
          style={{marginBottom: 5}}
        >
          <Typography className={classes.sectionNameTitle}>
            Código da Rede Loja:
          </Typography>
          <Input 
            className={classes.inputCodFilial} 
            onChange={(e) => {setNewRedeLojas(e.target.value)}} 
            disableUnderline={true}
          />
        </Grid>
        <Grid className={classes.gridInputCodFilial}>
          <Typography className={classes.sectionNameTitle}>
            Código da Nova Filial:
          </Typography>
          <Input 
            className={classes.inputCodFilial} 
            onChange={(e) => {setNewFilial(e.target.value)}} 
            disableUnderline={true} 
          />
        </Grid>
        <Button 
          className={classes.createButton} 
          onClick={createFilialOfStateRedeLoja}
        >
          Criar
        </Button>
      </Grid>
    </Popover>
  )
}