import {
  createMuiTheme,
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    background: '#373435', 
    width: '100%', 
    height: 'auto', 
    padding: 15, 
    borderRadius: 8 
  },
  title: {
    fontWeight: 600,
    fontSize: 15,
    color: '#fff',
    padding: '0 0 10px 0',
  },
  itemTextField: {
    width: '45%'
  },
  dateContent: {
    width: 'auto',
    border: '1px solid #fff',
    padding: 4,
    borderRadius: 4,
    textAlign: 'center',
    margin: '0 auto',
    height: 40
  },
  centeredText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

export const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      root: {
        color: '#fff'
      },
    },
    MuiInputBase: {
      input: {
        cursor: 'pointer',
        textAlign: 'center'
      }
    }
  },
});