import React, { useEffect, useState } from 'react';

/**
 * Library imports 
*/

import { 
    Link 
} from 'react-router-dom';

import { 
    FaKey, 
    FaTruckLoading, 
    FaClock, 
    FaWindowClose, 
    FaCalendarCheck, 
    FaMoneyCheckAlt,
    FaStore 
} from 'react-icons/fa';

import { 
    MdEdit, 
    MdDelete, 
    MdPlusOne 
} from 'react-icons/md';

import { 
    GoPerson 
} from 'react-icons/go';

/**
 * CSS imports 
*/

import { 
    PageBackground, 
    pageGrid 
} from '../../stylesCSS';

import { 
    useStyles 
} from './styles';

/**
 * Material UI Imports 
*/

import {  
    Grid,
    Typography,
    Button,
    Divider
} from '@material-ui/core';

/**
 * Context imports 
*/

import { useLogistic } from '../../context/logisticContext';

/**
 * Services imports 
*/

import {
    getAllShippingPolicies
} from '../../services/logisticSetup/shippingPolicies';

import {
    getAllDocks
} from '../../services/logisticSetup/docks';

import {
    getAllWarehouses
} from '../../services/logisticSetup/warehouses';

/**
 * Consts imports 
*/

import { SomaGroupBrands } from '../../consts/brands';

/**
 * Components imports 
*/

import Footer from '../../components/Footer';
import AccordionLogisticSetup from '../../components/AccordionLogisticSetup';
import AutoCompleteChooseBrand from '../../components/AutoCompleteChooseBrand';
import ModalEditSetupStore from '../../components/ModalEditSetupStore';
import ModalAddLogisticStore from '../../components/ModalAddLogisticStore';
import ModalDeleteSetupStore from '../../components/ModalDeleteSetupStore';

/**
 * Principal Component 
*/

export default function StoreLogisticSetup(props) {
    const classes = useStyles(props);
    const { setName, setIdVtex, setShippingMethod, idVtex, name, shippingMethod } = useLogistic();

    const SOMABRANDS = SomaGroupBrands.map(item => {
        return {
            key: item.brandName,
            value: item.brandId
        };
    });

    const [modalAdd, setModalAdd] = useState(false);
    const [modalEditSetup, setModalEditSetup] = useState(false);
    const [modalDeleteSetup, setModalDeleteSetup] = useState(false);
    const [modalEditData, setModalEditData] = useState([]);

    const [type, setType] = useState(1);
    const [data, setData] = useState([]);
    const [docks, setDocks] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [loading, setLoading] = useState(true);

    const [brand, setBrand] = React.useState(() => {
        const brandName = localStorage.getItem(`@maisomni:favoriteBrand`) ? localStorage.getItem(`@maisomni:favoriteBrand`) : "";
        const item = SOMABRANDS.find(item => item.key === brandName);
        return item !== undefined ? item.value : "";
    });

    /**
     *  Get data from shippingPolicies, docks and warehouses 
    */

    useEffect(() => {
        getAllShippingPolicies(brand)
        .then(response => {
            setData(response);
            setLoading(false);
        }).catch(error => {
            console.log(error);
        })
    }, [brand]);

    useEffect(() => {
        getAllDocks(brand)
        .then(response => {
            const newDocks = response.map(item => {
                let saleChannels = '';

                if(item.saleChannels.length > 0){
                    item.saleChannels.forEach(element => {
                        saleChannels = saleChannels + `${element.name}, `
                    });

                    saleChannels = saleChannels.trim().slice(0, -1)
                }

                return {
                    ...item,
                    saleChannels
                }
            })
            
            setDocks(newDocks);
            setLoading(false);
        }).catch(error => {
            console.log(error);
        })
    }, [brand]);

    useEffect(() => {
        getAllWarehouses(brand)
        .then(response => {
            setWarehouses(response);
            setLoading(false);
        }).catch(error => {
            console.log(error);
        })
    }, [brand]);

    /**
     * Functions
    */

    const modalEditOpen = (type) => {
        setType(type);
        setModalEditData(type === 1 ? data : type === 2? docks : warehouses);
        setModalEditSetup(true)
    };

    const modalDeleteOpen = (type) => {
        setType(type);
        setModalEditData(type === 1 ? data : type === 2? docks : warehouses);
        setModalDeleteSetup(true)
    };

    const modalAddOpen = (type) => {
        setType(type);
        setModalAdd(true)
    };

    const transformArrayInString = (array) => {
        let string = "";

        array.forEach(item => {
            string = string + item.idVtex + ","; 
        });

        string = string.substring(0, string.length - 1);

        return string;
    };

    return(
        <>
            <div style={PageBackground}>
                <div style={pageGrid}>

                    <Grid container justify='space-between' alignItems='center' className={classes.headerContainer}>
                        <Grid item container className={classes.autoWidth}>
                            <Typography className={classes.title}>
                                Atualização de logística
                            </Typography>
                        </Grid>

                        <Grid item container spacing={2} className={classes.autoWidth}>
                            <Grid item>
                                <Link to={{ pathname: '/automatization' }} className={classes.retireTextDecoration}>
                                    <Button variant="contained" size="medium" className={classes.button}>
                                        Principal
                                    </Button>
                                </Link>
                            </Grid>

                            <Grid item>
                                <Link to={{ pathname: '/automatization/upload' }} className={classes.retireTextDecoration}>
                                    <Button variant="contained" size="medium" className={classes.button}>
                                        Uploads
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider className={classes.dividerPrincipal} />

                    <Grid container direction='row' alignItems='center' justify='space-between' className={classes.headerContainer}>
                        <Grid item className={classes.contentTextField}>
                            <AutoCompleteChooseBrand 
                                title={"Marca"}
                                itemsToChoose={SOMABRANDS}
                                brand={brand}
                                setBrand={setBrand}
                                storageName={"@maisomni:favoriteBrand"}
                            />
                        </Grid>
                    </Grid>

                    <Grid container className={classes.containerLogistic}>
                        <Grid container justify='space-between' alignItems='center' className={classes.containerLogisticHeader}>
                            <Grid item>
                                <Typography className={classes.subTitle}>
                                    Transportadoras
                                </Typography>
                            </Grid>

                            <Grid container style={{ width: 'auto' }} direction="row" alignItems='center' justify='flex-end'>
                                <Grid item style={{ marginLeft: 15 }}>
                                    <Button 
                                        disabled={data.length === 0 && loading === false ? true : false} 
                                        onClick={() => modalEditOpen(1)} 
                                        variant="contained" 
                                        size="medium" 
                                        className={classes.smallButton}
                                    >
                                        <MdEdit size={20}/>
                                    </Button>
                                </Grid>
                                <Grid item style={{ marginLeft: 15 }}>
                                    <Button 
                                        disabled={data.length === 0 && loading === false ? true : false} 
                                        onClick={() => modalDeleteOpen(1)} 
                                        variant="contained" 
                                        size="medium" 
                                        className={classes.smallButton}
                                    >
                                        <MdDelete size={20}/>
                                    </Button>
                                </Grid>
                                <Grid item style={{ marginLeft: 15 }}>
                                    <Button
                                        onClick={() => modalAddOpen(1)} 
                                        variant="contained" 
                                        size="medium" 
                                        className={classes.smallButton}
                                    >
                                        <MdPlusOne size={20}/>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container>
                            {data.length === 0 && loading === false ?
                                <Grid container className={classes.containerNoContent}>
                                    <Typography className={classes.typographyNoContent}>
                                        Sem transportadoras
                                    </Typography>
                                </Grid>
                            : 
                                data.map(item => {
                                    return (
                                        <AccordionLogisticSetup 
                                            title={item.idVtex}
                                            atributtes={[
                                                {
                                                    title: item.idVtex,
                                                    icon: <FaKey />,
                                                    name: "ID",
                                                    itemName: idVtex,
                                                    setItem: setIdVtex
                                                },
                                                {
                                                    title: item.name,
                                                    name: "Nome",
                                                    icon: <GoPerson />,
                                                    itemName: name,
                                                    setItem: setName
                                                },
                                                {
                                                    title: item.shippingMethod,
                                                    name: "Método de entrega",
                                                    icon: <FaTruckLoading />,
                                                    itemName: shippingMethod,
                                                    setItem: setShippingMethod
                                                }
                                            ]}
                                            loading={loading}
                                        />
                                    )
                                })
                            }
                        </Grid>
                    </Grid>

                    <Divider className={classes.dividerSecondary} />

                    <Grid container className={classes.containerLogistic}>
                        <Grid container justify='space-between' alignItems='center' className={classes.containerLogisticHeader}>
                            <Grid item>
                                <Typography className={classes.subTitle}>
                                    Docas
                                </Typography>
                            </Grid>

                            <Grid container style={{ width: 'auto' }} direction="row" alignItems='center' justify='flex-end'>
                                <Grid item style={{ marginLeft: 15 }}>
                                    <Button 
                                        disabled={docks.length === 0 && loading === false ? true : false} 
                                        onClick={() => modalEditOpen(2)} 
                                        variant="contained" 
                                        size="medium" 
                                        className={classes.smallButton}
                                    >
                                        <MdEdit size={20}/>
                                    </Button>
                                </Grid>
                                <Grid item style={{ marginLeft: 15 }}>
                                    <Button 
                                        disabled={docks.length === 0 && loading === false ? true : false} 
                                        onClick={() => modalDeleteOpen(2)} 
                                        variant="contained" 
                                        size="medium" 
                                        className={classes.smallButton}
                                    >
                                        <MdDelete size={20}/>
                                    </Button>
                                </Grid>
                                <Grid item style={{ marginLeft: 15 }}>
                                    <Button
                                        onClick={() => modalAddOpen(2)} 
                                        variant="contained" 
                                        size="medium" 
                                        className={classes.smallButton}
                                    >
                                        <MdPlusOne size={20}/>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container>
                            {docks.length === 0 && loading === false ?
                                <Grid container className={classes.containerNoContent}>
                                    <Typography className={classes.typographyNoContent}>
                                        Sem Docas
                                    </Typography>
                                </Grid>
                            : 
                                docks.map(item => {
                                    return (
                                        <AccordionLogisticSetup 
                                            title={item.idVtex} 
                                            atributtes={[
                                                {
                                                    title: item.idVtex,
                                                    icon: <FaKey />
                                                },
                                                {
                                                    title: item.name,
                                                    icon: <GoPerson />
                                                },
                                                {
                                                    title: item.costTime,
                                                    icon: <FaClock />
                                                },
                                                {
                                                    title: item.costTimeOverhead,
                                                    icon: <FaWindowClose />
                                                },
                                                {
                                                    title: transformArrayInString(item.shippingPolicies),
                                                    icon: <FaTruckLoading />
                                                },
                                                {
                                                    title: item.saleChannels,
                                                    icon: <FaStore />
                                                }
                                            ]} 
                                            loading={loading}
                                        />
                                    )
                                })
                            }
                        </Grid>
                    </Grid>

                    <Divider className={classes.dividerSecondary} />

                    <Grid container className={classes.containerLogistic}>
                        <Grid container justify='space-between' alignItems='center' className={classes.containerLogisticHeader}>
                            <Grid item>
                                <Typography className={classes.subTitle}>
                                    Docas vinculadas ao estoque
                                </Typography>
                            </Grid>

                            <Grid container style={{ width: 'auto' }} direction="row" alignItems='center' justify='flex-end'>
                                <Grid item style={{ marginLeft: 15 }}>
                                    <Button 
                                        disabled={warehouses.length === 0 && loading === false ? true : false} 
                                        onClick={() => modalEditOpen(3)} 
                                        variant="contained" 
                                        size="medium" 
                                        className={classes.smallButton}
                                    >
                                        <MdEdit size={20}/>
                                    </Button>
                                </Grid>
                                <Grid item style={{ marginLeft: 15 }}>
                                    <Button 
                                        disabled={warehouses.length === 0 && loading === false ? true : false} 
                                        onClick={() => modalDeleteOpen(3)} 
                                        variant="contained" 
                                        size="medium" 
                                        className={classes.smallButton}
                                    >
                                        <MdDelete size={20}/>
                                    </Button>
                                </Grid>
                                <Grid item style={{ marginLeft: 15 }}>
                                    <Button
                                        onClick={() => modalAddOpen(3)} 
                                        variant="contained" 
                                        size="medium" 
                                        className={classes.smallButton}
                                    >
                                        <MdPlusOne size={20}/>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container>
                            {warehouses.length === 0 && loading === false ?
                                <Grid container className={classes.containerNoContent}>
                                    <Typography className={classes.typographyNoContent}>
                                        Sem Docas vinculadas ao estoque
                                    </Typography>
                                </Grid>
                            : 
                                warehouses.map(item => {
                                    return (
                                        <AccordionLogisticSetup 
                                            title={item.dock.idVtex} 
                                            atributtes={[
                                                {
                                                    title: item.dock.idVtex,
                                                    icon: <FaKey />
                                                },
                                                {
                                                    title: item.days,
                                                    icon: <FaCalendarCheck />
                                                },
                                                {
                                                    title: item.hours,
                                                    icon: <FaClock />
                                                },
                                                {
                                                    title: item.cost,
                                                    icon: <FaMoneyCheckAlt />
                                                }
                                            ]} 
                                            loading={loading}
                                        />
                                    )
                                })
                            }
                        </Grid>
                    </Grid>

                </div>
                <Footer />
            </div>

            <ModalAddLogisticStore 
                open={modalAdd}
                setOpen={setModalAdd}
                brand={brand}
                type={type}
            />

            <ModalDeleteSetupStore
                open={modalDeleteSetup}
                setOpen={setModalDeleteSetup}
                atributtes={modalEditData}
                brand={brand}
                type={type}
            />

            <ModalEditSetupStore 
                open={modalEditSetup}
                setOpen={setModalEditSetup}
                atributtes={modalEditData}
                brand={brand}
                type={type}
            />
        </>
    );
}