import React, { useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {  
    Grid,
    Typography,
    TextField,
    ThemeProvider
} from '@material-ui/core';

import { 
    useStyles, 
    theme 
} from './styles';

export default function AutoCompleteChooseBrand({ title, itemsToChoose, item, setItem }) {
    const classes = useStyles();

    useEffect(() => {
        if(itemsToChoose.length > 0){
            const das = itemsToChoose.find(element => element.value === item.value);
            setItem(das !== undefined ? das : itemsToChoose[0]);
        }
    }, [itemsToChoose, item, setItem])

    return (
        <>
            <Grid item container justify='flex-start' direction='column' className={classes.container}>
                <Grid item>
                    <Typography className={classes.title}> 
                        {title} 
                    </Typography>
                </Grid>
                <Grid item>
                    <ThemeProvider theme={theme}>
                        <Autocomplete
                            limitTags={1}
                            disableClearable
                            size="small"
                            id="autoCompleteChooseBrand"
                            options={itemsToChoose}
                            getOptionLabel={(option) => option.key}
                            value={item}
                            onChange={(event, newValue) => {
                                setItem(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" autoComplete='off'/>
                            )}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        </>
    );
}