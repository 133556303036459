import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: 10,
    margin: 5,
    background: '#373435',
    borderRadius: 8
  },
  dividerSecondary: {
    background: '#645e60',
    height: '2px',
    width: '100%',
    margin: '0 auto'
  },
}));