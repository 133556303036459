import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const user = localStorage.getItem('@maisomni:user');
    const token = localStorage.getItem('@maisomni:token');

    if(user && token){
      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  const signIn = useCallback(async (email, password) => {
      const response = await api.post('sessions', {
        email,
        password
      });

      const { token, permissions, sellerManagementToken } = response.data;
      localStorage.setItem('@maisomni:token', token);
      localStorage.setItem('@maisomni:sellerManagementToken', sellerManagementToken);
      localStorage.setItem('@maisomni:permissions', JSON.stringify(permissions));
      localStorage.setItem('@maisomni:user', JSON.stringify(response.data));

      setData({ token, user: response.data });

      return response.data;
    
  }, []);

  const signOut = useCallback((history) => {
    localStorage.removeItem('@maisomni:token');
    localStorage.removeItem('@maisomni:sellerManagementToken');
    localStorage.removeItem('@maisomni:permissions');
    localStorage.removeItem('@maisomni:user');

    setData({});

    history.push('/');
  }, []);
  return (
    <AuthContext.Provider value={{ signIn, user: data.user, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(){
  const context = useContext(AuthContext);

  if(!context){
    throw new Error("Não existe contexto AuthProvider");
  }

  return context;
}