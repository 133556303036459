import React, { useState } from 'react';

import { 
  ajustSellerScenarios,
  fixDeflators 
} from '../../services/deflators';

import { 
  materialTheme, 
  useStyles 
} from './styles';

import LoadingModal from '../LoadingModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Typography,
    ThemeProvider,
    TextField,
    MenuItem
} from '@material-ui/core';

import { SCENARIOS, MODE } from '../../consts/sellerStatus';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, setOpen, data }) {
    const classes = useStyles();
    const [loadingModalOpen, setLoadingModalOpen] = useState(false);
    const [scenario, setScenario] = useState(null);
    const [modeOption, setModeOption] = useState(null);
    const [deflatorFixed, setDeflatorFixed] = useState("");

    const handleChangeReason = (event) => {
      setModeOption(event.target.value);
    };

    const handleChangeMessage = (event) => {
      setDeflatorFixed(event.target.value);
    };

    const onClose = () => {
      setOpen(false);
    }

    const handleChange = (event) => {
      setScenario(event.target.value);
    };

    const submitConfigurations = async (data) => {
      try{
        setLoadingModalOpen(true);

        if(scenario !== null){
          await ajustSellerScenarios(scenario, data);
          toast.success(`Cenário das lojas atualizado para ${scenario}`);
        }

        if(modeOption === "activate" && deflatorFixed !== ""){
          const sellers = data.map(item => item.loja);
          await fixDeflators(sellers, Number(deflatorFixed));
          toast.success(`Deflatores fixados em ${deflatorFixed}`);
        }

        if(modeOption === "disable"){
          const sellers = data.map(item => item.loja);
          await fixDeflators(sellers, null);
          toast.success(`Deflatores desfixados em ${data.length} lojas`);
        }

        setLoadingModalOpen(false);
      }catch(err){
        toast.error(`Erro ao realizar mudanças de deflator nas lojas`);
        setLoadingModalOpen(false);
      }
    }

    return (
      <>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => onClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          maxWidth='sm' 
          fullWidth={false}
        >
          <ThemeProvider theme={materialTheme}>
            <DialogTitle className={classes.BoxDialog} id="alert-dialog-slide-title">
              <Grid 
                container 
                alignItems="center" 
                justify="space-between" 
                direction="row"
              >
                <Grid item className={classes.ItemHeader}>
                  <Typography className={classes.Title}>
                    Configurações de deflator
                  </Typography>
                </Grid>              
              </Grid>
            </DialogTitle>
            <DialogContent className={classes.BoxDialog}>
              <DialogContentText id="alert-dialog-slide-description">
                <Grid container className={classes.smallMargin} direction="column" justify="flex-start" alignItems="flex-start">
                  <Grid item>
                    <Typography className={classes.Subtitle}>
                      {data.length === 1 ? `Você selecionou ${data.length} loja` : `Você selecionou ${data.length} lojas`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      id="select-deflator"
                      select
                      size="small"
                      label="Novo cenário"
                      value={scenario}
                      onChange={handleChange}
                      variant="outlined"
                      className={classes.textFieldSelect}
                      helperText={scenario !== null ? `O cenário será alterado para ${scenario} em ${data.length} lojas` : null}
                    >
                      {SCENARIOS.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>

                <Grid container className={classes.smallMargin} direction="column" justify="flex-start" alignItems="flex-start">
                  <Grid item>
                    <TextField
                      id="outlined-select-currency"
                      select
                      size="small"
                      disabled={deflatorFixed.length > 0 ? true : false}
                      label="Fixar deflator"
                      value={modeOption}
                      onChange={handleChangeReason}
                      variant="outlined"
                      style={{ width: 400, marginBottom: 20 }}
                      helperText={modeOption === "disable" ? `O deflator será desfixado para ${data.length} lojas` : null}
                    >
                      {MODE.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  {modeOption === 'activate' ? 
                    <Grid item>
                      <TextField
                        id="outlined-currency"
                        size="small"
                        value={deflatorFixed}
                        onChange={(e) => handleChangeMessage(e)}
                        variant="outlined"
                        style={{ width: 400, marginBottom: 20 }}
                        placeholder="Digite o valor do deflator a ser fixado"
                        helperText={deflatorFixed === null || deflatorFixed === "" ? `Digite um número para ser fixado no deflator das lojas` : `O deflator ${deflatorFixed} será fixado para ${data.length} lojas`}
                        margin="dense"
                      />
                    </Grid>
                  : null}
                </Grid>

                <Grid className={classes.smallMargin} container justify="flex-end" alignItems="center">
                  <Button className={classes.Button} onClick={() => onClose()}>
                    Voltar
                  </Button>
                  <Button className={classes.Button} onClick={() => submitConfigurations(data)}>
                    Confirmar
                  </Button>
                </Grid>
              </DialogContentText>
            </DialogContent>
          </ThemeProvider> 
        </Dialog>
        
        <LoadingModal
          open={loadingModalOpen}
          title={"Ajustando status da loja"}
        />

        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={true}
        />
      </>
    );
}