import api from "../../sellerManagementApi";
import { SELLERMANAGEMENTTOKEN, ENCRIPTPASS } from "../../../consts/services";
import CryptoJS from "crypto-js";

function decryptToken() {
  const bytes = CryptoJS.AES.decrypt(SELLERMANAGEMENTTOKEN, ENCRIPTPASS);
  return bytes.toString(CryptoJS.enc.Utf8);
}

export const getAllShippingPolicies = async (brandId) => {
  const response = await api.get(
    `/shippingStrategy/shippingPolicies/${brandId}`,
    {
      headers: { authorization: decryptToken() },
    }
  );

  return response.data;
};

export const addShippingPolicies = async (
  id,
  name,
  shippingMethod,
  brandId
) => {
  const response = await api.post(
    `/shippingStrategy/shippingPolicies/${brandId}`,
    {
      id: id,
      name: name,
      shippingMethod: shippingMethod,
    },
    {
      headers: { authorization: decryptToken() },
    }
  );

  return response.data;
};

export const updateShippingPolicies = async (
  id,
  name,
  shippingMethod,
  brandId
) => {
  const response = await api.put(
    `/shippingStrategy/shippingPolicies/${brandId}`,
    {
      id: id,
      name: name,
      shippingMethod: shippingMethod,
    },
    {
      headers: { authorization: decryptToken() },
    }
  );

  return response.data;
};

export const deleteShippingPolicies = async (id, brandId) => {
  const response = await api.delete(
    `/shippingStrategy/shippingPolicies/${brandId}`,
    {
      data: { id: id },
    },
    {
      headers: { authorization: decryptToken() },
    }
  );

  return response.data;
};

export const createCarrier = async (
  sellerId,
  allShippingPolicies,
  businessHour
) => {
  const response = await api.post(
    `/logistic/carrier/${sellerId}`,
    {
      allShippingPolicies: allShippingPolicies,
      businessHour: businessHour,
    },
    {
      headers: {
        authorization: decryptToken(),
      },
    }
  );

  return response.data;
};

export const uploadFreightTable = async (file, sellerId, carrier) => {
  let fd = new FormData();
  fd.append("file", file);

  const response = await api.post(
    `/logistic/carrier/freight/${sellerId}?carrierId=${carrier}`,
    fd,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: decryptToken(),
      },
    }
  );

  return response.data;
};
