import React, { useEffect, useState } from 'react';
import StoreAutomatizationUploadDesktop from '../../PagesDesktop/storeAutomatizationUpload';

//Page principal

const Pages = () => {
  const [isDesk, setDesk] = useState(true);
  useEffect(() => {
    setDesk(window.innerWidth > 768);
  }, []);
  return isDesk ? <StoreAutomatizationUploadDesktop /> : <StoreAutomatizationUploadDesktop />;
};

export default Pages;