import React from 'react';
import clsx from 'clsx';
import maisOmni from '../../assets/logonova.png';

import { 
  Link, 
  useHistory 
} from 'react-router-dom';

import { 
  getMenu 
} from '../../consts/header';

import {
  MdExitToApp 
} from 'react-icons/md';

import { 
  FiChevronRight 
} from 'react-icons/fi';

import { 
  Menu as MenuIcon,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon
} from '@material-ui/icons';

import {
  useAuth 
} from '../../context/authContext';

import { 
  useStyles 
} from './styles';

import {
  Drawer, 
  CssBaseline, 
  AppBar, 
  Toolbar, 
  List, 
  Typography, 
  Divider,
  IconButton, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Paper, 
  Grid 
} from '@material-ui/core';

export default function Header({ children }) {
  const classes = useStyles();
  const history = useHistory();
  const { signOut, user } = useAuth();
  const menuObject = getMenu(user);

  const [open, setOpen] = React.useState(false);
  const [direction, setDirection] = React.useState('left');

  const handleDrawerOpen = () => {
    setOpen(true);
    setDirection('left')
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setDirection('right')
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Paper elevation={0}>
        <AppBar
          color="primary"
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Grid container item xs={12} className={classes.boxName}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                {`Olá, ${user.name}`}
              </Typography>            
            </Toolbar>
          </Grid>
        </AppBar>

        <Drawer
          color="secondary"
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <img 
              src={maisOmni} 
              style={{ width: '90px', marginLeft: '10px' }} 
              alt="logo soma" 
            />

            <IconButton 
              onClick={handleDrawerClose} 
              className={classes.IconButtonMenu}
            >
              {direction === 'left' ? 
                <ChevronLeftIcon /> 
              : 
                <ChevronRightIcon />
              }
            </IconButton>
          </div>
          <Divider className={classes.Divider} />
          <List>
            {menuObject.map(item => {
              return(
                <Link key={item.link} to={`/${item.link}`} style={{ textDecoration: 'none' }}>
                  <ListItem button key={item.link} className={classes.ListItem}>
                    <ListItemIcon className={classes.ListText}>
                      { item.icon ? 
                        item.icon 
                      : 
                        <FiChevronRight />
                      }
                    </ListItemIcon>
                    <ListItemText 
                      className={classes.ListText} 
                      primary={item.title} 
                    />
                  </ListItem>
                </Link>
              );
            })}
          </List>
          <Divider className={classes.Divider}/>
          <List>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <ListItem 
                button 
                key={"logout"} 
                className={classes.ListItem} 
                onClick={(e) => { signOut(history); }}
              >
                <ListItemIcon className={classes.ListText}>
                  {<MdExitToApp size={18} />}
                </ListItemIcon>
                <ListItemText 
                  className={classes.ListText} 
                  primary={"Sair"} 
                />
              </ListItem>
            </Link>
          </List>
        </Drawer>
      </Paper>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
}