import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { 
    styles, 
    materialTheme 
} from './styles';

import {
    Button,  
    Grid,
    Card,
    CardContent,
    CardActions,
    TextField,
    withStyles,
    ThemeProvider
} from '@material-ui/core';

import { 
    AuthContext 
} from '../../context/authContext';

import mais_omni from "../../assets/logonova.png";
import LoadingModal from '../../components/LoadingModal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login(props) {
    const { classes } = props;
    const { signIn } = useContext(AuthContext);

    const [userLogin, setUserLogin] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [loadingModalOpen, setLoadingModalOpen] = useState(false);
  
    const login = async () => {
        if(userLogin === "" || userPassword === ""){
            toast.error("Por favor preencher os campos!");
            return;
        }

        setLoadingModalOpen(true);

        try{
            await signIn(userLogin.toLowerCase(), userPassword);
        }catch(err){
            toast.error("Combinação e-mail/senha incorretas.");
        }

        setLoadingModalOpen(false);
    };

    const handleChange = (e, type) => {
        if(type === "user")
            setUserLogin(e.target.value)
        else if(type === "pass")
            setUserPassword(e.target.value)
    }

    const botaoLogin = () => {
        return (
            <>
                <Button 
                    variant="outlined" 
                    onClick={(e) => { login() }}
                    disableElevation
                    className={classes.btnMobile}
                >
                    Login
                </Button>
            </>
        );
    };

    return (
        <div className={classes.pageBg}>
            <ThemeProvider theme={materialTheme}>
                <Grid 
                    container
                    direction="column"
                    alignItems="center"
                >

                    <LoadingModal
                        open={loadingModalOpen}
                        title={"Verificando dados, Por favor aguarde"}
                    />

                    <ToastContainer
                        position="top-right"
                        autoClose={2500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick={false}
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover={false}
                    />


                    <Card className={classes.CardMobile} variant="outlined">
                        <CardContent>
                            <Grid item className={classes.title}>
                                <img 
                                    src={mais_omni} 
                                    alt="logo-soma" 
                                    style={{width: '200px', height: '54px', margin: '20px auto',}}
                                />
                            </Grid>
                            
                            <TextField 
                                autoComplete='nope' 
                                className={classes.TextField} 
                                id="email"
                                label="E-mail"
                                placeholder="exemplo@email.com"
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                onChange={(e) => handleChange(e, "user")} 
                            />

                            <TextField 
                                className={classes.TextField} 
                                id="password"
                                label="Senha"
                                placeholder="123456"
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined" 
                                type="password" 
                                onChange={(e) => handleChange(e, "pass")} 
                            />                           
                        </CardContent>
                        <CardActions>
                            {botaoLogin()}
                        </CardActions>
                    </Card>
                </Grid>
            </ThemeProvider>
        </div>
    );
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
export default withStyles(styles)(Login);