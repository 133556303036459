import React from 'react';

import {
  ThemeProvider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

import { 
  theme,
  useStyles
} from './styles';


export default function DoubleTextFieldIntegrationRules({
  rows, 
  title, 
  firstObject, 
  firstStateToChange,
  firstPlaceholder = "10:00", 
  secondObject, 
  secondStateToChange,
  secondPlaceholder = "22:00" 
}) {
  const classes = useStyles();

  const handleChangeField = (event, func) => {
    func(event.target.value)
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid item container justify='flex-start' direction='column' className={classes.container}>
          <Grid item>
            <Typography className={classes.title}> {title} </Typography>
          </Grid>
          <Grid container justify='space-between' direction='row'>
            <Grid item className={classes.itemTextField}>
              <TextField
                id="openStore"
                size='small'
                multiline
                rows={rows}
                value={firstObject}
                onChange={(e) => handleChangeField(e, firstStateToChange)}
                variant='outlined'
                placeholder={firstPlaceholder}
              />
            </Grid>
            <Grid item className={classes.itemTextField}>
              <TextField
                id="closeStore"
                size='small'
                multiline
                rows={rows}
                value={secondObject}
                onChange={(e) => handleChangeField(e, secondStateToChange)}
                variant='outlined'
                placeholder={secondPlaceholder}
              />
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}