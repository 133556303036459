import React from 'react';

import {  
    Grid,
    Typography,
    Paper,
    Chip
} from '@material-ui/core';

import { 
    useStyles 
} from './styles';
import { useCallback } from 'react';

export default function VisualizeItemsAdded({ title, listItems, setListItems }) {
    const classes = useStyles();

    const handleDelete = useCallback((array, itemToDelete) => {
        const newArray = array.filter(item => item !== itemToDelete);
        setListItems(newArray);
    }, [setListItems]);

    const maxItemsToShow = 5;
    const itemsToShow = listItems.slice(0, maxItemsToShow);
    const remainingItemCount = listItems.length - maxItemsToShow;

    return (
        <>
            <Grid item container justify='flex-start' direction='column' className={classes.container}>
                <Grid item>
                    <Typography className={classes.title}> 
                        {title} 
                    </Typography>
                </Grid>
                <Grid item>
                    <Paper variant="outlined" className={classes.paper}>
                        {itemsToShow && itemsToShow.map((item) => (
                            <Chip
                                className={classes.marginChip}
                                key={item}
                                label={item}
                                onDelete={() => handleDelete(listItems, item)}
                            />                 
                        ))}

                        {remainingItemCount > 0 && (
                            <Chip label={`+ ${remainingItemCount}`} className={classes.marginChip} />
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}