import {
  createMuiTheme,
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    background: '#373435', 
    width: '100%', 
    height: 'auto', 
    // padding: 15, 
    borderRadius: 8 
  },
  title: {
    fontWeight: 600,
    fontSize: 15,
    color: '#fff',
    padding: '0 0 10px 0',
  }
}));

export const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: '#fff',
        },
      },
      notchedOutline: {
        borderColor: '#fff'
      }
    },
    MuiSelect: {
      iconOutlined: {
        color: '#fff'
      },
      outlined: {
        color: '#fff'
      }
    },
    MuiInputLabel: {
      outlined: {
        color: '#fff'
      },
      marginDense: {
        color: '#fff'
      }
    },
    MuiAutocomplete: {
      popupIndicator: {
        color: '#fff'
      },
      clearIndicator: {
        color: '#fff'
      },
      inputRoot: {
        cursor: 'pointer',
        color: '#fff',
        fontWeight: 500
      },
      input: {
        cursor: 'pointer',
      }
    },
  },
});

export const themeMobile = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#fff',
        },
      },
      notchedOutline: {
        borderColor: '#fff'
      }
    },
    MuiSelect: {
      iconOutlined: {
        color: '#fff'
      },
      outlined: {
        color: '#fff'
      }
    },
    MuiInputLabel: {
      outlined: {
        color: '#fff'
      },
      marginDense: {
        color: '#fff'
      }
    },
    MuiTextField: {
      root: {
        width: '100%'
      }
    },
    MuiAutocomplete: {
      popupIndicator: {
        color: '#fff'
      },
      clearIndicator: {
        color: '#fff'
      }
    }
  },
});