import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {  
    Grid,
    TextField,
    ThemeProvider
} from '@material-ui/core';

import { 
    useStyles, 
    theme 
} from './styles';

export default function AutoCompleteChooseBrand({ itemsToChoose, item, setItem }) {
    const classes = useStyles();

    return (
        <>
            <Grid item container className={classes.container} xs={4}>
                <Grid item>
                    <ThemeProvider theme={theme}>
                        <Autocomplete
                            limitTags={1}
                            disableClearable
                            size="small"
                            id="autoCompleteChooseBrand"
                            options={itemsToChoose}
                            getOptionLabel={(option) => option}
                            value={item}
                            onChange={(event, newValue) => {
                                setItem(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" autoComplete='off'/>
                            )}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        </>
    );
}