export const SomaGroupBrands = [
  { brandName: 'Animale', brandId: 1 },
  { brandName: 'Farm', brandId: 2 },
  { brandName: 'Fábula', brandId: 5 },
  { brandName: 'Outlet', brandId: 6 },
  { brandName: 'Foxton', brandId: 7 },
  { brandName: "Cris Barros", brandId: 9 },
  { brandName: 'Maria Filó', brandId: 15 },
  { brandName: 'NV', brandId: 16 },
  { brandName: 'Hering', brandId: 17 },
  { brandName: 'Dzarm', brandId: 20 },
];