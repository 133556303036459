import api from '../api';
import { AUTHORIZATIONTOKENFUNCTION } from '../../consts/services';

export const getStoreIndicators = async (startDate, endDate, brands) => {
  const response = await api.post(`/businessUnit/store?startDate=${startDate}&endDate=${endDate}` , {
    brands: brands
  }, {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}

export const getCDIndicators = async (startDate, endDate, brands) => {
  const response = await api.post(`/businessUnit/cd?startDate=${startDate}&endDate=${endDate}` , {
    brands: brands
  }, {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}

export const getShareIndicators = async (startDate, endDate, brands) => {
  const response = await api.post(`/businessUnit/share?startDate=${startDate}&endDate=${endDate}` , {
    brands: brands
  }, {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}

export const getCollectionsIndicators = async (startDate, endDate, brands) => {
  const response = await api.post(`/businessUnit/collections?startDate=${startDate}&endDate=${endDate}` , {
    brands: brands
  }, {
    headers: { 'authorization': await AUTHORIZATIONTOKENFUNCTION() }
  });

  return response.data;
}