export const PageBackground = {
    position: 'absolute',
    background: '#212121',
    minHeight: `calc(100vh - 90px)`,
    width: '100%',
    // marginTop: '63px',
};

export const pageGrid = {
    width: '95%',
    margin: '0 auto',
    minHeight: `calc(100vh - 103px)`,
    height: 'auto',
    marginTop: '15px',
};