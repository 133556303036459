import React from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';

import { 
  useStyles 
} from './styles';

import {  
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';



export default function AccordionLogisticSetup({ 
  title,
  atributtes,
  loading
}) {
  const classes = useStyles();

  return (
    <>
        {loading ?
            <>
                <Skeleton variant="rect" className={classes.skeleton}/>
                <Skeleton variant="rect" className={classes.skeleton}/>
            </>
        :
            <>
                <Accordion className={classes.container}>
                    <AccordionSummary
                        className={classes.summaryAndDetails}
                        expandIcon={<ExpandMoreIcon style={{ color: '#fff' }}/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid container justify='space-between' alignItems='center'>
                            <Grid item>
                                <Typography className={classes.title}>
                                    {title}
                                </Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.summaryAndDetails}>
                        <Grid container>
                            {atributtes.map(item => (
                                <Grid container spacing={1} alignItems="center" className={classes.atributtesContent}>
                                    <Grid item className={classes.atributtesItemContent}>
                                        {item.icon ? item.icon : null}
                                    </Grid>

                                    <Grid item className={classes.atributtesItemContent}>
                                        <Typography>{item.title ? item.title : null}</Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </>
      }
    </>
  );
}



