import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, Grid, Divider } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import SwitchIOSv4 from '../SwitchIOS';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: 10,
    margin: 5,
    background: '#373435',
    borderRadius: 8 
  },
  dividerSecondary: {
    background: '#645e60',
    height: '2px',
    width: '100%',
    margin: '0 auto'
  },
  
});

// --------- dataArray model ---------
// [
//     lineTitle: sessionStorage,
//     lineState: true,
//     lineSetState: function
// ]

export default function Card1v4({ dataArray, principalTitle, secondaryTitle }) {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
        <CardContent style={{ padding: 10, paddingBottom: 10 }}>
            {/* Card Container */}
            <Grid container spacing={3} justify="space-between" alignItems='center' direction='row'>
                {/* Title container */}
                <Grid item container justify="space-between" alignItems='center' direction='row'>
                    <Grid item xs={8}>
                        <Typography variant='body1' style={{ fontWeight: 600, paddingLeft: 30, color: '#fff'}} gutterBottom> 
                            {principalTitle} 
                        </Typography>            
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='body1' style={{ fontWeight: 600, textAlign: 'center', color: '#fff'}} gutterBottom> 
                            {secondaryTitle}
                        </Typography> 
                    </Grid>
                </Grid>

                <Divider className={classes.dividerSecondary} />

                {/* Lines */}
                {dataArray.map(item => (
                    <>
                        <Grid item container justify="space-between" alignItems='center' direction='row'>
                            <Grid item xs={8}>
                                <Typography variant='body1' style={{ fontWeight: 600, paddingLeft: 30, color: '#fff'}} gutterBottom>
                                    {item.lineTitle}
                                </Typography>
                            </Grid>
                            <Grid item container xs={4} justify="center" alignItems='center' direction='row'>
                                <SwitchIOSv4 
                                    state={item.lineState}
                                    setState={item.lineSetState}
                                />
                            </Grid>
                        </Grid>

                        <Divider className={classes.dividerSecondary} />
                    </>
                ))}
            </Grid>
        </CardContent>
    </Card>
  );
}
