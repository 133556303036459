import {
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  sectionNameTitle: {
    color: '#fff',
  },
  observation: {
    fontStyle: "italic",
    color: '#fff',
  },
}));