import api from "../newMoreOmni";
import { SELLERMANAGEMENTTOKEN, ENCRIPTPASS } from "../../consts/services";
import CryptoJS from "crypto-js";

function decryptToken() {
  const bytes = CryptoJS.AES.decrypt(SELLERMANAGEMENTTOKEN, ENCRIPTPASS);
  return bytes.toString(CryptoJS.enc.Utf8);
}

export const getIntegrationRulesByBrand = async (brandId) => {
  const response = await api.get(`/integrations/brands/${brandId}`, {
    headers: { authorization: decryptToken() },
  });

  return response.data;
};

export const putIntegrationRulesBySellerId = async (
  sellerId,
  configurations,
  user
) => {
  const response = await api.put(
    `/integrations/sellers/${sellerId}`,
    {
      configuration: configurations,
      user: user,
    },
    {
      headers: { authorization: decryptToken() },
    }
  );

  return response.data;
};
