import {
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  Skeleton: {
    background: '#373435', 
    width: '18%'
  },
  ContainerBg: {
    width: '18%', 
    height: 120, 
    background: '#373435', 
    borderRadius: 8
  },
  Title: {
    fontWeight: 600,
    fontSize: 20,
    color: '#fff',
    paddingBottom: 20
  },
  Value: {
    fontWeight: 600,
    fontSize: 20,
    color: '#fff'
  }
}));