import React, { useEffect, useState } from 'react';

import { 
  Grid, 
  ThemeProvider,
  Accordion,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Typography
} from '@material-ui/core';

import { 
  FaPlay, 
  FaPause, 
  FaStop 
} from 'react-icons/fa';

import { 
  useAuth 
} from '../../context/authContext';

import { 
  useStyles, 
  materialTheme 
} from './styles';

export default function ActionsInAccordionSummary({ 
  item, 
  setDataCheck, 
  dataCheck, 
  checkFlag,
  currentPage 
}) {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const { user } = useAuth();

  const userCanViewThis = (user) => {
    if(user.permissions.isAdministrator || user.permissions.isManagement){
      return true;
    }

    return false;
  };

  const getIcon = (item) => {
    if(item.ligado === false){
      return <FaStop size={16} style={{ marginRight: '10px', color: '#d62b2b' }}/>
    }

    if(item.abaixo_do_teto === false){
      return <FaPause size={16} style={{ marginRight: '10px', color: '#d62b2b' }}/>
    }

    if(item.abrangente === true){
      return <FaPlay size={16} style={{ marginRight: '10px', color: '#ffd700' }}/>
    }

    return <FaPlay size={16} style={{ marginRight: '10px', color: '#42a31c' }}/>
  }

  useEffect(() => {
    if(dataCheck.length === 0){
      setChecked(false);
    }
  }, [dataCheck]);

  useEffect(() => {
    if(checkFlag === true){
      setChecked(true);
    }else{
      setChecked(false);
    }
  }, [checkFlag]);

  useEffect(() => {
    const sellerIsChecked = dataCheck.find(element => element.loja === item.loja);

    if(sellerIsChecked){
      setChecked(true);
    }else{
      setChecked(false);
    }

  }, [currentPage, dataCheck, item.loja]);

  const handleCheckBox = (event) => {
    if(event.target.checked === true){
      const arr = [...dataCheck, item]
      setDataCheck(arr)
    }else if(event.target.checked === false){
      const arr = dataCheck.filter(element => {return item.loja !== element.loja});
      setDataCheck(arr)
    }
    event.stopPropagation();
  };

  return (
    <div className={classes.root}>
      <ThemeProvider theme={materialTheme}>
        <Accordion 
          expanded={false} 
          defaultExpanded={true}
          className={checked ? classes.AccordionSelected : classes.Accordion}
        >
          <AccordionSummary>
            {userCanViewThis(user) ?
              <FormControlLabel
                checked={checked}
                aria-label="Acknowledge"
                onClick={(event) => handleCheckBox(event)}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox color={"#fff"}/>}
                style={{ width: '5%' }}
              /> 
            : 
              <Grid style={{ width: '5%' }}></Grid>
            }

            <Grid 
              container 
              justify="space-between" 
              alignItems="center" 
              style={{ width: '100%' }}
            >
              <Grid 
                style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  width: '20%' 
                }}
              >
                {getIcon(item)}

                <Grid 
                  container 
                  item 
                  style={{ 
                    flexDirection: 'column', 
                    maxWidth: '150px' 
                  }}
                >
                  <Grid item style={{ maxWidth: '150px' }}>
                    <Typography 
                      style={{ 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis', 
                        color: '#fff' 
                      }}
                    >
                      {item.loja}</Typography>
                  </Grid>
                  <Grid item style={{ maxWidth: '150px' }}>
                    <Typography 
                      style={{ 
                        color: '#808080', 
                        fontSize: 12, 
                        fontWeight: 500, 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis', 
                        whiteSpace: 'nowrap' 
                      }}
                    >
                      {item.filial}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classes.boxItem}>
                <Typography className={classes.Boxwhite}>
                  {`${item.media_romaneio}`}
                </Typography>
              </Grid>
              
              <Grid className={classes.boxItem}>
                <Typography className={classes.Boxwhite}>
                  {`${item.tma_horas} h`}
                </Typography>
              </Grid>

              <Grid className={classes.boxItem}>
                <Typography className={classes.Boxwhite}>
                  {item.pedidos_totais}
                </Typography>
              </Grid>
              
              <Grid className={classes.boxItem}>
                <Typography className={classes.Boxwhite}>
                  {item.taxa_cancelamento}
                </Typography>
              </Grid>

              <Grid className={classes.boxItem}>
                <Typography className={classes.Boxwhite}>
                  {item.pedidos_pendentes_2dias}
                </Typography>
              </Grid>
              
              <Grid className={classes.boxItem}>
                <Typography className={classes.Boxwhite}>
                  {`${item.openOrders} | ${item.capacidade}`}
                </Typography>
              </Grid>
              
              <Grid className={classes.boxItem}>
                <Typography className={classes.Boxwhite}>
                  {`${item.deflator}`}
                </Typography>
              </Grid>   
            </Grid>
          </AccordionSummary>
        </Accordion>
      </ThemeProvider>
    </div>
  );
}