import React, { useEffect, useState } from 'react';

/**
 * Libraries Imports
*/

import { Link } from 'react-router-dom';
import { DropzoneArea } from 'material-ui-dropzone';
import { FaFileCsv } from 'react-icons/fa';

/**
 * Material UI Imports
*/

import { 
    Description, 
    PictureAsPdf, 
    Theaters 
} from '@material-ui/icons';

import {  
    Grid,
    Typography,
    Button,
    Divider,
    ThemeProvider
} from '@material-ui/core';

/**
 *  Components Imports
*/

import AutoCompleteChooseBrand from '../../components/AutoCompleteChooseBrand';
import AutoCompleteChooseMultipleItems from '../../components/AutoCompleteChooseMultipleItems';
import Footer from '../../components/Footer';

/**
 *  Styles Imports
*/

import { 
    useStyles,
    theme 
} from './styles';

import { 
    PageBackground, 
    pageGrid 
} from '../../stylesCSS';

/**
 *  Consts Imports
*/

import { 
    SomaGroupBrands 
} from '../../consts/brands';

/**
 *  Services Imports
*/

import { 
    uploadFreightTable, 
    getAllShippingPolicies 
} from '../../services/logisticSetup/shippingPolicies';

import { 
    getAllSellersByBrandId 
} from '../../services/brand';

/**
 *  Principal Function Component
*/

export default function StoreAutomatizationUpload(props) {
    const classes = useStyles(props);

    const SOMABRANDS = SomaGroupBrands.map(item => {
        return {
            key: item.brandName,
            value: item.brandId
        };
    });

    const [csvFile, setCsvFile] = useState({});
    const [items, setItems] = useState([]);
    const [shippingPolicies, setShippingPolicies] = useState([]);
    const [selectedShippingPolicie, setSelectedShippingPolicie] = useState("");

    const [sellersByBrand, setSellersByBrand] = useState([]);

    const [brand, setBrand] = React.useState(() => {
        const brandName = localStorage.getItem(`@maisomni:favoriteBrand`) ? localStorage.getItem(`@maisomni:favoriteBrand`) : "";
        const item = SOMABRANDS.find(item => item.key === brandName);
        return item !== undefined ? item.value : "";
    });

    useEffect(() => {
        getAllSellersByBrandId(brand)
        .then(response => {
            const modifiedArray = response.map(({ sellerId }) => {
                return {
                    key: sellerId,
                    value: sellerId
                };
            });

            setSellersByBrand(modifiedArray);
        }).catch(error => {
            console.log(error);
        })
    }, [brand]);

    useEffect(() => {
        getAllShippingPolicies(brand)
        .then(response => {
            const modifiedArray = response.map(({ idVtex }) => {
                return {
                    key: idVtex,
                    value: idVtex
                };
            });

            setShippingPolicies(modifiedArray);
        }).catch(error => {
            console.log(error);
        })
    }, [brand]);

    const handlePreviewIcon = (fileObject) => {
        const {type} = fileObject.file
        const iconProps = {
          className : classes.image,
        }
      
        if (type.startsWith("video/")) return <Theaters {...iconProps} />
      
        switch (type) {
          case "application/msword":
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            return <Description {...iconProps} />
          case "application/pdf":
            return <PictureAsPdf {...iconProps} />
          default:
            return <FaFileCsv {...iconProps} />
        }
    }

    const submitFreightCsv = async (file, selectedShippingPolicie, selectedSellers) => {
        await Promise.all(
            selectedSellers.map(async item => {
                await uploadFreightTable(file, item, selectedShippingPolicie);
            })
        )

    }

    return(
        <>
            <div style={PageBackground}>
                <div style={pageGrid}>

                    <Grid container justify='space-between' alignItems='center' className={classes.headerContainer}>
                        <Grid item container className={classes.autoWidth}>
                            <Typography className={classes.title}>
                                Upload de tabela
                            </Typography>
                        </Grid>

                        <Grid item container spacing={2} className={classes.autoWidth}>
                            <Grid item>
                                <Link to={{ pathname: '/automatization/setup' }} className={classes.retireTextDecoration}>
                                    <Button variant="contained" size="medium" className={classes.button}>
                                        Configurações
                                    </Button>
                                </Link>
                            </Grid>

                            <Grid item>
                                <Link to={{ pathname: '/automatization' }} className={classes.retireTextDecoration}>
                                    <Button variant="contained" size="medium" className={classes.button}>
                                        Principal
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider className={classes.dividerPrincipal} />

                    <Grid container direction='row' alignItems='center' justify='space-between' className={classes.headerContainer}>
                        <Grid item className={classes.contentTextField}>
                            <AutoCompleteChooseBrand 
                                title={"Marca"}
                                itemsToChoose={SOMABRANDS}
                                brand={brand}
                                setBrand={setBrand}
                                storageName={"@maisomni:favoriteBrand"}
                            />
                        </Grid>
                    </Grid>

                    <Grid container direction='row' alignItems='center' justify='space-between' className={classes.headerContainer}>
                        <Grid item className={classes.contentTextField}>
                            <AutoCompleteChooseBrand 
                                title={"Transportadoras"}
                                itemsToChoose={shippingPolicies}
                                brand={selectedShippingPolicie}
                                setBrand={setSelectedShippingPolicie}
                                storageFlag={false}
                            />
                        </Grid>
                        <Grid item className={classes.contentTextField}>
                            <AutoCompleteChooseMultipleItems 
                                title={"Lojas"}
                                itemsToChoose={sellersByBrand}
                                setItems={setItems}
                            />
                        </Grid>
                    </Grid>

                    <Grid container direction='row' alignItems='center' justify='space-between' className={classes.headerContainer} style={{ background: '#373435', borderRadius: 8, padding: '20px' }}>
                        <ThemeProvider theme={theme}>
                            <DropzoneArea
                                onChange={(files) => setCsvFile(files[0])}
                                dropzoneClass={classes.dropZone}
                                showPreviewsInDropzone={true}
                                filesLimit={1}
                                dropzoneText={"arraste ou clique para adicionar a tabela csv"}
                                showFileNames
                                maxFileSize={10485760}
                                getPreviewIcon={handlePreviewIcon}
                                getFileAddedMessage={() => `O arquivo foi adicionado com sucesso!`}
                                getDropRejectMessage={() => `O arquivo não pode ser adicionado! (Limite 10mb)`}
                                getFileRemovedMessage={() => `O arquivo foi removido com sucesso!`}
                            />
                        </ThemeProvider>
                        
                    </Grid>

                    <Grid container direction='row' alignItems='center' justify='flex-end' className={classes.headerContainer}>
                        <Button onClick={() => submitFreightCsv(csvFile, selectedShippingPolicie, items)} variant="contained" size="medium" className={classes.createStoreButton}>
                            Subir CSV
                        </Button>
                    </Grid>
                </div>
                <Footer />
            </div>
        </>
    );
}