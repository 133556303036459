import { 
  createMuiTheme
} from '@material-ui/core';

export const styles = {
  pageBg: {
    position: 'absolute',
    background: '#201E29',
    height: `100%`,
    width: '100%',
  },
  CardMobile: {
    width: '90%',
    height: '500px',
    background: '#201e29',
    border: 'none',
    marginTop: 160
  },
  TextField: {
    width: '100%',
    margin: '10px auto',
    color: '#fff',
  },
  btnMobile: {
    width: '100%',
    height:'40px',
    margin: '0 auto',
    marginBottom: '10px',
    border: '1px solid #fff',
    color: '#fff'
  },
  title: {
    display: 'flex',
  }
}

export const materialTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      input: {
        color: '#fff',
      },
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#fff',
        },
      },
      notchedOutline: {
        borderColor: '#fff'
      } 
    },
    MuiInputLabel: {
      outlined:{
        color: '#fff',
      }
    },
    MuiCardContent:{
      root: {
        padding: '7x',
      }
    },
    MuiCardActions: {
      root: {
        padding: '8px 0px'
      }
    },
  },
});