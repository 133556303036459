import {
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    background: '#373435', 
    width: '100%', 
    height: 'auto', 
    padding: 15, 
    borderRadius: 8 
  },
  title: {
    fontWeight: 600,
    fontSize: 15,
    color: '#fff',
    padding: '0 0 10px 0',
  },
  paper: {
    width: '100%', 
    height: 45, 
    background: 'transparent', 
    border: '1px solid #fff', 
    padding: '5px'
  },
  marginChip: {
    margin: '0 2px'
  }
}));

