import {
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  Skeleton: {
    background: '#373435', 
    width: '48%',
    margin: 3
  },
  ContainerBg: {
    width: '48%', 
    height: 120, 
    background: '#373435', 
    borderRadius: 8,
    margin: 3
  },
  Title: {
    fontWeight: 600,
    fontSize: 16,
    color: '#fff',
    paddingBottom: 20
  },
  Value: {
    fontWeight: 600,
    fontSize: 16,
    color: '#fff'
  }
}));