export const INDICATORS = [
  {
    value: 'Cancelamento',
    label: 'Cancelamento',
  },
  {
    value: 'TMA',
    label: 'TMA',
  },
  {
    value: 'Pedidos',
    label: 'Pedidos',
  },
];

export const UNITS = [
  {
    value: 'TOTAL',
    label: 'Total',
  },
  {
    value: 'LOJAS',
    label: 'Lojas',
  },
  {
    value: 'CD_RJ',
    label: 'CD RJ',
  },
  {
    value: 'CD_SP',
    label: 'CD SP',
  },
];