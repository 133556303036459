import {
  makeStyles
} from '@material-ui/core';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
    minHeight: 62
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    maxWidth: 320,
    minWidth: 250,
    background: '#DCC6B7 !important',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    minHeight: 62,
    justifyContent: 'space-between'
  },
  content: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: '100%',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  Fab: {
    border: `1px solid #000`,
    boxShadow: 'none',
    marginRight: '10px',
  },
  ListText: {
    color: '#1e1c1c'
  },
  IconButtonMenu: {
    color: '#000',
    '&:hover': { 
      background: `#D3BEB0`
    },
  },
  Divider: {
    background: '#000'
  },
  ListItem: {
    '&:hover': { 
      background: '#D3BEB0'
    },
  },
  boxName: {
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center'
  }
}));