import { createMuiTheme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  BoxDialog: {
    background: '#212121',
    color: '#fff',
    width: '100%',
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    color: '#fff'
  },
  subTitle: {
    fontWeight: 600,
    fontSize: 12,
    color: '#fff'
  },
  containerSwitch: {
    background: '#373435', 
    borderRadius: 8, 
    width: '100%', 
    margin: '10px 0px'
  },
  BoxDialogMobile: {
    background: '#212121',
    color: '#fff',
    padding: '8px 10px'
  },
  Button: {
    width: '120px', 
    height: '40px', 
    textTransform: 'uppercase',
    borderRadius: 5, 
    '&:hover': { 
      border: '1px solid #fff',
    }
  },
  Title: {
    textAlign: 'center', 
    fontSize: 18, 
    flex: 1
  },
  GridTitle: {
    display: 'flex', 
    alignItems: 'center'
  }
}));

export const materialTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: '#fff',
        },
      },
      notchedOutline: {
        borderColor: '#fff'
      },
      input: {
        color: '#fff'           
      }  
    },
    MuiInputLabel: {
      outlined: {
        color: '#fff',
      }
    },
    MuiFormHelperText: {
      contained: {
        color: '#fff',
      }
    },
    MuiButton: {
      label: {
        color: '#fff'           
      }
    },
  },
});