import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { 
    PageBackground, 
    pageGrid 
} from '../../stylesCSS';

import { 
    styles, 
    materialTheme 
} from './styles';

import { 
    GetStoresWithoutUserAssociation, 
    AssociateBrandsUser, 
    AssociateSellersUser 
} from '../../services/usersBrands';

import { 
    createUser 
} from '../../services/users';

import { 
    BRANDSTOCHOICE, 
    CURRENCIES 
} from '../../consts/createUsers';

import { 
    Grid, 
    TextField, 
    MenuItem, 
    Button, 
    Typography,
    withStyles,
    ThemeProvider
} from '@material-ui/core';

import AutoCompleteChips from '../../components/AutoCompleteChip';
import AutoComplete from '../../components/AutoCompleteBrands';
import Footer from '../../components/Footer';
import LoadingModal from '../../components/LoadingModal';
import { useAuth } from '../../context/authContext';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateUsers(props) {
    const { classes } = props;
    const { user } = useAuth();

    const [currency, setCurrency] = useState(1);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [stores, setStores] = useState([]);
    const [selectedStores, setSelectedStores] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState(null);
    const [loadingModalOpen, setLoadingModalOpen] = useState(false);

    useEffect(() => {
        (async () => {
            const sellersWithoutAssociation = await GetStoresWithoutUserAssociation();
            setStores(sellersWithoutAssociation);
        })();
        
    }, [user.token]);

    const handleChange = event => {
        setCurrency(event.target.value);
    };

    const submit = async () => {

        if(name === "" || email === "" || password === "" || selectedBrands.length === 0){
          toast.error("Por favor preencher os campos!");
          return;
        }

        let userData = [];
        setLoadingModalOpen(true);
  
        try{
            userData = await createUser(name, email, password, currency, selectedBrands[0]);
            const idUser = userData.id_user;
            await AssociateBrandsUser(idUser, selectedBrands);

            if(currency === 4){
                userData = await AssociateSellersUser(idUser, selectedStores);
            }

            toast.success("Usuário cadastrado!");
        }catch(err){
            toast.error(err.response.data.error);
        }
  
        setLoadingModalOpen(false);
    };

    return (
        <>
            <div style={PageBackground}>
                <div style={pageGrid}>
                    <ThemeProvider theme={materialTheme}>
                        <Grid container xs={12} className={classes.boxPrincipal}>
                            <Typography className={classes.title} variant="h6" gutterBottom>
                                CRIAR USUÁRIOS
                            </Typography>

                            <TextField
                                id="name"
                                label="Nome"
                                style={{ margin: 8 }}
                                placeholder="Digite o nome"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                onChange={(e) => setName(e.target.value)}
                            />

                            <TextField
                                id="email"
                                label="E-mail"
                                style={{ margin: 8 }}
                                placeholder="Digite o e-mail"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                onChange={(e) => setEmail(e.target.value)}
                            />

                            <TextField
                                id="password"
                                label="Senha"
                                style={{ margin: 8 }}
                                placeholder="Digite a senha"
                                type="password"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                onChange={(e) => setPassword(e.target.value)}
                            />

                            <TextField
                                id="perfil"
                                select
                                label="Tipo de usuário"
                                style={{ margin: 8 }}
                                value={currency}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            >
                                {CURRENCIES.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            
                            <Grid container style={{ width: '100%', margin: '8px' }}> 
                                <AutoComplete 
                                    UsersBrands={BRANDSTOCHOICE} 
                                    setSelectedBrands={setSelectedBrands} 
                                />
                            </Grid>

                            {currency === 4?
                                <Grid container style={{ width: '100%' }}>
                                    <AutoCompleteChips 
                                        UsersBrands={stores} 
                                        setSelectedBrands={setSelectedStores} 
                                    />
                                </Grid>
                            : null}

                            <Grid container>
                                <Button
                                    variant="outlined" 
                                    onClick={(e) => { submit() }}
                                    disableElevation
                                    className={classes.btn}
                                >
                                    <Typography style={{ color: '#fff' }}>
                                        Criar Usuário
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>

                        <ToastContainer
                            position="top-right"
                            autoClose={2500}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick={false}
                            rtl={false}
                            pauseOnVisibilityChange
                            draggable
                            pauseOnHover={false}
                        />

                        <LoadingModal
                            open={loadingModalOpen}
                            title={"Criando usuário"}
                        />
                    </ThemeProvider>
                </div>
                <Footer />
            </div>
        </>
    );
}

CreateUsers.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
export default withStyles(styles)(CreateUsers);