import React, { useEffect, useState } from 'react';

import {
    Grid,
    Typography
} from '@material-ui/core';

import { 
    useAuth 
} from '../../context/authContext';

import {
    MODE,
    STATUS
} from '../../consts/logistic';

import Footer from '../../components/Footer';
import AutoCompleteFilterBrands from '../../components/AutoCompleteFilterBrands';
import AutoComplete from '../../components/AutoComplete';
import AccordionDock from '../../components/AccordionDock';

import { 
    getDocksCities,
    getDocksStates,
    getDocksByBrand,
    getCDDocksByBrand,
    getOrchestratorDocks,
    getAllCDDocks
} from '../../services/docks';

import { 
    PageBackground, 
    pageGrid 
} from '../../stylesCSS';

import { 
    getBrandsByUser 
} from '../../services/usersBrands';

export default function Logistic(props) {
    const { user } = useAuth();
    const [UsersBrands, setUsersBrands] = useState([]);
    const [dockData, setDockData] = useState([]);
    const [orchestratorDocks, setOrchestratorDocks] = useState([]);
    const [dockCDData, setDockCDData] = useState([]);
    const [selectedDocks, setSelectedDocks] = useState([]);
    const [selectedCDDocks, setSelectedCDDocks] = useState([]);
    const [docks, setDocks] = useState([]);
    const [CDdocks, setCDDocks] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([user.rede]);
    const [loading, setLoading] = useState(false);

    const [cities, setCities] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);

    const [states, setStates] = useState([]);
    const [selectedStates, setSelectedStates] = useState([]);

    const getDocksByBrandFunction = async (brands, city, state) => {
        let response = await getDocksByBrand(brands, city, state);
        setDockData(response);
    };

    const getOrchestratorDocksbyBrand = async (brands) => {
        let response = await getOrchestratorDocks(brands);
        setOrchestratorDocks(response);
    };

    const getDocksCDByBrandFunction = async (brands, city, state) => {
        let response = await getCDDocksByBrand(brands, city, state);
        setDockCDData(response);
    };

    const getDocksCitiesFunction = async () => {
        const response = await getDocksCities();
        setCities(response);
    };

    const getDocksStatesFunction = async () => {
        const response = await getDocksStates();
        setStates(response);
    };

    const getAllCDDocksFunction = async (brandId) => {
        const response = await getAllCDDocks(brandId);
        return response;
    };

    const getUsersBrands = async (id) => {
        const response = await getBrandsByUser(id);

        let newUserBrands = response.filter(item => item.rede_lojas !== 0)

        setUsersBrands(newUserBrands);
    };

    useEffect(() => {
        getUsersBrands(user.id);
        getDocksCitiesFunction();
        getDocksStatesFunction();
    }, [user]);

    useEffect(() => {
        getOrchestratorDocksbyBrand(selectedBrands)
    }, [selectedBrands]);


    useEffect(() => {
        setDocks(orchestratorDocks.length > 0 ? orchestratorDocks.map(item => {
            return ({
                value: item.id,
                label: item.name
            })
        }) : [])
    }, [orchestratorDocks]);


    useEffect(() => {
        setSelectedDocks([])
        setSelectedCDDocks([])
    }, [selectedBrands]);

    useEffect(() => {
        if(selectedBrands.length !== 0){
            getAllCDDocksFunction(selectedBrands[0])
            .then(docks => {
                setCDDocks(docks.map(item => {
                    return ({
                        value: item.id,
                        label: item.name
                    })
                }))
            })
        }
    }, [selectedBrands]);

    useEffect(() => {
        if(selectedBrands.length !== 0){
            (async () => {
                setLoading(true);
                await Promise.all([
                    getDocksByBrandFunction(
                        selectedBrands, 
                        selectedCities, 
                        selectedStates
                    ),
                    getDocksCDByBrandFunction(
                        selectedBrands, 
                        selectedCities, 
                        selectedStates
                    )
                ]);
                setLoading(false);
            })();
        }
    }, [selectedBrands, selectedCities, selectedStates]);

    return(
        <div style={PageBackground}>
            <div style={pageGrid}>  
                <Grid 
                    container 
                    alignItems="center" 
                    justify="space-between" 
                    style={{ paddingTop: '20px' }}
                >
                    <Grid item>
                        <AutoCompleteFilterBrands 
                            UsersBrands={UsersBrands}
                            defaultValue={UsersBrands ? UsersBrands : []} 
                            setSelectedBrands={setSelectedBrands}
                            width={300}
                        />
                    </Grid>
                    <Grid item style={{ width: 400 }}>
                        <AutoComplete
                            data={cities}
                            setSelected={setSelectedCities}
                            title={"Cidade"}
                        />
                    </Grid>
                    <Grid item style={{ width: 300 }}>
                        <AutoComplete
                            data={states}
                            setSelected={setSelectedStates}
                            title={"Estado"}
                        />
                    </Grid>
                </Grid>

                <Grid 
                    container 
                    alignItems="center" 
                    justify="space-evenly" 
                    style={{ paddingTop: '20px' }}
                >
                    <Grid item style={{ width: 300 }}>
                        <AutoComplete
                            data={docks.length > 0 ? docks.map(item => item.value) : []}
                            setSelected={setSelectedDocks}
                            title={"Docas de loja a serem visualizadas"}
                        />
                    </Grid>

                    <Grid item style={{ width: 300 }}>
                        <AutoComplete
                            data={CDdocks.length > 0 ? CDdocks.map(item => item.value) : []}
                            setSelected={setSelectedCDDocks}
                            title={"Docas de CD a serem visualizadas"}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Typography 
                        style={{ 
                            fontWeight: 600,
                            fontSize: 30,
                            color: '#fff',
                            padding: '30px 0'
                        }}
                    >
                        Logística
                    </Typography>
                </Grid>

                <Grid container style={{ marginBottom: 60 }}>
                    <AccordionDock
                        title={"Lojas"} 
                        loading={loading}
                        storeData={dockData}
                        DockId1={selectedDocks[0]}
                        DockId2={selectedDocks[1]}
                        DockId3={selectedDocks[2]}
                        DockId4={selectedDocks[3]}
                        DockNameId1={selectedDocks[0]}
                        DockNameId2={selectedDocks[1]}
                        DockNameId3={selectedDocks[2]}
                        DockNameId4={selectedDocks[3]}
                        MODE={MODE}
                        STATUS={STATUS}
                        DOCKS={docks}
                    />
                </Grid>

                <Grid container>
                    <AccordionDock
                        title={"Centros de distribuição"} 
                        loading={loading}
                        storeData={dockCDData}
                        DockId1={selectedCDDocks[0]}
                        DockId2={selectedCDDocks[1]}
                        DockId3={selectedCDDocks[2]}
                        DockId4={selectedCDDocks[3]}
                        DockNameId1={selectedCDDocks[0]}
                        DockNameId2={selectedCDDocks[1]}
                        DockNameId3={selectedCDDocks[2]}
                        DockNameId4={selectedCDDocks[3]}
                        MODE={MODE}
                        STATUS={STATUS}
                        DOCKS={CDdocks}
                    />
                </Grid>
            </div>
            <Footer />
        </div>
    );
}