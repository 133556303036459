import api from '../api';
import { AUTHORIZATIONTOKEN } from '../../consts/services';

export const getCommissionByBrandId = async (brandId) => {
  const response = await api.get(`/orchestrator/realocation/commission/${brandId}`, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const getDistributionByBrandId = async (brandId, state) => {
  const response = await api.get(`/orchestrator/realocation/distribution/${brandId}?state=${state}`, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const updateOrchestratorCommissionByBrandId = async (brandId, value) => {
  const response = await api.put(`/orchestrator/realocation/commission/${brandId}`, {
    commission: value
  }, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const updateOrchestratorDistributionByBrandId = async (brandId, state, value) => {
  const response = await api.put(`/orchestrator/realocation/distribution/${brandId}`, {
    state: state,
    value: value
  }, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const getOrchestratorRealocationDataByBrandId = async (brandId) => {
  const response = await api.get(`/orchestrator/realocation/setup/${brandId}`, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const getOrchestratorGeneralDataByBrandId = async (brandId) => {
  const response = await api.get(`/orchestrator/general/setup/${brandId}`, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const getOrchestratorManagementDataByBrandId = async (brandId) => {
  const response = await api.get(`/orchestrator/management/setup/${brandId}`, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const updateOrchestratorRealocationDataByBrandId = async (brandId, data) => {
  const response = await api.put(`/orchestrator/realocation/setup/${brandId}`, data, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const updateOrchestratorGeneralDataByBrandId = async (brandId, data) => {
  const response = await api.put(`/orchestrator/general/setup/${brandId}`, data, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const updateOrchestratorDeflatorScenarioByBrandId = async (brandId, scenario) => {
  const response = await api.put(`/orchestrator/management/deflator/setup/${brandId}`, {
    scenario: scenario
  }, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const updateOrchestratorCapacityScenarioByBrandId = async (brandId, scenario) => {
  const response = await api.put(`/orchestrator/management/capacity/setup/${brandId}`, {
    scenario: scenario
  }, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}