import {
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  heading: {
    fontWeight: 600,
    fontSize: 24,
    color: '#fff',
  },
  boxItem: {
    color: '#fff', 
    width: '16%',
    margin: '0 auto',
    justifyContent: 'center'
  },
  Boxwhite: {
    minWidth: '60px', fontWeight: 800, color: '#fff',
    margin: '0 auto',
    textAlign: 'center',
    marginRight: 8,
    fontSize: 12,
  },
  setColorWhite: {
    color: '#fff',
    fontSize: 12,
  },
  Skeleton: {
    background: '#373435', 
    width: '100%', 
    height: '60px', 
    margin: '5px auto', 
    borderRadius: 8
  },
  HeaderContainer: {
    display: 'flex', 
    alignItems: 'center', 
    width: '100%', 
    margin: '5px auto', 
    padding: '0px 16px', 
    height: '49px'
  },
  CheckboxContainer: {
    width: '5%', 
    marginLeft: '-8px', 
    marginRight: '16px'
  },
  TooltipContainer: {
    display: 'flex', 
    alignItems: 'center', 
    height: '49px'
  }
}));