import React from 'react';

import { 
  Line 
} from 'react-chartjs-2';

import { 
  useStyles 
} from './styles';

export default function Chart({ 
  graphyTitle, 
  graphyData, 
  indicator
}) {
  const classes = useStyles();

  const data = {
    labels: graphyTitle,
    datasets: [
      {
        type:'line',
        fill: true,
        backgroundColor: 'rgba(65, 65, 145, 0.75)',
        borderColor: '#fff',
        pointBackgroundColor: '#414191',
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#414191',
        pointHoverBorderColor: '#fff',
        pointHoverBorderWidth: 2,
        data: graphyData,
        yAxisID: 'y-axis-1'
      },
    ]  
  };

  return(
    <div className={classes.BoxChart}>
      <Line
        data={data}
        width={'100%'}
        height={'auto'}
        options={{
          responsive: true, 
          maintainAspectRatio: false,
          legend: {
            display: false,
            position: 'bottom'
          },
          scales: {
            xAxes: [{
              barPercentage: 0.8,
              gridLines: {
                display: false
              },
              ticks: {
                fontColor: '#fff'
              },
            }],
              
            yAxes: [{
              type: 'linear',
              display: true,
              position: 'left',
              id: 'y-axis-1',
              gridLines: {
                display: true
              },
              labels: {
                show: true
              },
              ticks: {
                fontColor: '#fff',
                stepSize: indicator === 'Pedidos' ? 2000 : 1
              },
            }]
          }              
        }}
      />       
    </div>
  );
}