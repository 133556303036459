import { 
  makeStyles,
  createMuiTheme
} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  BoxDialog: {
    background: '#212121',
    color: '#fff',
  }
}));

export const materialThemeMobile = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#fff',
      },
      input: {
        color: '#fff'           }  
    },
    MuiInputLabel: {
      outlined: {
        color: '#fff',
      }
    },
    MuiFormHelperText: {
      contained: {
        color: '#fff',
      }
    },
    MuiButton: {
      label: {
        color: '#fff'           }
    },
    MuiDialog: {
      paperWidthSm: {
        width: '95%',
        maxWidth: '95%',
        margin: '0 auto'
      },
      paper: {
        margin: 0
      }
    }
  },
});