import {
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  heading: {
    fontWeight: 600,
    fontSize: 30,
    color: '#fff',
  },
  boxItem: {
    color: '#fff', 
    width: '11,3%',
    margin: '0 auto',
    justifyContent: 'center'
  },
  Boxwhite: {
    minWidth: '60px', fontWeight: 800, color: '#fff',
    margin: '0 auto',
    textAlign: 'center',
    marginRight: 8,
    fontSize: 16,
  },
  setColorWhite: {
    color: '#fff',
    fontSize: 12,
  },
  Skeleton: {
    background: '#373435', 
    width: '100%', 
    height: '60px', 
    margin: '5px auto', 
    borderRadius: 8
  },
  buttonGrow: {
    background: '#373737',
    borderRadius: 5,
    padding: 8,
    margin: '0 5px',

    '&:hover': { 
      border: '1px solid #fff',
    }
  }
}));