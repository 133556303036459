import {
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  HeaderMenu: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    background: '#373435', 
    borderRadius: 8, 
    padding: 8, 
    flexDirection: 'column', 
    width: '400px'
  },
  seller: {
    fontSize: 16, 
    color: '#fff', 
    fontWeight: 900, 
    paddingRight: 10
  },
  supervisor: {
    fontSize: 12, 
    color: '#fff', 
    fontWeight: 900, 
    paddingRight: 10
  },
  title: {
    fontSize: 16, 
    color: '#fff', 
    fontWeight: 900
  },
  subtitle: {
    fontSize: 12, 
    color: '#fff'
  }
}));