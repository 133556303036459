import React from 'react';
import moment from 'moment';

import { 
  Line 
} from 'react-chartjs-2';

import { 
  useStyles 
} from './styles';

export default function Chart({ shareIndicators }) {
  const classes = useStyles();

  const data = {
    labels: shareIndicators.map(
      item => moment(item.data_venda).format("DD/MM")
    ),
    datasets: [
      {
        label: 'Loja',
        data: shareIndicators.map(item => Math.round(item.shareLoja * 100)),
        borderColor: '#051EFA',
        backgroundColor: 'rgba(65, 65, 145, 1)',
        fill: true,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: 'rgba(65, 65, 145, 1)',
        pointHoverBorderColor: '#fff',
        pointHoverBorderWidth: 2,
      },
      {
        label: 'CD Rio',
        data: shareIndicators.map(item => Math.round(item.shareCDRJ * 100)),
        borderColor: '#cc3022',
        backgroundColor: 'rgba(250, 83, 69, 1)',
        fill: true,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: 'rgba(250, 83, 69, 1)',
        pointHoverBorderColor: '#fff',
        pointHoverBorderWidth: 2,
      },
      {
        label: 'CD SP',
        data: shareIndicators.map(item => Math.round(item.shareCDSP * 100)),
        borderColor: '#FA3E2D',
        backgroundColor: 'rgba(227, 52, 90, 1)',
        fill: true,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: 'rgba(227, 52, 90, 1)',
        pointHoverBorderColor: '#fff',
        pointHoverBorderWidth: 2,
      },
    ]  
  };

  return(
    <div className={classes.BoxChart}>
      <Line
        data={data}
        width={'100%'}
        height={'auto'}
        options={{
          responsive: true, 
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              usePointStyle: true,
            },
          },
          pointBackgroundColor: '#fff',
          radius: 10,
          interaction: {
            intersect: false,
          },
          scales: {
            xAxes: [{
              barPercentage: 0.8,
              gridLines: {
                display: false
              },
              ticks: {
                fontColor: '#fff'
              }
            }],
            
            yAxes: [{
              ticks: {
                fontColor: '#fff',
                max: 100,
                stepSize: 20,
                beginAtZero: true
              },
              stacked: true,
            }]
          }          
        }}
      />       
    </div>
  );
}