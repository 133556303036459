import React from "react";

import { BrowserRouter, Switch } from "react-router-dom";

import Route from "./Route";
import HeaderComponent from "./components/Header";
import Logistic from "./pages/Logistic";
import LoginPage from "./pages/login";
import CreateUsers from "./pages/createUsers";
import BranchesOfStatesPage from "./components/BranchesOfStates";
import SupervisorDash from "./pages/supervisorDashboard";
import IntegrationRules from "./pages/integrationRules";
import IntegrationRulesEdit from "./pages/integrationRulesEdit";
import StoreAutomatization from "./pages/storeAutomatization";
import StoreLogisticSetup from "./pages/storeLogisticSetup";
import StoreAutomatizationUpload from "./pages/storeAutomatizationUpload";
import PrincipalDash from "./pages/principalDashboard";
import EditUser from "./pages/editUsers";
import OrchestratorSetup from "./pages/orchestratorSetup";
import ScenariosSetup from "./pages/ScenariosSetup";
import DeflatorSku from "./pages/deflatorSku";

export default function Routes() {
  function renderHeader(component) {
    return (
      <HeaderComponent onClick={(link) => this.handleClick(link.route)}>
        {component}
      </HeaderComponent>
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/logistic"
          component={() => renderHeader(<Logistic />)}
          isPrivate
          isAdministrator
          isManagement
        />
        <Route path="/" exact component={LoginPage} />
        <Route
          path="/dashboard"
          exact
          component={() => renderHeader(<PrincipalDash />)}
          isPrivate
          isAdministrator
        />
        <Route
          path="/integration"
          exact
          component={() => renderHeader(<IntegrationRules />)}
          isPrivate
          isAdministrator
          isManagement
        />
        <Route
          path="/integration/edit"
          exact
          component={() => renderHeader(<IntegrationRulesEdit />)}
          isPrivate
          isAdministrator
          isManagement
        />
        <Route
          path="/automatization"
          exact
          component={() => renderHeader(<StoreAutomatization />)}
          isPrivate
          isAdministrator
        />
        <Route
          path="/automatization/setup"
          exact
          component={() => renderHeader(<StoreLogisticSetup />)}
          isPrivate
          isAdministrator
        />
        <Route
          path="/automatization/upload"
          exact
          component={() => renderHeader(<StoreAutomatizationUpload />)}
          isPrivate
          isAdministrator
        />
        <Route
          path="/dashboard/ecommerce"
          exact
          component={() => renderHeader(<PrincipalDash />)}
          isPrivate
          isEcommerce
        />
        <Route
          path="/dashboard/management"
          exact
          component={() => renderHeader(<PrincipalDash />)}
          isPrivate
          isManagement
        />
        <Route
          path="/dashboard/supervisor"
          exact
          component={() => renderHeader(<SupervisorDash />)}
          isPrivate
          isSupervisor
        />
        <Route
          path="/users/create"
          exact
          component={() => renderHeader(<CreateUsers />)}
          isPrivate
          isAdministrator
        />
        <Route
          path="/users/edit"
          exact
          component={() => renderHeader(<EditUser />)}
          isPrivate
          isAdministrator
          isEcommerce
          isManagement
          isSupervisor
        />
        <Route
          path="/branches_of_states"
          exact
          component={() => renderHeader(<BranchesOfStatesPage />)}
          isPrivate
          isAdministrator
        />
        <Route
          path="/orchestrator"
          exact
          component={() => renderHeader(<OrchestratorSetup />)}
          isPrivate
          isAdministrator
        />
        <Route
          path="/orchestrator/scenarios"
          exact
          component={() => renderHeader(<ScenariosSetup />)}
          isPrivate
          isAdministrator
          isManagement
        />
        <Route
          path="/deflator"
          exact
          component={() => renderHeader(<DeflatorSku />)}
          isPrivate
          isAdministrator
          isManagement
        />
      </Switch>
    </BrowserRouter>
  );
}
