import React, { createContext, useCallback, useContext } from 'react';
import { getAllShippingPolicies } from '../services/logisticSetup/shippingPolicies';
import { getAllDocks } from '../services/logisticSetup/docks';
import { getAllWarehouses } from '../services/logisticSetup/warehouses';

export const LogisticContext = createContext({});

export const LogisticProvider = ({ children }) => {
    const getShippingPoliciesByBrandId = useCallback(async (brandId) => {
      const response = await getAllShippingPolicies(brandId);
      return response;
    }, []);

    const getDocksByBrandId = useCallback(async (brandId) => {
      const response = await getAllDocks(brandId);
      return response;
    }, []);

    const getWarehousesByBrandId = useCallback(async (brandId) => {
      const response = await getAllWarehouses(brandId);
      return response;
    }, []);
  
  return (
    <LogisticContext.Provider 
        value={{ getShippingPoliciesByBrandId, getDocksByBrandId, getWarehousesByBrandId }}
    >
      {children}
    </LogisticContext.Provider>
  );
};

export function useLogistic(){
  const context = useContext(LogisticContext);

  if(!context){
    throw new Error("Não existe contexto LogisticProvider");
  }

  return context;
}