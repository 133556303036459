import React from 'react';

import { 
  IoIosArrowBack 
} from 'react-icons/io';

import { 
  FaPlay, 
  FaPause, 
  FaStop 
} from 'react-icons/fa';

import { 
  useStyles, 
  materialThemeMobile 
} from './styles';

import {
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Typography,
    Divider,
    ThemeProvider
} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ 
  open, 
  setOpen 
}) {
  const classes = useStyles();

  const onClose = () => {
    setOpen(false);
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        fullScreen={true}
      >
        <ThemeProvider theme={materialThemeMobile}>
          <DialogTitle 
            className={classes.BoxDialog} 
            style={{ textAlign: 'center', fontSize: 18 }} 
            id="alert-dialog-slide-title"
          >
            <Grid 
              container 
              alignItems="center" 
              justifyContent="flex-start" 
              direction="row"
            >
              <Button onClick={() => onClose()} color="secondary">
                <IoIosArrowBack size={18} style={{ color: '#fff'}}/>
              </Button>
              <Typography style={{ textAlign: 'center', fontSize: 18, flex: 1 }}>
                Indicadores
              </Typography>
            </Grid>
            
          </DialogTitle>
          <DialogContent className={classes.BoxDialog}>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid container justify="flex-start" alignItems="flex-start" style={{ padding: 10, width: '90%', flexDirection: 'column', borderRadius: 8 }}>
                <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 5 }}>
                    <FaPlay style={{ marginRight: '10px', color: '#42a31c' }}/>
                    <Typography style={{ color: '#fff' }}>Ligado</Typography>
                  </Grid>
                  <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 5 }}>
                    <FaPause style={{ marginRight: '10px', color: '#d62b2b' }}/>
                    <Typography style={{ color: '#fff' }}>Teto batido</Typography>
                  </Grid>
                  <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 5 }}>
                    <FaStop style={{ marginRight: '10px', color: '#d62b2b' }}/>
                    <Typography style={{ color: '#fff' }}>Desligado</Typography>
                  </Grid>
              </Grid>
              <Grid container style={{ background: '#373435', padding: 10, width: '100%', height: '100%', margin: '20px auto', flexDirection: 'column', borderRadius: 8 }}>
                  <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 5 }}>
                      <Typography style={{width: '30%', fontSize: 16, fontWeight: 600, color: '#fff', padding: 10, textAlign: 'center' }}>Tempo Romaneio</Typography>
                      <Typography style={{ color: '#fff', width: '60%', textAlign: 'center' }}>É o tempo médio, em dias, que a loja leva para encerrar os romaneios</Typography>
                  </Grid>
                  <Grid container style={{ width: '100%', background: '#fff' }} alignItems="center">
                      <Divider light />
                  </Grid>
                  <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 5 }}>
                      <Typography style={{width: '30%', fontSize: 16, fontWeight: 600, color: '#fff', padding: 10, textAlign: 'center' }}>TMA</Typography>
                      <Typography style={{ color: '#fff', width: '60%', textAlign: 'center' }}>É o tempo médio, em horas, que a loja leva para aceitar os pedidos omni</Typography>
                  </Grid>
                  <Grid container style={{ width: '100%', background: '#fff' }} alignItems="center">
                      <Divider light />
                  </Grid>
                  <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 5 }}>
                      <Typography style={{width: '30%', fontSize: 16, fontWeight: 600, color: '#fff', padding: 10, textAlign: 'center' }}>Recusa</Typography>
                      <Typography style={{ color: '#fff', width: '60%', textAlign: 'center' }}>Representa a taxa de cancelamento da loja. (cancelados / total)</Typography>
                  </Grid>
                  <Grid container style={{ width: '100%', background: '#fff' }} alignItems="center">
                      <Divider light />
                  </Grid>
                  <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 5 }}>
                      <Typography style={{width: '30%', fontSize: 16, fontWeight: 600, color: '#fff', padding: 10, textAlign: 'center' }}>Pendentes</Typography>
                      <Typography style={{ color: '#fff', width: '60%', textAlign: 'center' }}>São os pedidos que estão com status pendente, em separação e aceito</Typography>
                  </Grid>
                  <Grid container style={{ width: '100%', background: '#fff' }} alignItems="center">
                      <Divider light />
                  </Grid>
                  <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 5 }}>
                      <Typography style={{width: '30%', fontSize: 16, fontWeight: 600, color: '#fff', padding: 10, textAlign: 'center' }}>Pendentes antigos</Typography>
                      <Typography style={{ color: '#fff', width: '60%', textAlign: 'center' }}>São os pedidos pendentes há mais de 2 dias</Typography>
                  </Grid>
                  <Grid container style={{ width: '100%', background: '#fff' }} alignItems="center">
                      <Divider light />
                  </Grid>
                  <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 5 }}>
                      <Typography style={{width: '30%', fontSize: 16, fontWeight: 600, color: '#fff', padding: 10, textAlign: 'center' }}>Totais</Typography>
                      <Typography style={{ color: '#fff', width: '60%', textAlign: 'center' }}>É a quantidade total de pedidos capturados pela loja no período</Typography>
                  </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </ThemeProvider> 
      </Dialog>
    </div>
  );
}