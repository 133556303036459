import { 
  createMuiTheme
} from '@material-ui/core';

export const materialTheme = createMuiTheme({
  overrides: {
    MuiInput: {
      root: {
        color: '#fff'
      },
    },
    MuiInputBase: {
      input: {
        cursor: 'pointer'
      }
    }
  },
});