import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';

import AutoCompleteFilterSupervisor from '../AutoCompleteFilterSupervisor';
import MoreOrdersCardPodium from '../MoreOrdersCardPodium';

import { 
  IoIosArrowBack 
} from 'react-icons/io';

import { useAuth } from '../../context/authContext';

import { 
  FiCalendar 
} from 'react-icons/fi';

import {
  Skeleton
} from '@material-ui/lab';

import { 
  DatePicker, 
  MuiPickersUtilsProvider 
} from "@material-ui/pickers";

import { 
  getMoreOrders, 
  getSupervisors 
} from '../../services/storeData';

import { 
  useStyles, 
  materialTheme 
} from './styles';

import SearchIcon from '@material-ui/icons/Search';

import {
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Typography,
    IconButton,
    ThemeProvider
} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ 
  open, 
  setOpen, 
  selectedBrands 
}) {
    const classes = useStyles();
    const { user } = useAuth();
    const [data, setData] = useState([]);
    const [dataFix, setDataFix] = useState([]);
    const [supervisors, setSupervisors] = useState([]);
    const [selectedSupervisor, setSelectedSupervisor] = useState([]);
    const [loading, setLoading] = useState(false);

    const [endDate, setEndDate] = useState(moment().clone().endOf('month').format('YYYY-MM-DD'));
    const [startDate, setStartDate] = useState(moment().clone().startOf('month').format('YYYY-MM-DD'));

    const getMoreOrdersFunction = async (startDate, endDate, brands) => {
        const response = await getMoreOrders(startDate, endDate, brands);
        setDataFix(response);
        setData(response);
    };

    useEffect(() => {
        if(selectedSupervisor.length !== 0){
            const newData = dataFix.filter((item, index) => {
                const findSupervisor = selectedSupervisor.find(element => { 
                return element === item.id_supervisor 
                })

                if(findSupervisor !== undefined){
                    item.pos = index
                }

                return findSupervisor !== undefined;
            });
            setData(newData);
        }else{
            setData(dataFix);
        }
    }, [selectedSupervisor, selectedBrands, dataFix]);

    useEffect(() => {
        if(selectedBrands.length > 0){
            (async () => {
                let response = await getSupervisors();
                const supers = [];
                const corporative = selectedBrands.find(item => item === 0 );
        
                if(corporative !== undefined){
                setSupervisors(response);
                }
        
                if(selectedBrands.length !== 0 && corporative === undefined){
                response.forEach(item => {
                    const find = selectedBrands.find(element => { 
                    return element === item.rede_lojas 
                    })
        
                    if(find !== undefined){
                    supers.push(item)
                    }
                })
        
                setSupervisors(supers);
                }
            })();
        }
    }, [user, selectedBrands]);

    const changeData = useCallback(async () => {
        if(selectedBrands.length > 0){
            setLoading(true);
            await Promise.all([
                getMoreOrdersFunction(startDate, endDate, selectedBrands),
            ]);
            setLoading(false);
        }
    }, [startDate, endDate, selectedBrands]);

    useEffect(() => {
        if(selectedBrands.length > 0){
            (async () => {
                await changeData();
            })();
        }
        // eslint-disable-next-line
    }, [selectedBrands]);

    const onClose = () => {
        setOpen(false);
    }

    const handleDateStartChange = (date) => {
        setStartDate(moment(date).format('YYYY-MM-DD'));
    };

    const handleDateEndChange = (date) => {
        setEndDate(moment(date).format('YYYY-MM-DD'));
    };
   
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        fullScreen={true}
      >
        <ThemeProvider theme={materialTheme}>
          <DialogTitle 
            className={classes.BoxDialog} 
            style={{ 
              textAlign: 'center', 
              fontSize: 18 
            }} 
            id="alert-dialog-slide-title"
          >
            <Grid 
              container 
              alignItems="center" 
              justify="space-between" 
              direction="row"
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Button 
                  style={{ minWidth: 'auto' }} 
                  onClick={() => onClose()} 
                  color="secondary"
                >
                  <IoIosArrowBack size={18} style={{ color: '#fff'}}/>
                </Button>
                <Typography 
                  style={{ 
                    textAlign: 'center', 
                    fontSize: 18, 
                    flex: 1 
                  }}
                >
                  Ranking +Pedidos                
                </Typography>
              </Grid>
            </Grid>
          </DialogTitle>

        <DialogContent className={classes.BoxDialog}>
            <DialogContentText id="alert-dialog-slide-description">
                <Grid container justify="space-between" style={{ marginBottom: 30 }}>
                    <Grid item style={{ width: '300px' }}>
                        <AutoCompleteFilterSupervisor 
                            supervisors={supervisors} 
                            setSelectedSupervisor={setSelectedSupervisor} 
                            width={300} 
                            defaultValue={[]}
                        />
                    </Grid>
                    <Grid item container justify='space-between' alignItems='center' direction='row' style={{ width: '300px' }}>
                        <Grid item>
                            <Grid container alignItems="center" justify="center">
                                <FiCalendar size={25} style={{ color: '#fff', marginRight: 10 }}/>
                                <ThemeProvider theme={materialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            variant="inline"
                                            style={{ width: '90px' }}
                                            InputProps={{
                                                disableUnderline: true,
                                                color: '#fff',
                                            }}
                                            format="dd/MM/yyyy"
                                            value={moment(startDate)}
                                            onChange={handleDateStartChange}
                                        />
                                        <Typography 
                                            style={{ 
                                                color: '#fff', 
                                                margin: '0px 5px', 
                                                marginRight: 10 
                                            }}
                                        >
                                            -
                                        </Typography>
                                        <DatePicker
                                            variant="inline"
                                            style={{ width: '90px' }}
                                            format="dd/MM/yyyy"
                                            InputProps={{
                                                disableUnderline: true,
                                                color: '#fff',
                                            }}
                                            value={moment(endDate)}
                                            onChange={handleDateEndChange}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => changeData()} color="primary" aria-label="search">
                                <SearchIcon style={{ color: '#fff' }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
              
                {loading ?
                    <>
                        <Skeleton variant="rect" className={classes.skeleton} />
                        <Skeleton variant="rect" className={classes.skeleton} />
                        <Skeleton variant="rect" className={classes.skeleton} />
                        <Skeleton variant="rect" className={classes.skeleton} />
                    </> 
                :
                    (selectedSupervisor.length === 0 ?
                        <>
                            <Grid container justify="center" alignItems="center" style={{ marginBottom: 20 }}>
                                <Grid item className={classes.HeaderMenu}>
                                    <MoreOrdersCardPodium 
                                    seller={data.length > 0 ? data[0].seller : 'seller'}
                                    supervisor={data.length > 0 ? data[0].supervisor : 'supervisor'}
                                    color={'#FFD700'}
                                    recused={data.length > 0 ? data[0].recusados : '0'}
                                    invoiced={data.length > 0 ? data[0].faturados : '0'}
                                    points={data.length > 0 ? data[0].points : '0'}
                                    />
                                </Grid>
                                <Grid container alignItems="center" justify="space-evenly" style={{ margin: '10px 0px' }}>
                                    <Grid item className={classes.HeaderMenu}>
                                        <MoreOrdersCardPodium 
                                            seller={data.length > 1 ? data[1].seller : 'seller'}
                                            supervisor={data.length > 1 ? data[1].supervisor : 'supervisor'}
                                            color={'#C0C0C0'}
                                            recused={data.length > 1 ? data[1].recusados : '0'}
                                            invoiced={data.length > 1 ? data[1].faturados : '0'}
                                            points={data.length > 1 ? data[1].points : '0'}
                                        />
                                    </Grid>
                                    
                                    <Grid item className={classes.HeaderMenu}>
                                        <MoreOrdersCardPodium 
                                            seller={data.length > 2 ? data[2].seller : 'seller'}
                                            supervisor={data.length > 2 ? data[2].supervisor : 'supervisor'}
                                            color={'#cd7f32'}
                                            recused={data.length > 2 ? data[2].recusados : '0'}
                                            invoiced={data.length > 2 ? data[2].faturados : '0'}
                                            points={data.length > 2 ? data[2].points : '0'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        
                            <Grid container alignItems="center">
                                <Grid container className={classes.HeaderBg}>
                                    <Typography className={classes.dataNameTypography}>
                                    Posição
                                    </Typography>
                                    <Typography className={classes.sellersNameTypography}>
                                    Seller
                                    </Typography>
                                    <Typography className={classes.sellersNameTypography}>
                                    Supervisor
                                    </Typography>
                                    <Typography className={classes.dataNameTypography}>
                                    Faturados
                                    </Typography>
                                    <Typography className={classes.dataNameTypography}>
                                    Recusados
                                    </Typography>
                                    <Typography className={classes.dataNameTypography}>
                                    Pontos
                                    </Typography>
                                </Grid>
                                {data.filter((a, i) => {return i > 2}).map((item, index) => {
                                    return (
                                    <Grid container className={classes.dataBg}>
                                        <Typography className={classes.dataNameTypography}>
                                        {`${index + 4}º`}
                                        </Typography>
                                        <Typography className={classes.sellersNameTypography}>
                                        {item.seller}
                                        </Typography>
                                        <Typography className={classes.sellersNameTypography}>
                                        {item.supervisor}
                                        </Typography>
                                        <Typography className={classes.dataNameTypography}>
                                        {item.faturados}
                                        </Typography>
                                        <Typography className={classes.dataNameTypography}>
                                        {item.recusados}
                                        </Typography>
                                        <Typography className={classes.dataNameTypography}>
                                        {item.points}
                                        </Typography>
                                    </Grid>
                                    );
                                })}
                            </Grid>
                        </>
                    :
                        <>
                            <Grid container className={classes.HeaderBg}>
                                <Typography className={classes.dataNameTypography}>
                                    Posição
                                </Typography>
                                <Typography className={classes.sellersNameTypography}>
                                    Seller
                                </Typography>
                                <Typography className={classes.sellersNameTypography}>
                                    Supervisor
                                </Typography>
                                <Typography className={classes.dataNameTypography}>
                                    Faturados
                                </Typography>
                                <Typography className={classes.dataNameTypography}>
                                    Recusados
                                </Typography>
                                <Typography className={classes.dataNameTypography}>
                                    Pontos
                                </Typography>
                            </Grid>
                            {data.map((item, index) => {
                                return (
                                    <Grid container className={classes.dataBg}>
                                        <Typography className={classes.dataNameTypography}>
                                            {`${item.pos + 1}º`}
                                        </Typography>
                                        <Typography className={classes.sellersNameTypography}>
                                            {item.seller}
                                        </Typography>
                                        <Typography className={classes.sellersNameTypography}>
                                            {item.supervisor}
                                        </Typography>
                                        <Typography className={classes.dataNameTypography}>
                                            {item.faturados}
                                        </Typography>
                                        <Typography className={classes.dataNameTypography}>
                                            {item.recusados}
                                        </Typography>
                                        <Typography className={classes.dataNameTypography}>
                                            {item.points}
                                        </Typography>
                                    </Grid>
                                );
                            })}
                        </>
                    )
                }
            </DialogContentText>
          </DialogContent>
        </ThemeProvider> 
      </Dialog>
    </div>
  );
}