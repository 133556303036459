export const BRANDSTOCHOICE = [
  { name: "Corporativo", rede_lojas: 0 },
  { name: "Animale", rede_lojas: 1 },
  { name: "Farm", rede_lojas: 2 },
  { name: "Fábula", rede_lojas: 5 },
  { name: "Off Premium", rede_lojas: 6 },
  { name: "Foxton", rede_lojas: 7 },
  { name: "Cris Barros", rede_lojas: 9 },
  { name: "Maria Filó", rede_lojas: 15 },
  { name: "NV", rede_lojas: 16 },
  { name: "Hering", rede_lojas: 17 },
  { name: "Dzarm", rede_lojas: 20 },
];

export const CURRENCIES = [
  {
    value: 1,
    label: "Administrador",
  },
  {
    value: 4,
    label: "Supervisor",
  },
  {
    value: 5,
    label: "Ecommerce",
  },
  {
    value: 6,
    label: "Management",
  },
];
