import React, { useState } from 'react';

import { 
  activeDock, 
  desactiveDock, 
  changeDockTime 
} from '../../services/docks';

import { 
  useStyles, 
  materialTheme 
} from './styles';

import LoadingModal from '../../components/LoadingModal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Slide,
    Typography,
    TextField,
    MenuItem,
    ThemeProvider
} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ 
  open, 
  setOpen, 
  data,
  DOCKS,
  MODE,
  STATUS 
}) {
    const classes = useStyles();
    const [loadingModalOpen, setLoadingModalOpen] = useState(false);
    const [modeOption, setModeOption] = useState(1);
    const [dockOption, setDockOption] = useState('Sequoia');
    const [statusOption, setStatusOption] = useState('Sequoia');
    const [dockTime, setDockTime] = React.useState('');

    const handleChangeDockTime = (event) => {
      setDockTime(event.target.value);
    };

    const handleChangeMode = (event) => {
      setModeOption(event.target.value);
    };

    const handleChangeStatus = (event) => {
      setStatusOption(event.target.value);
    };

    const handleChangeDock = (event) => {
      setDockOption(event.target.value);
    };

    const onClose = () => {
      setOpen(false);
    }

    const activeDockFunction = async (seller, id) => {
      await activeDock(seller, id);
    }

    const desactiveDockFunction = async (seller, id) => {
      await desactiveDock(seller, id);
    }

    const changeDockTimeFunction = async (seller, id, dockTime) => {
      await changeDockTime(seller, id, dockTime);
    }

    const submitConfigurations = async (data) => {
      setLoadingModalOpen(true);
      for(let i = 0; i < data.length; i++){
        try{
          if(modeOption === 1){
            statusOption === 1 ? 
              await activeDockFunction(data[i].seller, dockOption)
            :
              await desactiveDockFunction(data[i].seller, dockOption);
            
            toast.success("Configurações de doca ajustados com sucesso");
          }
          if(modeOption === 2){
            await changeDockTimeFunction(data[i].seller, dockOption, dockTime);
            toast.success("Tempo de doca ajustados com sucesso");
          }
        }catch{
          toast.error("Erro ao realizar as configurações");
        }
        
      }
      setLoadingModalOpen(false);
    }

    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => onClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth={false}
          fullScreen={true}
        >
          <ThemeProvider theme={materialTheme}>
            <DialogTitle 
              className={classes.BoxDialog} 
              style={{ textAlign: 'center', fontSize: 18 }} 
              id="alert-dialog-slide-title"
            >
              <Grid 
                container 
                alignItems="center" 
                justify="space-between" 
                direction="row"
              >
                <Grid 
                  item
                  className={classes.ItemHeader}
                >
                  <Typography className={classes.Title}>
                    Configurações
                  </Typography>
                  <Typography className={classes.Subtitle}>
                    {`Você selecionou ${data.length} lojas`}
                  </Typography>
                </Grid>              
              </Grid>
            </DialogTitle>
            <DialogContent className={classes.BoxDialog}>
                <Grid 
                  container 
                  style={{ marginTop: 30 }} 
                  direction="column"
                >
                  <Grid item>
                    <TextField
                      id="outlined-select-currency"
                      select
                      size="small"
                      label="Configuração"
                      value={modeOption}
                      onChange={handleChangeMode}
                      variant="outlined"
                      style={{ width: '100%', marginBottom: 20 }}
                    >
                      {MODE.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item>
                    <TextField
                      id="outlined-select-currency"
                      select
                      size="small"
                      label="Doca"
                      value={dockOption ? dockOption : ''}
                      onChange={handleChangeDock}
                      variant="outlined"
                      style={{ width: '100%', marginBottom: 20 }}
                    >
                      {DOCKS.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                
                  {modeOption === 1 ? 
                    <Grid item>
                      <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        label="Status"
                        value={statusOption}
                        onChange={handleChangeStatus}
                        variant="outlined"
                        style={{ width: '100%' }}
                      >
                        {STATUS.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  : 
                  null}

                  {modeOption === 2 ? 
                    <Grid item>
                      <TextField
                        id="outlined-name"
                        label="Tempo de doca"
                        defaultValue="2"
                        size="small"
                        value={dockTime}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChangeDockTime}
                        variant="outlined"
                        style={{ width: '100%' }}
                      />
                    </Grid>
                  : 
                  null}
                </Grid>

                <Grid 
                  style={{ marginTop: 60 }} 
                  container 
                  justify="space-around" 
                  alignItems="center"
                >
                  <Button 
                    className={classes.Button} 
                    onClick={() => onClose()}
                  >
                    Voltar
                  </Button>
                  <Button 
                    className={classes.Button} 
                    onClick={() => submitConfigurations(data)}
                  >
                    Confirmar
                  </Button>
                </Grid>
            </DialogContent>
          </ThemeProvider> 
        </Dialog>

        <LoadingModal
          open={loadingModalOpen}
          title={"Ajustando configurações"}
        />

        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={false}
        />
      </div>
    );
}