import React, { useEffect, useState } from 'react';

import { 
  Grid, 
  ThemeProvider,
  Accordion,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Typography,
  Tooltip,
  Button
} from '@material-ui/core';

import { 
  useStyles, 
  materialTheme 
} from './styles';

export default function ActionsInAccordionSummary({ 
  item,
  objActual,
  setDataCheck, 
  dataCheck, 
  checkFlag 
}) {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if(dataCheck.length === 0){
      setChecked(false);
    }
  }, [dataCheck]);

  const handleCheckBox = (event) => {
    if(event.target.checked === true){
      const arr = [
        ...dataCheck,
        item,
      ]
      setDataCheck(arr)
    }else if(event.target.checked === false){
      const arr = dataCheck.filter(element => {return item.id !== element.id});
      setDataCheck(arr)
    }
    event.stopPropagation();
    setChecked(event.target.checked);
  };

  const chooseColumn = (link, item) => {
    let data = '';

    if(link === 'collectionsLength'){
        data = item.collectionsLength;
    }else if(link === 'minimumPrice'){
        data = item.minimumPrice;
    }else if(link === 'integrateProductsLength'){
        data = item.integrateProductsLength;
    }else if(link === 'deleteProductsLength'){
        data = item.deleteProductsLength;
    }else if(link === 'tablePrice'){
        data = item.tablePrice;
    }
    
    return (
      <Tooltip title={objActual.title} arrow>
        <span style={{ width: '30%' }}>
          <Button disabled className={classes.selectedIndicatorBox}>
            <Grid className={classes.selectedIndicatorGrid}>
              {/* {objActual.icon} */}
              <Typography 
                style={{ 
                  marginLeft: '5px', 
                  minWidth: '60px', 
                  color: '#fff'  
                }}
              >
                {data ? data : 'nulo'}
              </Typography>
            </Grid>
          </Button>
        </span>
      </Tooltip>                           
    );
  }

  return (
    <div className={classes.root}>
      <ThemeProvider theme={materialTheme}>
        <Accordion 
          expanded={false} 
          defaultExpanded={true} 
          className={checkFlag === true ?
            classes.AccordionSelected 
            : 
            checked ? 
              classes.AccordionSelected 
              : 
              classes.Accordion
          }
        >
          <AccordionSummary>
            <FormControlLabel
              checked={checkFlag === true ? true : checked}
              aria-label="Acknowledge"
              onClick={(event) => handleCheckBox(event)}
              onFocus={(event) => event.stopPropagation()}
              control={<Checkbox color={"#fff"}/>}
              className={classes.boxCheck}
            /> 
            
            <Grid 
              container 
              justify="space-between" 
              alignItems="center" 
              style={{ width: '100%' }}
            >
              <Grid className={classes.boxStore}>
                <Grid container item className={classes.containerTitleStore}>
                  <Grid item style={{ minWidth: '150px' }}>
                    <Typography 
                      style={{ 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis', 
                        color: '#fff',
                        paddingLeft: 5,
                      }}
                    >
                      {item.sellerId}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              {chooseColumn(objActual.link, item)}   
            </Grid>
          </AccordionSummary>
        </Accordion>
      </ThemeProvider>
    </div>
  );
}