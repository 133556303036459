import {
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: '#fff',
  },
  container: {
    width: '100%', 
    boxShadow: 'none', 
    background: '#373435', 
    margin: '5px 0', 
    borderRadius: '8px'
  },
  summaryAndDetails: {
    background: '#373435', 
    boxShadow: 'none', 
    borderRadius: '8px'
  },
  skeleton: {
    background: '#373435', 
    width: '100%', 
    height: '50px', 
    margin: '5px auto', 
    borderRadius: 8
  },
  atributtesContent: {
    color: '#c9c9c9',
    padding: '5px 0'
  },
  atributtesItemContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
}));