import React, { useEffect, useState } from 'react';

import { 
  useHistory 
} from 'react-router-dom';

import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Typography,
  ThemeProvider
} from '@material-ui/core';

import { 
  materialTheme, 
  useStyles 
} from './styles';

import { 
  putIntegrationRulesBySellerId 
} from '../../services/integrationRules';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LoadingModal from '../LoadingModal';
import Switchintegration from '../Switch';

import { useAuth } from '../../context/authContext';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ 
  open, 
  setOpen, 
  data,
  obj
}) {
    const classes = useStyles();
    const history = useHistory();
    const { user } = useAuth();

    const [loadingModalOpen, setLoadingModalOpen] = useState(false);
    const [integrationData, setIntegrationData] = useState(obj);

    const onClose = () => {
      setOpen(false);
    }

    useEffect(() => {
      setIntegrationData(obj)
    }, [obj]);

    function timeout(delay) {
      return new Promise( res => setTimeout(res, delay) );
    }

    const setIntegrationFunction = async (seller, array, user) => {
      const response = await putIntegrationRulesBySellerId(seller, array, user);
      return response;
    }

    const ajustIntegrationRules = async (data, integrationRules, user) => {
      setLoadingModalOpen(true);
      let errors = 0;
      for(let i = 0; i < data.length; i++){
        try{
          await setIntegrationFunction(data[i].sellerId, integrationRules, user);
        }catch(err){
          errors = errors + 1;
          toast.error(err.response.data.error ? err.response.data.error : `Erro ao ajustar a regra de integração da ${data[i].sellerId}`);
        }
        
      }
      setLoadingModalOpen(false);
      toast.success(`Regras de integração ajustadas com sucesso para ${data.length - errors} de ${data.length} lojas`);
      await timeout(5000)
      history.push('/integration');

    }

    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => onClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <ThemeProvider theme={materialTheme}>
            <DialogContent className={classes.BoxDialog}>
              <Grid container justify='center' alignItems='center' direction='column'>
                <Grid item>
                  <Typography className={classes.title}>
                    {`Escolha as regras que deseja alterar`}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className={classes.subTitle}>
                    {`foram selecionadas ${data.length} lojas`}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className={classes.containerSwitch}>
                <Switchintegration 
                  title={'Coleções'} 
                  field={"EstoqueFiltroColecao"} 
                  data={integrationData} 
                  setData={setIntegrationData} 
                  originalArray={obj}
                />
                <Switchintegration 
                  title={'Preço mínimo'} 
                  field={"EstoqueFiltroPrecoMinimo"} 
                  data={integrationData} 
                  setData={setIntegrationData} 
                  originalArray={obj}
                />
                <Switchintegration 
                  title={'Forçar produtos'} 
                  field={"EstoqueFiltroProdutosForcar"} 
                  data={integrationData} 
                  setData={setIntegrationData} 
                  originalArray={obj}
                />
                <Switchintegration 
                  title={'Excluir produtos'} 
                  field={"EstoqueFiltroProdutosNaoEnviar"} 
                  data={integrationData} 
                  setData={setIntegrationData} 
                  originalArray={obj}
                />
                <Switchintegration 
                  title={'Tabela preço'} 
                  field={"EstoqueFiltroTabelaPreco"} 
                  data={integrationData} 
                  setData={setIntegrationData} 
                  originalArray={obj}
                />
              </Grid>

              <Grid container style={{ margin: '12px 0' }} justify='center' alignItems='center' direction='column'>
                <Grid item>
                  <Button onClick={() => ajustIntegrationRules(data, integrationData, user.email)} variant="contained" style={{ background: '#373435' }}>confirmar</Button>
                </Grid>
              </Grid>
            </DialogContent>
          </ThemeProvider> 
        </Dialog>

        <LoadingModal
          open={loadingModalOpen}
          title={"Ajustando regras"}
        />

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </div>
    );
}