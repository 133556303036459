import React from 'react';
import { Typography, Card, Grid, Divider } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import TransparentTextField from '../TransparentTextField';
import AutoCompleteOneItem from '../AutoCompleteOneItem';
import { useStyles } from './styles';

export default function Card6v4({ dataArray, principalTitle, itemsToChoose }) {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
        <CardContent style={{ padding: 10, paddingBottom: 10 }}>
            <Grid container spacing={3} justify="space-between" alignItems='center' direction='row'>
                <Grid item container justify="space-between" alignItems='center' direction='row'>
                    <Grid item xs={4}>
                        <Typography variant='body1' style={{ fontWeight: 600, paddingLeft: 30, color: '#fff'}} gutterBottom> {principalTitle} </Typography>            
                    </Grid>
                </Grid>

                <Divider className={classes.dividerSecondary} />

                {/* Lines */}
                {dataArray.map(item => (
                    <>
                        <Grid item container justify="space-between" alignItems='center' direction='row' style={{ height: 65 }}>
                            <Grid item xs={4}>
                                <Typography variant='body1' style={{ fontWeight: 600, paddingLeft: 30, color: '#fff'}} gutterBottom>
                                    {item.lineTitle}
                                </Typography>
                            </Grid>
                            {
                                item.lineTitle === 'Distribuição' ?
                                    <Grid item container xs={4} justify="center" alignItems='center' direction='row'>
                                        <AutoCompleteOneItem
                                            title={"Doca associada"}
                                            itemsToChoose={itemsToChoose}
                                            item={item.selectItem}
                                            setItem={item.setSelectItem}
                                        />
                                    </Grid>
                                : null
                            }
                            <Grid item container xs={4} justify="center" alignItems='center' direction='row'>
                                <TransparentTextField 
                                    rows={1}
                                    objectToRender={item.firstlineState}
                                    stateToChange={item.firstlineSetState}
                                />
                            </Grid>
                            
                            
                        </Grid>

                        <Divider className={classes.dividerSecondary} />
                    </>
                ))}
            </Grid>
        </CardContent>
    </Card>
  );
}