import React from 'react';

import {
  FaPlay, 
  FaPause, 
  FaStop 
} from 'react-icons/fa';

import { 
  Grid,
  Typography,
  ThemeProvider,
  Button,
  Card,
  Tooltip,
} from '@material-ui/core';

import { 
  theme, 
  useStyles 
} from './styles';

export default function MultilineTextFields({ 
  item, 
  objActual 
}) {
  const classes = useStyles();

  const chooseColumn = (link, item) => {
    let data = '';

    if(link === 'media_romaneio'){
        data = item.media_romaneio;
    }else if(link === 'tma_horas'){
        data = item.tma_horas;
    }else if(link === 'taxa_cancelamento'){
        data = item.taxa_cancelamento;
    }else if(link === 'pedidos_pendentes'){
        data = item.pedidos_pendentes;
    }else if(link === 'pedidos_pendentes_2dias'){
        data = item.pedidos_pendentes_2dias;
    }else if(link === 'pedidos_totais'){
        data = item.pedidos_totais;
    }
    
    return (
      <Tooltip title={objActual.title} arrow>
        <span style={{ width: '30%' }}>
          <Button disabled className={classes.selectedIndicatorBox}>
            <Grid className={classes.selectedIndicatorGrid}>
              {objActual.icon}
              <Typography 
                style={{ 
                  marginLeft: '5px', 
                  minWidth: '60px', 
                  color: link !== 'tma_horas' ? 
                    '#fff' 
                  : item.tma_horas <= 2 ? 
                    '#028A0F' 
                  : item.tma_horas > 3 ? 
                    '#ff1100' 
                  : 
                    '#fff' 
                }}
              >
                {data}
              </Typography>
            </Grid>
          </Button>
        </span>
      </Tooltip>                           
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Card style={{ display: 'flex', width: '100%', margin: '5px auto', height: 'auto', background: '#373435', justifyContent: 'space-between', alignItems: 'center'}}>
          <span style={{ width: '70%' }}>
            <Button disabled style={{ textTransform: 'lowercase', color: '#222', textAlign: 'left', width: '100%', justifyContent: 'flex-start' }}>
              <Grid style={{ display: 'flex', alignItems: 'center' }}>
                {
                  item.ligado ?
                    (item.abaixo_do_teto === false ?
                      <FaPause size={16} className={classes.pause}/>
                    :
                    item.ligado === true ?
                      <FaPlay size={16} className={classes.play}/>
                    :
                      <FaStop size={16} className={classes.pause}/>
                    )
                  :
                  <FaStop size={16} className={classes.pause}/>
                }
                <Grid item className={classes.boxStoreTypography}>
                  <Typography className={classes.store}>
                    {item.loja}
                  </Typography>
                  <Typography className={classes.filial}>
                    {item.filial}
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </span>

          {chooseColumn(objActual.link, item)}        
        </Card>
      </ThemeProvider>
    </>
  );
}