import React, { useEffect, useState } from 'react';

/**
 * Libraries Imports
*/
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Material-Ui Imports
*/

import Skeleton from '@material-ui/lab/Skeleton';

import {  
    Grid,
    Typography,
    Button,
    Divider
} from '@material-ui/core';

/**
 *  Components Imports
*/

import RealocationSetup from '../../components/realocationSetupOrchestrator';
import RealocationDistribution from '../../components/RealocationDistribution';
import AutoCompleteChooseBrand from '../../components/AutoCompleteChooseBrand';
import Footer from '../../components/Footer';
import LoadingModal from '../../components/LoadingModal';
import RealocationSignals from '../../components/realocationSignals';
import ContainerLines from '../../components/containerLines';

/**
 *  Styles Imports
*/

import { 
    useStyles 
} from './styles';

import { 
    PageBackground, 
    pageGrid 
} from '../../stylesCSS';

/**
 *  Consts Imports
*/

import { 
    SomaGroupBrands 
} from '../../consts/brands';

import { 
    STATES 
} from '../../consts/states';

/**
 *  Services Imports
*/

import { 
    getOrchestratorRealocationDataByBrandId, 
    getOrchestratorGeneralDataByBrandId,
    getOrchestratorManagementDataByBrandId,
    updateOrchestratorRealocationDataByBrandId,
    updateOrchestratorCapacityScenarioByBrandId,
    updateOrchestratorDeflatorScenarioByBrandId,
    updateOrchestratorGeneralDataByBrandId,
    getCommissionByBrandId,
    getDistributionByBrandId,
    updateOrchestratorCommissionByBrandId,
    updateOrchestratorDistributionByBrandId 
} from '../../services/orchestrator';
import { useCallback } from 'react';

/**
 *  Principal Function Component
*/

export default function IntegrationRulesDash(props) {
    const classes = useStyles(props);

    const [loadingGeneral, setLoadingGeneral] = useState(true);
    const [loadingRealocation, setLoadingRealocation] = useState(true);
    const [loadingModalOpen, setLoadingModalOpen] = useState(false);

    /**
     * General States
    */

    const [CanceledRealocation, setCanceledRealocation] = useState(false);
    const [LinxRealocation, setLinxRealocation] = useState(false);
    const [SLARealocation, setSLARealocation] = useState(false);
    const [RecentRealocation, setRecentRealocation] = useState(false);

    const [UpdateCapacity, setUpdateCapacity] = useState(false);
    const [SellerStatus, setSellerStatus] = useState(false);
    const [UpdateComprehensive, setUpdateComprehensive] = useState(false);
    const [UpdateDeflator, setUpdateDeflator] = useState(false);

    /**
     * Realocation states
    */

    const [experience, setExperience] = useState(false);
    const [onlyCD, setOnlyCD] = useState(false);
    const [shippingEstimateCheck, setShippingEstimateCheck] = useState(false);
    const [costCheck, setcostCheck] = useState(false);
    const [indicatorsCheck, setindicatorsCheck] = useState(false);

    /**
     * Seller management states 
    */

    const [scenarios, setscenarios] = useState('conservative');
    const [capacityScenarios, setCapacityScenarios] = useState('conservative');

    const [sellerManagementDeflator, setSellerManagementDeflator] = useState({
        conservative: 0,
        normal: 0,
        aggressive: 0,
        undefined: 0,
    });

    const [sellerManagementCapacity, setSellerManagementCapacity] = useState({
        conservative: 0,
        normal: 0,
        aggressive: 0,
        undefined: 0,
    });


    /**
     * Signals states 
    */

     const [costGreen, setCostGreen] = useState(0);
     const [costYellow, setCostYellow] = useState(0);
     const [experienceGreen, setExperienceGreen] = useState(0);
     const [experienceYellow, setExperienceYellow] = useState(0);

    /**
     * Distribution states 
    */

    const [commission, setComission] = useState(0);
    const [stateToChoose, setStateToChoose] = useState("RJ");
    const [distribution, setDistribution] = useState(0);

    /**
     * Brand state
    */

    const SOMABRANDS = SomaGroupBrands.map(item => {
        return {
            key: item.brandName,
            value: item.brandId
        };
    });

    const [brand, setBrand] = React.useState(() => {
        const brandName = localStorage.getItem(`@maisomni:favoriteBrand`) ? localStorage.getItem(`@maisomni:favoriteBrand`) : "";
        const item = SOMABRANDS.find(item => item.key === brandName);
        return item !== undefined ? item.value : "";
    });

    /**
     * Data Arrays 
    */

    const realocationArrayData = [
        {
            lineTitle: "Realoca cancelado",
            lineState: CanceledRealocation,
            lineSetState: setCanceledRealocation
        },
        {
            lineTitle: "Realoca Recente",
            lineState: RecentRealocation,
            lineSetState: setRecentRealocation
        },
        {
            lineTitle: "Realoca Linx",
            lineState: LinxRealocation,
            lineSetState: setLinxRealocation
        },
        {
            lineTitle: "Realoca SLA",
            lineState: SLARealocation,
            lineSetState: setSLARealocation
        },
    ]

    const sellerManagementArrayData = [
        {
            lineTitle: "Atualiza capacidade",
            lineState: UpdateCapacity,
            lineSetState: setUpdateCapacity
        },
        {
            lineTitle: "Atualiza Deflator",
            lineState: UpdateDeflator,
            lineSetState: setUpdateDeflator
        },
        {
            lineTitle: "Atualiza Abrangentes",
            lineState: UpdateComprehensive,
            lineSetState: setUpdateComprehensive
        },
        {
            lineTitle: "Atualiza estado da loja",
            lineState: SellerStatus,
            lineSetState: setSellerStatus
        },
    ]

    const realocationSetupData = [
        {
            lineTitle: "Experience",
            lineState: experience,
            lineSetState: setExperience
        },
        {
            lineTitle: "Somente CD",
            lineState: onlyCD,
            lineSetState: setOnlyCD
        },
        {
            lineTitle: "Checagem de tempo de entrega",
            lineState: shippingEstimateCheck,
            lineSetState: setShippingEstimateCheck
        },
        {
            lineTitle: "Checagem de custo",
            lineState: costCheck,
            lineSetState: setcostCheck
        },
        {
            lineTitle: "Checagem de performance",
            lineState: indicatorsCheck,
            lineSetState: setindicatorsCheck
        }
    ]

    const realocationSignals = [
        {
            lineTitle: "Custo",
            firstlineState: costGreen,
            firstlineSetState: setCostGreen,
            lastlineState: costYellow,
            lastlineSetState: setCostYellow
        },
        {
            lineTitle: "Experiência",
            firstlineState: experienceGreen,
            firstlineSetState: setExperienceGreen,
            lastlineState: experienceYellow,
            lastlineSetState: setExperienceYellow
        },
    ]

    const realocationDistribution = [
        {
            lineTitle: "Comissão",
            firstlineState: commission,
            firstlineSetState: setComission,
        },
        {
            lineTitle: "Distribuição",
            firstlineState: distribution,
            firstlineSetState: setDistribution,
            selectItem: stateToChoose,
            setSelectItem: setStateToChoose
        },
    ]

    const scenariosOptions = [
        {
            key: 'Conservador',
            value: 'conservative',
        },
        {
            key: 'Normal',
            value: 'normal',
        },
        {
            key: 'Agressivo',
            value: 'aggressive',
        },
    ]

    /**
     *  Get data Functions 
    */

    const getCommission = useCallback(() => {
        setLoadingRealocation(true);

        getCommissionByBrandId(brand)
        .then(response => {
            setComission(response.commission);
        })
        .catch(err => {
            toast.error(err.response.data.error ? err.response.data.error : 'Ocorreu um erro ao carregar os dados de comissão');
        }).finally(() => {
            setLoadingRealocation(false);
        })
    }, [brand]);

    const getDistribution = useCallback(() => {
        setLoadingRealocation(true);

        getDistributionByBrandId(brand, stateToChoose)
        .then(response => {
            setDistribution(response.value);
        })
        .catch(err => {
            toast.error(err.response.data.error ? err.response.data.error : 'Ocorreu um erro ao carregar os dados de distribuição');
        }).finally(() => {
            setLoadingRealocation(false);
        })
    }, [brand, stateToChoose]);

    const getRealocationData = useCallback(() => {
        setLoadingRealocation(true);

        getOrchestratorRealocationDataByBrandId(brand)
        .then(response => {
            setExperience(response.isExperiencePriorized);
            setOnlyCD(response.isOnlyCDConsidered);
            setShippingEstimateCheck(response.shippingEstimateCheck);
            setcostCheck(response.costCheck);
            setindicatorsCheck(response.indicatorsCheck);

            setCostGreen(response.costGreen);
            setCostYellow(response.costYellow);
            setExperienceGreen(response.ShippingEstimateGreen);
            setExperienceYellow(response.ShippingEstimateYellow);
        })
        .catch(err => {
            toast.error(err.response.data.error ? err.response.data.error : 'Ocorreu um erro ao carregar os dados de orquestrador');
        }).finally(() => {
            setLoadingRealocation(false);
        })
    }, [brand]);

    const getGeneralData = useCallback(() => {
        setLoadingGeneral(true);

        getOrchestratorGeneralDataByBrandId(brand)
        .then(response => {
            setCanceledRealocation(response.canceledRealocationIsActive);
            setRecentRealocation(response.recentRealocationIsActive);
            setLinxRealocation(response.linxRealocationIsActive);
            setSLARealocation(response.SlaRealocationIsActive);

            setUpdateCapacity(response.updateCapacityIsActive);
            setSellerStatus(response.sellerStatusIsActive);
            setUpdateComprehensive(response.updateComprehensiveIsActive);
            setUpdateDeflator(response.updateDeflatorIsActive);
        })
        .catch(err => {
            toast.error(err.response.data.error);
        }).finally(() => {
            setLoadingGeneral(false);
        })
    }, [brand]);

    const getManagementData = useCallback(() => {
        setLoadingGeneral(true);

        getOrchestratorManagementDataByBrandId(brand)
        .then(response => {
            const deflatorScenarios = {
                conservative: 0,
                normal: 0,
                aggressive: 0,
                undefined: 0,
            }

            const capacityScenarios = {
                conservative: 0,
                normal: 0,
                aggressive: 0,
                undefined: 0,
            }

            response.sellers.forEach(item => {
                item.deflatorScenario === 'conservative' ? deflatorScenarios.conservative += 1 : item.deflatorScenario === 'normal' ? deflatorScenarios.normal += 1 : item.deflatorScenario === 'aggressive' ? deflatorScenarios.aggressive += 1 : deflatorScenarios.undefined += 1
                item.capacityScenario === 'conservative' ? capacityScenarios.conservative += 1 : item.capacityScenario === 'normal' ? capacityScenarios.normal += 1 : item.capacityScenario === 'aggressive' ? capacityScenarios.aggressive += 1 : capacityScenarios.undefined += 1
            })

            setSellerManagementDeflator(deflatorScenarios);
            setSellerManagementCapacity(capacityScenarios);
        
        })
        .catch(err => {
            toast.error(err.response.data.error);
        }).finally(() => {
            setLoadingGeneral(false);
        })
    }, [brand]);

    /**
     *  Update data Functions 
    */

    const updateRealocationDistribution = async () => {
        try{
            setLoadingModalOpen(true)
    
            await updateOrchestratorCommissionByBrandId(brand, commission);
            await updateOrchestratorDistributionByBrandId(brand, stateToChoose, distribution);

            toast.success("Regras de realocação ajustadas com sucesso");
            setLoadingModalOpen(false)
            getDistribution();
            getCommission();
        }catch(err){
            toast.error(err.response.data.error);
            setLoadingModalOpen(false)
        }   
    }

    const updateRealocationSetup = async () => {
        try{
            setLoadingModalOpen(true)

            const dataToPersist = {
                isOnlyCDConsidered: onlyCD, 
                isExperiencePriorized: experience, 
                ShippingEstimateGreen: experienceGreen, 
                ShippingEstimateYellow: experienceYellow, 
                costGreen: costGreen,  
                costYellow: costYellow, 
                shippingEstimateCheck: shippingEstimateCheck, 
                costCheck: costCheck, 
                indicatorsCheck: indicatorsCheck
            }
    
            await updateOrchestratorRealocationDataByBrandId(brand, dataToPersist);
            toast.success("Regras de realocação ajustadas com sucesso");
            setLoadingModalOpen(false)
            getRealocationData();
        }catch(err){
            toast.error(err.response.data.error);
            setLoadingModalOpen(false)
        }   
    }

    const updateGeneralSetup = async () => {
        try{
            setLoadingModalOpen(true)
            const dataToPersist = {
                canceledRealocationIsActive: CanceledRealocation,
                linxRealocationIsActive: LinxRealocation,
                SlaRealocationIsActive: SLARealocation,
                recentRealocationIsActive: RecentRealocation,
                updateCapacityIsActive: UpdateCapacity,
                sellerStatusIsActive: SellerStatus,
                updateComprehensiveIsActive: UpdateComprehensive,
                updateDeflatorIsActive: UpdateDeflator
            }
    
            await updateOrchestratorGeneralDataByBrandId(brand, dataToPersist);
            toast.success("Regras gerais do orquestrador atualizadas com sucesso");
            setLoadingModalOpen(false)
            getGeneralData();
        }catch(err){
            toast.error(err.response.data.error);
            setLoadingModalOpen(false)
        }
    }

    const updateDeflatoScenario = async (scenario) => {
        try{
            setLoadingModalOpen(true)
            await updateOrchestratorDeflatorScenarioByBrandId(brand, scenario);

            toast.success(`Cenário de deflator alterado para ${scenario === 'conservative' ? 'conservador' : scenario === 'aggressive' ? 'agressivo' : scenario}`);
            setLoadingModalOpen(false)
            getManagementData();
        }catch(err){
            toast.error(err.response.data.error);
            setLoadingModalOpen(false)
        }
    }

    const updateCapacityScenario = async (scenario) => {
        try{
            setLoadingModalOpen(true)
            await updateOrchestratorCapacityScenarioByBrandId(brand, scenario);

            toast.success(`Cenário de capacidade alterado para ${scenario === 'conservative' ? 'conservador' : scenario === 'aggressive' ? 'agressivo' : scenario}`);
            setLoadingModalOpen(false)
            getManagementData();
        }catch(err){
            toast.error(err.response.data.error);
            setLoadingModalOpen(false)
        } 
    }

    /**
     * Update render component 
    */

    useEffect(() => {
        getRealocationData();
        getGeneralData();
        getManagementData();
        getDistribution();
        getCommission();
    }, [brand, getRealocationData, getGeneralData, getManagementData, getDistribution, getCommission]);

    return(
        <>
            <div style={PageBackground}>
                <div style={pageGrid}>
                    {/* <Grid container justify='space-between' alignItems='center' className={classes.headerContainer}>
                        <Grid item container className={classes.autoWidth}>
                            <Typography className={classes.title}>
                                Configurações do Orquestrador
                            </Typography>
                        </Grid>
                    </Grid> */}

                    <Grid container justify='space-between' alignItems='center' className={classes.headerContainer}>
                        <Grid item container className={classes.autoWidth}>
                            <Typography className={classes.title}>
                                Configurações do Orquestrador
                            </Typography>
                        </Grid>

                        <Grid item container spacing={2} className={classes.autoWidth}>
                            <Grid item>
                                <Link to={{ pathname: '/orchestrator/scenarios' }} className={classes.retireTextDecoration}>
                                    <Button variant="contained" size="medium" className={classes.button}>
                                        Cenários
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider className={classes.dividerPrincipal} />

                    <Grid container xs={12} spacing={2} direction='row' alignItems='center' justify='space-between' className={classes.headerContainer}>
                        <Grid item className={classes.contentTextField}>
                            <AutoCompleteChooseBrand 
                                title={"Marca"}
                                itemsToChoose={SOMABRANDS}
                                brand={brand}
                                setBrand={setBrand}
                                storageName={"@maisomni:favoriteBrand"}
                            />
                        </Grid>
                    </Grid>

                    <Divider className={classes.dividerPrincipal} />

                    <Grid container justify='space-between' alignItems='center' className={classes.headerContainer}>
                        <Grid item container className={classes.autoWidth}>
                            <Typography className={classes.subtitle}>
                                Panorama Geral
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container xs={12} spacing={2} style={{ margin: '5px 0' }} justify="space-between" alignItems='flex-start' direction='row'>
                        <Grid item xs={6}>
                            {loadingGeneral === true ? 
                                <Skeleton 
                                    variant="rect" 
                                    className={classes.skeletonContainer}
                                    width="100%" 
                                    height={'auto'} 
                                />
                            :
                                <RealocationSetup 
                                    principalTitle={"Critérios"}
                                    secondaryTitle={"Status"}
                                    dataArray={realocationArrayData}
                                />
                            }
                            
                        </Grid>

                        <Grid item xs={6}>
                            {loadingGeneral === true ? 
                                <Skeleton 
                                    variant="rect" 
                                    className={classes.skeletonContainer} 
                                    width="100%" 
                                    height={'auto'} 
                                />
                            :
                                <RealocationSetup 
                                    principalTitle={"Critérios"}
                                    secondaryTitle={"Status"}
                                    dataArray={sellerManagementArrayData}
                                />
                            }
                        </Grid>
                    </Grid>

                    <Grid container justify='flex-end' alignItems='center' style={{ marginBottom: '15px' }}>
                        {loadingGeneral === true ? 
                            <Skeleton 
                                variant="rect" 
                                className={classes.skeletonButton} 
                                width="240px" 
                                height={'auto'} 
                            />
                        :
                            <Button onClick={updateGeneralSetup} variant="contained" size="medium" className={classes.createStoreButton}>
                                Salvar
                            </Button>
                        }
                    </Grid>

                    <Divider className={classes.dividerPrincipal} />

                    <Grid container justify='space-between' alignItems='center' className={classes.headerContainer}>
                        <Grid item container className={classes.autoWidth}>
                            <Typography className={classes.subtitle}>
                                Panorama da realocação
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container xs={12} spacing={2} style={{ margin: '5px 0' }} justify="space-between" alignItems='flex-start' direction='row'>
                        <Grid item xs={6}>
                            {loadingRealocation === true ? 
                                <Skeleton 
                                    variant="rect" 
                                    className={classes.skeletonContainer} 
                                    width="100%" 
                                    height={'auto'} 
                                />
                            :
                                <RealocationSetup 
                                    principalTitle={"Critérios"}
                                    secondaryTitle={"Status"}
                                    dataArray={realocationSetupData}
                                />
                            }
                        </Grid>
                        <Grid item xs={6}>
                            {loadingRealocation === true ? 
                                <Skeleton 
                                    variant="rect" 
                                    className={classes.skeletonContainer} 
                                    width="100%" 
                                    height={'auto'} 
                                />
                            :
                                <Grid container item justify='flex-end' alignItems='flex-end' direction='column'>
                                    <RealocationSignals
                                        principalTitle={"Critérios"}
                                        firstTitle={"Filtro Verde"}
                                        lastTitle={"Filtro Amarelo"}
                                        dataArray={realocationSignals}
                                    />

                                    <Button onClick={updateRealocationSetup} variant="contained" size="medium" className={classes.createStoreButton}>
                                        Salvar
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                    <Grid container justify='space-between' alignItems='center' className={classes.headerContainer}>
                        <Grid item container className={classes.autoWidth}>
                            <Typography className={classes.subtitle}>
                                Custos da realocação
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container xs={12} spacing={2} style={{ margin: '5px 0' }} justify="space-between" alignItems='flex-start' direction='row'>
                        
                        <Grid item xs={6}>
                            {loadingRealocation === true ? 
                                <Skeleton 
                                    variant="rect" 
                                    className={classes.skeletonContainer} 
                                    width="100%" 
                                    height={'auto'} 
                                />
                            :
                                <Grid container item justify='flex-end' alignItems='flex-end' direction='column'>
                                    <RealocationDistribution
                                        principalTitle={"Critérios"}
                                        firstTitle={"ajuste"}
                                        dataArray={realocationDistribution}
                                        itemsToChoose={STATES}
                                    />

                                    <Button onClick={updateRealocationDistribution} variant="contained" size="medium" className={classes.createStoreButton}>
                                        Salvar
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                    <Divider className={classes.dividerPrincipal} />

                    <Grid container justify='space-between' alignItems='center' className={classes.headerContainer}>
                        <Grid item container className={classes.autoWidth}>
                            <Typography className={classes.subtitle}>
                                Panorama de gestão de sellers
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container xs={12} spacing={2} style={{ margin: '20px 0' }} justify="space-between" alignItems='flex-start' direction='row'>
                        <Grid item xs={6}>
                            {loadingRealocation === true ? 
                                <Skeleton 
                                    variant="rect" 
                                    className={classes.skeletonContainer} 
                                    width="100%" 
                                    height={'auto'} 
                                />
                            :       
                                <Grid container justify='center' alignItems='center' direction='column'>
                                    <Grid item container style={{ margin: '10px 0' }}>
                                        <AutoCompleteChooseBrand 
                                            title={"Cenários dos deflatores"}
                                            itemsToChoose={scenariosOptions}
                                            brand={scenarios}
                                            setBrand={setscenarios}
                                        />
                                    </Grid>
                                    <ContainerLines 
                                        principalTitle={"Cenários"}
                                        secondaryTitle={"Lojas"}
                                        dataArray={[
                                            {
                                                "lineTitle": "Conservador",
                                                "lineText": sellerManagementDeflator.conservative
                                            },
                                            {
                                                "lineTitle": "Normal",
                                                "lineText": sellerManagementDeflator.normal
                                            },
                                            {
                                                "lineTitle": "Agressivo",
                                                "lineText": sellerManagementDeflator.aggressive
                                            },
                                            {
                                                "lineTitle": "Indefinido",
                                                "lineText": sellerManagementDeflator.undefined
                                            }
                                        ]}
                                    />
                                    <Grid item container justify='flex-end' alignItems='center' style={{ margin: '10px 0' }}>
                                        <Button onClick={() => updateDeflatoScenario(scenarios)} variant="contained" size="medium" className={classes.saveDataButton}>
                                            Salvar
                                        </Button>
                                    </Grid>
                                </Grid>   
                            }
                        </Grid>
                        <Grid item xs={6}>
                            {loadingRealocation === true ? 
                                <Skeleton 
                                    variant="rect" 
                                    className={classes.skeletonContainer} 
                                    width="100%" 
                                    height={'auto'} 
                                />
                            :
                            <Grid container justify='center' alignItems='center' direction='column'>
                                <Grid item container style={{ margin: '10px 0' }}>
                                    <AutoCompleteChooseBrand 
                                        title={"Cenários da capacidade"}
                                        itemsToChoose={scenariosOptions}
                                        brand={capacityScenarios}
                                        setBrand={setCapacityScenarios}
                                    />
                                </Grid>
                                <ContainerLines 
                                    principalTitle={"Cenários"}
                                    secondaryTitle={"Lojas"}
                                    dataArray={[
                                        {
                                            "lineTitle": "Conservador",
                                            "lineText": sellerManagementCapacity.conservative
                                        },
                                        {
                                            "lineTitle": "Normal",
                                            "lineText": sellerManagementCapacity.normal
                                        },
                                        {
                                            "lineTitle": "Agressivo",
                                            "lineText": sellerManagementCapacity.aggressive
                                        },
                                        {
                                            "lineTitle": "Indefinido",
                                            "lineText": sellerManagementCapacity.undefined
                                        }
                                    ]}
                                />
                                <Grid item container justify='flex-end' alignItems='center' style={{ margin: '10px 0' }}>
                                    <Button onClick={() => updateCapacityScenario(capacityScenarios)} variant="contained" size="medium" className={classes.saveDataButton}>
                                        Salvar
                                    </Button>
                                </Grid>
                            </Grid> 
                            }
                        </Grid>
                    </Grid>
                </div>
                <Footer />

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover={true}
                />

                <LoadingModal
                    open={loadingModalOpen}
                    title={"Atualizando Dados..."}
                />
            </div>
        </>
    );
}