import {
  createMuiTheme
} from '@material-ui/core';

export const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#fff'
      }
    },
    MuiSelect: {
      iconOutlined: {
        color: '#fff'
      },
      outlined: {
        color: '#fff'
      }
    },
    MuiInputLabel: {
      outlined: {
        color: '#fff'
      },
      marginDense: {
        color: '#fff'
      }
    },
  },
});