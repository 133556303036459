import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from './context/authContext';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

export default function RouteWrapper({
    component: Component,
    isPrivate,
    isAdministrator,
    isLogistic,
    isShipping,
    isSupervisor,
    isEcommerce,
    isManagement,
    ...rest
}) {

  const { user, signOut } = useAuth();
  const history = useHistory();

  function redirectUser(user){
    const UTM = `?utm_campaign=${user.marca}&utm_medium=${getCargo(user)}&utm_source=${user.email}`

    if(user.permissions.isAdministrator){
      return `/dashboard${UTM}`
    }else if(user.permissions.isSupervisor){
      return `/dashboard/supervisor${UTM}`
    }else if(user.permissions.isEcommerce){
      return `/dashboard/ecommerce${UTM}`
    }else if(user.permissions.isManagement){
      return `/dashboard/management${UTM}`
    } 
  }

  function getCargo(user){
    if(user.permissions.isAdministrator){
      return `Administrador`
    }else if(user.permissions.isSupervisor){
      return `Supervisor`
    }else if(user.permissions.isEcommerce){
      return `E-commerce`
    }else if(user.permissions.isManagement){
      return `Management`
    } 
  }

  const signed = user ? true : false;

  //Redireciona se acessar a url sem logar
  if(!signed && isPrivate){
    return <Redirect to={{ pathname: '/' }} />
  }

  //Verifica se o token expirou
  if(signed){
    const now = moment(new Date()); // Data de hoje
    const past = moment(user.LoginTime); // Data de Login
    const duration = moment.duration(now.diff(past));

    // Mostra a diferença em horas
    const hours = duration.asHours();

    if(hours > 6){
      signOut(history);
    }
  }

  //Redireciona do login para as páginas iniciais
  if(signed && !isPrivate){
    const urlWithUTMS = redirectUser(user);

    return <Redirect to={`${urlWithUTMS}`} />
  }

  //Testa permissões quando se está autenticado e a rota é privada
  if(signed && isPrivate){
    const ADMINISTRATOR = user.permissions.isAdministrator;
    const SUPERVISOR = user.permissions.isSupervisor;
    const ECOMMERCE = user.permissions.isEcommerce;
    const MANAGEMENT = user.permissions.isManagement;

    const urlWithUTMS = redirectUser(user);

    if(!ADMINISTRATOR){
      if(SUPERVISOR){
        if(!isSupervisor) return <Redirect to={`${urlWithUTMS}`} />
      }

      if(ECOMMERCE){
        if(!isEcommerce) return <Redirect to={`${urlWithUTMS}`} />
      }

      if(MANAGEMENT){
        if(!isManagement) return <Redirect to={`${urlWithUTMS}`} />
      }
    }
  }

  return <Route {...rest} component={Component} />;
}

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    isAdministrator: PropTypes.bool,
    isLogistic: PropTypes.bool,
    isShipping: PropTypes.bool,
    isSupervisor: PropTypes.bool,
    isEcommerce: PropTypes.bool,
    isManagement: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

RouteWrapper.defaultProps = {
    isPrivate: false,
    isAdministrator: false,
    isLogistic: false,
    isShipping: false,
    isSupervisor: false,
    isEcommerce: false,
    isManagement: false
};