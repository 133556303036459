import React, { useCallback, useEffect, useState } from 'react';

/**
 * Libraries Imports
*/

import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Grid, Typography, Divider, Button} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

/**
 *  Components Imports
*/

import TextFieldIntegrationRules from '../../components/TextFieldIntegrationRules';
import AutoCompleteChooseBrand from '../../components/AutoCompleteChooseBrand';
import ChooseListItemsAndAdd from '../../components/ChooseListItemsAndAdd';
import Footer from '../../components/Footer';
import LoadingModal from '../../components/LoadingModal';
import DateContainer from '../../components/DateContainer';
import VisualizeItemsAdded from '../../components/VisualizeItemsAdded';
import TableContainer from '../../components/TableContainer';

/**
 *  Styles Imports
*/

import { 
    useStyles 
} from './styles';

import { 
    PageBackground, 
    pageGrid 
} from '../../stylesCSS';

/**
 *  Consts Imports
*/

import { 
    SomaGroupBrands 
} from '../../consts/brands';

/**
 * Services imports 
*/

import { getAllStoresByBrandId } from '../../services/stores';
import { getDeflatorSkusChange, putDeflatorOnSkus } from '../../services/deflators';

/**
 *  Principal Function Component
*/

export default function DeflatorSku(props) {
    const classes = useStyles(props);

    const tableHeaders = ["Data", "Loja", "Referência", "Deflator", "Usuário"];
    const [TableContent, setTableContent] = useState([]);
    const [ContentLength, SetContentLength] = useState(0);

    //Loading states
    const [loadingModalOpen, setLoadingModalOpen] = useState(false);
    const [loadingToGetReferenceDeflator, setLoadingToGetReferenceDeflator] = useState(false);

    //Deflator by reference
    const [deflatorValue, setDeflatorValue] = useState(0);
    const [AllStores, setAllStores] = useState([]);
    const [SelectedStores, setSelectedStores] = useState([]);
    const [SelectedReferences, setSelectedReferences] = useState([]);

    //Deflator change module
    const [Store, setStore] = useState(null);
    const [Reference, setReference] = useState(null);

    const [EndDate, setEndDate] = useState(moment(new Date()));
    const [StartDate, setStartDate] = useState(moment(new Date()).subtract(7, 'days'));


    //Brand states
    const SOMABRANDS = SomaGroupBrands.map(item => {
        return {
            key: item.brandName,
            value: item.brandId
        };
    });

    const [brand, setBrand] = React.useState(() => {
        const brandName = localStorage.getItem(`@maisomni:favoriteBrand`) ? localStorage.getItem(`@maisomni:favoriteBrand`) : "";
        const item = SOMABRANDS.find(item => item.key === brandName);
        return item !== undefined ? item.value : "";
    });


    const getAllStores = useCallback(async (brandId) => {
        const response = await getAllStoresByBrandId(brandId);
        return response;
    }, []);

    const dateDiferenceByDays = useCallback((date1, date2) => {
        const diferencaEmMilissegundos = date2.diff(date1);
        const diferencaEmDias = Math.floor(
          diferencaEmMilissegundos / (1000 * 60 * 60 * 24)
        );
      
        return diferencaEmDias;
    }, []);

    const getAllReferenceDeflatorChanges = useCallback(async (brandId, startDate, endDate, reference, store, type = "get") => {
        setLoadingToGetReferenceDeflator(true);
        try{
            const dateDiference = dateDiferenceByDays(startDate, endDate);

            if(dateDiference > 30){
                if(type === "update"){
                    setEndDate(moment(new Date()));
                    setStartDate(moment(new Date()).subtract(7, 'days'));
                }else{
                    toast.error("Não é possível pesquisar por mais de 30 dias");
                    setLoadingToGetReferenceDeflator(false);
                    return "Não é possível pesquisar por mais de 30 dias";
                }
            }

            const response = await getDeflatorSkusChange(brandId, startDate, endDate, reference, store);

            SetContentLength(response.length)

            const itemsWithAjustedDate = response.data.map(item => {
                const parsedDateTime = moment(item.date, 'YYYY-MM-DD HH:mm');
                const formattedDateTime = parsedDateTime.format('DD/MM/YYYY HH:mm');

                item.date = formattedDateTime;
                item.newDeflator = item.newDeflator === null ? "Retirou" : item.newDeflator

                return item;
            });

            setTableContent(itemsWithAjustedDate);
        }catch(err){
            toast.error(err.response.data.error);
        }

        setLoadingToGetReferenceDeflator(false);
    }, [dateDiferenceByDays]);

    const updateDeflatorReferences = useCallback(async (storesList, referencesList, deflatorValue) => {
        setLoadingModalOpen(true);
        try{
            const response = await putDeflatorOnSkus(storesList, referencesList, deflatorValue);


            setDeflatorValue(0);
            setAllStores([]);
            setSelectedStores([]);
            setSelectedReferences([]);

            await getAllReferenceDeflatorChanges(brand, StartDate, EndDate, Reference, Store, "update");

            setLoadingModalOpen(false);
            toast.success("Deflatores alterados com sucesso");
            return response;
        }catch(err){
            setLoadingModalOpen(false);
            toast.error(err.message);
        }
    }, [brand, StartDate, EndDate, Reference, Store, getAllReferenceDeflatorChanges]);

    const CleanFilters = useCallback(async (brand, StartDate, EndDate) => {
        setStore(null);
        setReference(null);

        await getAllReferenceDeflatorChanges(brand, StartDate, EndDate, null, null);
    }, [setStore, setReference, getAllReferenceDeflatorChanges]);

    useEffect(() => {
        (async () => {
            setLoadingToGetReferenceDeflator(true);
    
          try{
            const [StoresAllInformation, ] = await Promise.all([
                getAllStores(brand),
                getAllReferenceDeflatorChanges(brand, StartDate, EndDate, Reference, Store)
            ]);
    
            const OnlySellerId = StoresAllInformation.data.map(item => {
                return {
                    key: item.sellerId,
                    value: item.sellerId
                }
            });

            setAllStores(OnlySellerId);
          }catch(err){
            toast.error(err.response.data.error);
          }
    
          setLoadingToGetReferenceDeflator(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brand]);

    return(
        <>
            <div style={PageBackground}>
                <div style={pageGrid}>
                    <Grid container justify='space-between' alignItems='center' className={classes.headerContainer}>
                        <Grid item container className={classes.autoWidth}>
                            <Typography className={classes.title}>
                                Deflator por referência
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider className={classes.dividerPrincipal} />

                    <Grid container  direction='row' alignItems='center' justify='space-between' className={classes.headerContainer}>
                        {loadingToGetReferenceDeflator === true ? 
                            <> 
                                <Grid item className={classes.secondaryContentTextField}>
                                    <Skeleton 
                                        variant="rect" 
                                        className={classes.skeleton}  
                                        height={102} 
                                    />
                                </Grid>
                                <Grid item className={classes.principalContentTextField}>
                                    <Skeleton 
                                        variant="rect" 
                                        className={classes.skeleton}  
                                        height={102} 
                                    />
                                </Grid>
                                <Grid item className={classes.principalContentTextField}>
                                    <Skeleton 
                                        variant="rect" 
                                        className={classes.skeleton} 
                                        height={102} 
                                    />
                                </Grid>
                                <Grid item className={classes.secondaryContentTextField}>
                                    <Skeleton 
                                        variant="rect" 
                                        className={classes.skeleton}  
                                        height={102} 
                                    />
                                </Grid>
                            </>
                        :
                            <>
                                <Grid item className={classes.secondaryContentTextField}>
                                    <AutoCompleteChooseBrand 
                                        title={"Marca"}
                                        itemsToChoose={SOMABRANDS}
                                        brand={brand}
                                        setBrand={setBrand}
                                        storageName={"@maisomni:favoriteBrand"}
                                    />
                                </Grid>

                                <Grid item className={classes.principalContentTextField}>
                                    <ChooseListItemsAndAdd 
                                        title={"Adicionar lojas"}
                                        itemsToChoose={AllStores}
                                        setList={setSelectedStores}
                                    />
                                </Grid>

                                <Grid item className={classes.principalContentTextField}>
                                    <ChooseListItemsAndAdd 
                                        title={"Adicionar referências"}
                                        setList={setSelectedReferences}
                                        type="textField"
                                        mode="Double"
                                    />
                                </Grid>

                                <Grid item className={classes.secondaryContentTextField}>
                                    <TextFieldIntegrationRules 
                                        rows={1}
                                        objectToRender={deflatorValue}
                                        title={'Valor do Deflator'}
                                        stateToChange={setDeflatorValue}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>

                    <Grid container  direction='column' alignItems='center' justify='center' className={classes.headerContainer}>
                        <Grid item container style={{ marginBottom: 25 }}>
                            <VisualizeItemsAdded title={"Lojas"} listItems={SelectedStores} setListItems={setSelectedStores}/>
                        </Grid>
                        <Grid item container>
                            <VisualizeItemsAdded title={"Referências"} listItems={SelectedReferences} setListItems={setSelectedReferences}/>
                        </Grid>
                    </Grid>

                    <Grid container  direction='row' alignItems='center' justify='flex-end'>
                        <Button
                            onClick={() => deflatorValue === null ? setDeflatorValue(0) : setDeflatorValue(null)} 
                            variant='outlined' 
                            className={deflatorValue === null ? classes.buttonSelected : classes.button}
                        >
                            Retirar deflator
                        </Button>

                        {loadingToGetReferenceDeflator === true ?
                            <Skeleton 
                                variant="rect" 
                                className={classes.skeleton} 
                                width={160}
                                height={40}
                                style={{ marginLeft: 10 }} 
                            />
                        :
                            <Button
                                onClick={() => updateDeflatorReferences(SelectedStores, SelectedReferences, deflatorValue)} 
                                variant='outlined' 
                                className={classes.button}
                            >
                                Salvar
                            </Button>
                        }
                    </Grid>

                    <Grid container justify='space-between' alignItems='center' className={classes.headerContainer}>
                        <Grid item container className={classes.autoWidth}>
                            <Typography className={classes.title}>
                                Mudanças de Deflator
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider className={classes.dividerPrincipal} />

                    <Grid container  direction='row' alignItems='center' justify='space-between' className={classes.headerContainer}>
                        {loadingToGetReferenceDeflator === true ? 
                            <> 
                                <Grid item className={classes.principalContentTextField}>
                                    <Skeleton 
                                        variant="rect" 
                                        className={classes.skeleton}  
                                        height={102} 
                                    />
                                </Grid>
                                <Grid item className={classes.principalContentTextField}>
                                    <Skeleton 
                                        variant="rect" 
                                        className={classes.skeleton}  
                                        height={102} 
                                    />
                                </Grid>
                                <Grid item className={classes.principalContentTextField}>
                                    <Skeleton 
                                        variant="rect" 
                                        className={classes.skeleton} 
                                        height={102} 
                                    />
                                </Grid>
                            </>
                        :
                            <> 
                                <Grid item className={classes.principalContentTextField}>
                                    <AutoCompleteChooseBrand 
                                        title={"Loja"}
                                        itemsToChoose={AllStores}
                                        brand={Store}
                                        setBrand={setStore}
                                        saveOnStorageFlag={false}
                                    />
                                </Grid>
                                <Grid item className={classes.principalContentTextField}>
                                    <TextFieldIntegrationRules 
                                        rows={1}
                                        objectToRender={Reference}
                                        title={'Referência'}
                                        stateToChange={setReference}
                                    />
                                </Grid>
                                <Grid item className={classes.principalContentTextField}>
                                    <DateContainer 
                                        startDate={StartDate} 
                                        setStartDate={setStartDate} 
                                        endDate={EndDate} 
                                        setEndDate={setEndDate}
                                    />
                                </Grid>
                            </>
                        } 
                    </Grid>

                    <Grid container alignItems='center' justify='space-between'>
                        <Grid item className={classes.Typography}>
                            {ContentLength > TableContent.length ? 
                                <Typography variant="body1" gutterBottom>{`Existem ${ContentLength} registros. Os ${TableContent.length} últimos estão sendo exibidos`}</Typography>
                            : 
                                null
                            }
                        </Grid>

                        <Grid style={{ width: 400 }} item container  direction='row' alignItems='center' justify='flex-end'>
                            <Button
                                onClick={() => CleanFilters(brand, StartDate, EndDate)} 
                                variant='outlined' 
                                className={classes.button}
                            >
                                Limpar
                            </Button>
                            
                            {loadingToGetReferenceDeflator === true ?
                                <Skeleton 
                                    variant="rect" 
                                    className={classes.skeleton} 
                                    width={160}
                                    height={40}
                                    style={{ marginLeft: 10 }}
                                />
                            :
                                <Button 
                                    onClick={() => getAllReferenceDeflatorChanges(brand, StartDate, EndDate, Reference, Store)} 
                                    variant='outlined' 
                                    className={classes.button}
                                >
                                    Pesquisar
                                </Button>
                            } 
                        </Grid>
                    </Grid>

                    <Grid container  direction='row' alignItems='center' justify='space-between' className={classes.headerContainer}>
                        {loadingToGetReferenceDeflator === true ? 
                            <Skeleton 
                                variant="rect" 
                                className={classes.skeleton} 
                                width="100%" 
                                height={300} 
                            /> 
                        : 
                            <TableContainer headersArray={tableHeaders} rowsArray={TableContent}/>
                        } 
                    </Grid> 
                </div>
                <Footer />

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover={true}
                />

                <LoadingModal
                    open={loadingModalOpen}
                    title={"Atualizando Deflatores selecionados..."}
                />
            </div>
        </>
    );
}