import { 
  makeStyles,
  createMuiTheme
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  titleMenu: {
    fontSize: '0.875rem', 
    letterSpacing: '0.02857em', 
    fontWeight: 500, 
    marginRight: '5px',
    color: '#fff',
  },
  setColorWhite: {
    color: '#fff'
  }
}));

export const materialTheme = createMuiTheme({
  overrides: {
    MuiDataGrid: {
      root: {
        overflowX: 'visible',
        overflowY: 'hidden',
        border: 'none',
        textAlign: 'center'
      },
      window: {
        overflowX: 'visible !important',
        overflowY: 'hidden !important',
        color: 'blue',
        top: '20px'
      },
      cellLeft: {
        textAlign: 'none'
      }
    },
    MuiInput: {
      root: {
        color: '#fff'
      },
    },
    MuiInputBase: {
      input: {
        color: '#fff'
      },
    }
  },
});