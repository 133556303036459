import api from '../api';
import { AUTHORIZATIONTOKEN } from '../../consts/services';

export const getFiliaisInformation = async () => {
  const response = await api.get(`/branches`, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const postFiliaisInformation = async (state, brandId, filialCode) => {
  const response = await api.post(`/branches` , {
    state: state, 
    rede_lojas: brandId, 
    cod_filial: filialCode
  }, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const EditFiliaisInformation = async (state, brandId, filialCode) => {
  const response = await api.put(`/branches` , {
    state: state, 
    rede_lojas: brandId, 
    cod_filial: filialCode
  }, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}