import React from 'react';

import { Typography, Card, Grid, Divider, CardContent } from '@material-ui/core';
import { useStyles } from './styles';

export default function TableContainer({ headersArray, rowsArray }) {
    const classes = useStyles();

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Grid container spacing={2} justify="space-between" alignItems='center' direction='row'>
                    <Grid item container justify="space-between" alignItems='center' direction='row'>
                        {headersArray && headersArray.map(item => (
                            <Grid item key={item} style={{ width: `${100 / headersArray.length}%`, textAlign: 'center' }}>
                                <Typography variant='body1' className={classes.typography} gutterBottom> 
                                    {item}
                                </Typography>            
                            </Grid>
                        ))}
                    </Grid>

                    <Divider className={classes.divider} />


                    {rowsArray.length === 0 ?
                        <>
                            <Grid item container justify="space-between" alignItems='center' direction='row'>
                                <Grid item style={{ width: `100%`, textAlign: 'center' }}>
                                    <Typography variant='body1' className={classes.typographyNoRegisters} gutterBottom>
                                        Sem Registros
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                        </>

                    :
                        rowsArray.map(item => {
                            const renderedValues = [];
                                
                            for (const key in item) {
                                const value = item[key];
                                
                                renderedValues.push(
                                    <Grid item style={{ width: `${100 / headersArray.length}%`, textAlign: 'center' }}>
                                        <Typography variant='body1' className={classes.typography} gutterBottom>
                                            {value}
                                        </Typography>
                                    </Grid>
                                );
                            }

                            return (
                                <>
                                    <Grid item container justify="space-between" alignItems='center' direction='row' key={item}>
                                        {renderedValues}
                                    </Grid>
                                    <Divider className={classes.divider} />
                                </>
                            );
                        })
                    }
                </Grid>
            </CardContent>
        </Card>
    );
}
