import React, { useEffect, useState } from 'react';

import { 
  Grid, 
  ThemeProvider,
  Accordion,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Typography
} from '@material-ui/core';

import { 
  useStyles, 
  materialTheme 
} from './styles';

export default function ActionsInAccordionSummary({ 
  item, 
  setDataCheck, 
  dataCheck, 
  checkFlag 
}) {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if(dataCheck.length === 0){
      setChecked(false);
    }
  }, [dataCheck]);

  const handleCheckBox = (event) => {
    if(event.target.checked === true){
      const arr = [
        ...dataCheck,
        item,
      ]
      setDataCheck(arr)
    }else if(event.target.checked === false){
      const arr = dataCheck.filter(element => {return item.id !== element.id});
      setDataCheck(arr)
    }
    event.stopPropagation();
    setChecked(event.target.checked);
  };

  return (
    <div className={classes.root}>
      <ThemeProvider theme={materialTheme}>
        <Accordion 
          expanded={false} 
          defaultExpanded={true} 
          className={checkFlag === true ?
            classes.AccordionSelected 
            : 
            checked ? 
              classes.AccordionSelected 
              : 
              classes.Accordion
          }
        >
          <AccordionSummary>
            <FormControlLabel
              checked={checkFlag === true ? true : checked}
              aria-label="Acknowledge"
              onClick={(event) => handleCheckBox(event)}
              onFocus={(event) => event.stopPropagation()}
              control={<Checkbox color={"#fff"}/>}
              className={classes.boxCheck}
            /> 

            <Grid 
              container 
              justify="space-between" 
              alignItems="center" 
              style={{ width: '100%' }}
            >
              <Grid className={classes.boxStore}>
                <Grid container item className={classes.containerTitleStore}>
                  <Grid item style={{ minWidth: '150px' }}>
                    <Typography 
                      style={{ 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis', 
                        color: '#fff' 
                      }}
                    >
                      {item.sellerId}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classes.boxItem}>
                <Typography className={classes.headerTableTypography}>
                  {`${item.collectionsLength}`}
                </Typography>
              </Grid>

              <Grid className={classes.boxItem}>
                <Typography className={classes.headerTableTypography}>
                  {item.minimumPrice ? `R$ ${item.minimumPrice}` : 'nulo'}
                </Typography>
              </Grid>
              
              <Grid className={classes.boxItem}>
                <Typography className={classes.headerTableTypography}>
                  {item.integrateProductsLength ? `${item.integrateProductsLength}` : 'nulo'}
                </Typography>
              </Grid>

              <Grid className={classes.boxItem}>
                <Typography className={classes.headerTableTypography}>
                  {item.deleteProductsLength ? `${item.deleteProductsLength}` : 'nulo'}
                </Typography>
              </Grid>
              
              <Grid className={classes.boxItem}>
                <Typography className={classes.headerTableTypography}>
                  {item.tablePrice ? `${item.tablePrice}` : 'nulo'}
                </Typography>
              </Grid>   
            </Grid>
          </AccordionSummary>
        </Accordion>
      </ThemeProvider>
    </div>
  );
}