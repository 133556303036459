import React, { useState } from 'react';

import { 
  setStore 
} from '../../services/storeData';

import { 
  materialTheme, 
  useStyles 
} from './styles';

import LoadingModal from '../LoadingModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Typography,
    ThemeProvider,
    TextField,
    MenuItem
} from '@material-ui/core';

import { MODE, REASONS } from '../../consts/sellerStatus';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, setOpen, data }) {
    const classes = useStyles();
    const [loadingModalOpen, setLoadingModalOpen] = useState(false);
    const [modeOption, setModeOption] = useState('activate');
    const [reasonOption, setReasonOption] = useState('');
    const [message, setMessage] = useState('');

    const onClose = () => {
      setOpen(false);
    }

    const handleChangeReason = (event) => {
      setReasonOption(event.target.value);
    };

    const setStoreFunction = async (seller, status, message) => {
      const response = await setStore(seller, status, message);
      return response;
    }

    const handleChangeMode = (event) => {
      setModeOption(event.target.value);
    };

    const handleChangeMessage = (event) => {
      setMessage(event.target.value);
    };

    const submitConfigurations = async (data, message) => {
      setLoadingModalOpen(true);

      await Promise.all(
        data.map(async item => {
            try{
                await setStoreFunction(item.loja, modeOption, message)
            }catch(err){
                toast.error(`Erro ao realizar atualização da ${item.loja}`)
            }
        })
      );

      toast.success("Lojas atualizadas com sucesso");
      setLoadingModalOpen(false);
    }

    return (
      <div>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => onClose()}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            maxWidth='sm' 
            fullWidth={false}
          >
            <ThemeProvider theme={materialTheme}>
                <DialogTitle 
                  className={classes.BoxDialog} 
                  style={{ textAlign: 'center', fontSize: 18 }} 
                  id="alert-dialog-slide-title"
                >
                  <Grid 
                    container 
                    alignItems="center" 
                    justify="space-between" 
                    direction="row"
                  >
                    <Grid 
                      item
                      className={classes.ItemHeader}
                    >
                      <Typography className={classes.Title}>
                        Configurações de loja
                      </Typography>
                      <Typography className={classes.Subtitle}>
                        {`Você selecionou ${data.length} lojas`}
                      </Typography>
                    </Grid>              
                  </Grid>
                </DialogTitle>
              <DialogContent className={classes.BoxDialog}>
                <DialogContentText id="alert-dialog-slide-description">
                  <Grid 
                    container 
                    className={classes.smallMargin} 
                    direction="column" 
                    justify="center" 
                    alignItems="center"
                  >
                    <Grid item>
                      <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        label="Configuração"
                        value={modeOption}
                        onChange={handleChangeMode}
                        variant="outlined"
                        style={{ width: 400, marginBottom: 20 }}
                      >
                        {MODE.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item>
                      <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        disabled={message.length > 0 ? true : false}
                        label="Rasões"
                        value={reasonOption}
                        onChange={handleChangeReason}
                        variant="outlined"
                        style={{ width: 400, marginBottom: 20 }}
                      >
                        {REASONS.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    {reasonOption === 'Outros' ? 
                      <Grid item>
                        <TextField
                          id="outlined-currency"
                          size="small"
                          value={message}
                          onChange={(e) => handleChangeMessage(e)}
                          variant="outlined"
                          style={{ width: 400, marginBottom: 20 }}
                          placeholder="Digite a rasão da mudança"
                          helperText={`${message.length} de 180 caracteres`}
                          margin="dense"
                        />
                      </Grid>
                    : null}
                  </Grid>

                  <Grid 
                    className={classes.smallMargin} 
                    container 
                    justify="flex-end" 
                    alignItems="center"
                  >
                    <Button 
                      className={classes.Button} 
                      onClick={() => onClose()}
                    >
                      Voltar
                    </Button>
                    <Button 
                      className={classes.Button} 
                      onClick={() => submitConfigurations(data, message.length > 0 ? message : reasonOption)}
                    >
                      Confirmar
                    </Button>
                  </Grid>
                </DialogContentText>
              </DialogContent>
            </ThemeProvider> 
          </Dialog>
        

        <LoadingModal
          open={loadingModalOpen}
          title={"Ajustando status da loja"}
        />

        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={true}
        />
      </div>
    );
}