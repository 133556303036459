import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {  
    Grid,
    Typography,
    TextField,
    ThemeProvider
} from '@material-ui/core';

import { 
    useStyles, 
    theme 
} from './styles';

export default function AutoCompleteChooseBrand({ title, itemsToChoose, brand, setBrand, saveOnStorageFlag = true, storageName = ""}) {
    const classes = useStyles();

    return (
        <>
            <Grid item container justify='flex-start' direction='column' className={classes.container}>
                <Grid item>
                    <Typography className={classes.title}> 
                        {title} 
                    </Typography>
                </Grid>
                <Grid item>
                    <ThemeProvider theme={theme}>
                        <Autocomplete
                            limitTags={1}
                            disableClearable
                            onChange={(event, newValue) => {
                                if(saveOnStorageFlag){
                                    localStorage.setItem(`${storageName}`, newValue.key);
                                }
                                setBrand(newValue.value);
                            }}
                            size="small"
                            id="autoCompleteChooseBrand"
                            options={itemsToChoose}
                            getOptionLabel={(option) => option.key ? option.key : ""}
                            defaultValue={() => {
                                return itemsToChoose.length > 0 ? itemsToChoose.find(item => item.value === brand) : "";
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" autoComplete='off'/>
                            )}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        </>
    );
}