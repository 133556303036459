import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {
  Grid,
  Button
} from '@material-ui/core';

import { 
  CSVLink 
} from "react-csv";

import { 
  AiFillQuestionCircle 
} from 'react-icons/ai';

import {
  MdAttachMoney 
} from 'react-icons/md';

import { 
  GiPodiumThird 
} from 'react-icons/gi';

import { 
  useStyles 
} from './styles';

import ModalInformation from '../../components/ModalSupervisorInformation';
import ModalMoreOrders from '../../components/ModalMoreOrders';
import ModalStoreRevenuesComponent from '../../components/ModalStoreRevenues';

export default function GroupModalsStoreInformation({ 
  selectedBrands,
  data
}) {
  useEffect(() => {}, [selectedBrands]);

  const classes = useStyles();
  const [ModalStoreRevenues, setModalStoreRevenues] = useState(false);
  const [modalMoreOrders, setModalMoreOrders] = useState(false);
  const [modalSupervisorInformation, setModalSupervisorinformation] = useState(false);

  return (
    <>
      <Grid container alignItems="center" justify="flex-end" style={{ width: '100%', margin: '10px auto' }}>
        <Button onClick={() => setModalStoreRevenues(true)} variant="outlined" className={classes.normalButton}>
          <MdAttachMoney size={25} style={{ color: '#fff' }}/>
        </Button>
        <Button onClick={() => setModalMoreOrders(true)} variant="outlined" className={classes.normalButton}>
          <GiPodiumThird size={25} style={{ color: '#fff' }}/>
        </Button>
        <Button onClick={() => setModalSupervisorinformation(true)} variant="outlined" className={classes.normalButton}>
          <AiFillQuestionCircle size={18} style={{ color: '#fff' }}/>
        </Button>
        <Button variant="outlined" className={classes.csvButton}>
          <CSVLink
            style={{ textDecoration:'none', color: '#fff' }}
            separator={";"} 
            data={data}
            filename={`indicadores-${moment(new Date()).format("DD/MM/YYYY")}.csv`}
            className="btn btn-primary"
            target="_blank"
          >
            csv
          </CSVLink>
        </Button>
      </Grid>

      <ModalInformation 
        open={modalSupervisorInformation} 
        setOpen={setModalSupervisorinformation}
      />

      <ModalMoreOrders 
        open={modalMoreOrders} 
        setOpen={setModalMoreOrders} 
        selectedBrands={selectedBrands} 
      />

      <ModalStoreRevenuesComponent 
        open={ModalStoreRevenues} 
        setOpen={setModalStoreRevenues}
        selectedBrands={selectedBrands}
      />
    </>
  );
}