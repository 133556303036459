import React from 'react';

import { 
  Grid, 
  CircularProgress, 
  Typography, 
  Dialog, 
  DialogContent, 
  DialogTitle 
} from '@material-ui/core';

import {
  useStyles 
} from './styles';

export default function LoadingModal({ 
  open, 
  title, 
  subtitle 
}) {
  const classes = useStyles();

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Grid container alignItems={'center'} justify={'center'} >
          <Grid item className={classes.BoxDialog}>
            {title}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container justify={'center'}>
          <Grid item>
            <CircularProgress className={classes.BoxDialog}></CircularProgress>
          </Grid>
        </Grid>
      </DialogContent>
      <Grid container alignItems={'center'} justify={'center'} >
        <Grid item>
          <Typography 
            className={classes.BoxDialog} 
            variant="subtitle2" 
            gutterBottom
          >
            {subtitle ? subtitle : ""}
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
}