import api from '../api';
import { AUTHORIZATIONTOKEN } from '../../consts/services';

export const ajustSellerScenarios = async (scenario, sellersArray) => {
  const response = await api.put(`/deflators/scenarios` , {
    scenario: scenario, 
    sellersArray: sellersArray,
  }, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const getDeflatorSkusChange = async (brandId, startDate, endDate, reference = null, store = null) => {

  let URL = `/deflators/skus/${brandId}?startDate=${startDate}&endDate=${endDate}`;

  if(reference !== null){
    URL = URL + `&reference=${reference}`
  }

  if(store !== null){
    URL = URL + `&sellerId=${store}`
  }

  const response = await api.get(URL , {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const putDeflatorOnSkus = async (stores, references, value) => {
  const response = await api.put(`/deflators/skus` , {
    stores: stores, 
    references: references,
    value: value
  }, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const fixDeflators = async (stores, value) => {
  const response = await api.put(`/deflators/fix` , {
    stores: stores,
    value: value
  }, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}