import React from 'react';

import {
  ThemeProvider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

import { 
  theme,
  useStyles
} from './styles';


export default function TextFieldIntegrationRules({rows, objectToRender, title, stateToChange, disabled = false}) {
  const classes = useStyles();

  const handleChangeField = (event, func) => {
    func(event.target.value)
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid item container justify='flex-start' direction='column' className={classes.container}>
          <Grid item>
            <Typography className={classes.title}> {title} </Typography>
          </Grid>
          <Grid item>
            <TextField
              id="outlined-multiline-static"
              size='small'
              multiline
              disabled={disabled}
              rows={rows}
              value={objectToRender === null ? "" : objectToRender}
              onChange={(e) => handleChangeField(e, stateToChange)}
              variant='outlined'
              style={{ width: '100%' }}
            />
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}