import api from '../api';
import { AUTHORIZATIONTOKEN } from '../../consts/services';

export const getDocksFromSeller = async (brandId) => {
  const response = await api.get(`/docks/sellers/${brandId}`, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const getOrchestratorDocks = async (brandId) => {
  const response = await api.get(`/docks/orchestrator/${brandId}`, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const getDocksCities = async () => {
  const response = await api.get(`/docks/cities`, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const getDocksStates = async () => {
  const response = await api.get(`/docks/states`, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const getDocksByBrand = async (brands, cidade, estado) => {
  const response = await api.post(`/docks` , {
    brands: brands,
    cidade: cidade,
    estado: estado,
    getAllDocksFlag: true,
  }, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const getAllCDDocks = async (brandId) => {
  const response = await api.get(`/docks/cd/${brandId}`, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const getCDDocksByBrand = async (brands, cidade, estado) => {
  const response = await api.post(`/docks/cd` , {
    brands: brands,
    cidade: cidade,
    estado: estado
  }, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const activeDock = async (seller, dockId) => {
  const response = await api.post(`/docks/active/${seller}?id=${dockId}`, {}, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const desactiveDock = async (seller, dockId) => {
  const response = await api.post(`/docks/desactive/${seller}?id=${dockId}`, {}, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}

export const changeDockTime = async (seller, id, dockTime) => {
  const response = await api.post(`/docks/change/${seller}` , {
    id: id,
    dockTime: dockTime
  }, {
    headers: { 'authorization': AUTHORIZATIONTOKEN }
  });

  return response.data;
}