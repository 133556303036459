import React, { useEffect, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';

import { 
    PageBackground, 
    pageGrid 
} from '../../stylesCSS';

import {  
    Grid,
    Typography,
    TextField,
    ThemeProvider,
    MenuItem,
    Button,
    Divider,
} from '@material-ui/core';

import { 
    materialTheme,
    useStyles 
} from './styles';

import Footer from '../../components/Footer';
import IndicatorsBox from '../../components/IndicatorsBox';
import TextFieldIntegrationRules from '../../components/TextFieldIntegrationRules';
import ModalIntegrationRules from '../../components/ModalIntegrationRules';

export default function IntegrationRulesDash() {
    let { state } = useLocation();
    const classes = useStyles();

    if(state === undefined || state.integrationRulesData.length === 0){
        state = { integrationRulesData: [] }
    }

    const [integrationRulesData, ] = useState(state.integrationRulesData);

    const [openModal, setOpenModal] = useState(false);

    const [sellerToPropagate, setSellerToPropagate] = useState(
        state.integrationRulesData.length === 1 ? integrationRulesData[0] : ''
    );

    const [collections, setCollections] = useState(sellerToPropagate.collections);
    const [minimumPrice, setMinimumPrice] = useState(sellerToPropagate.minimumPrice);
    const [integrateProducts, setIntegrateProducts] = useState(sellerToPropagate.integrateProducts);
    const [deleteProducts, setDeleteProducts] = useState(sellerToPropagate.deleteProducts);
    const [tablePrice, setTablePrice] = useState(sellerToPropagate.tablePrice);

    const handleChange = (event) => {
        setSellerToPropagate(event.target.value);
    };

    useEffect(() => {
        setCollections(sellerToPropagate.collections)
        setMinimumPrice(sellerToPropagate.minimumPrice)
        setIntegrateProducts(sellerToPropagate.integrateProducts)
        setDeleteProducts(sellerToPropagate.deleteProducts)
        setTablePrice(sellerToPropagate.tablePrice)
    }, [sellerToPropagate]);

    const objectToSend = () => {
        const array = [];

        array.push({
            "configuration": "EstoqueFiltroColecao",
            "value": collections
        })

        array.push({
            "configuration": "EstoqueFiltroPrecoMinimo",
            "value": minimumPrice
        })

        array.push({
            "configuration": "EstoqueFiltroProdutosForcar",
            "value": integrateProducts
        })

        array.push({
            "configuration": "EstoqueFiltroProdutosNaoEnviar",
            "value": deleteProducts
        })

        array.push({
            "configuration": "EstoqueFiltroTabelaPreco",
            "value": tablePrice
        })

        return array;
    }

    return(
        <>
            {integrationRulesData.length === 0 ?
                <Redirect to={{ pathname: '/integration' }} />
            :
                <div style={PageBackground}>
                    <div style={pageGrid}>
                        <ThemeProvider theme={materialTheme}>
                            {integrationRulesData.length === 1 ?
                                <>
                                    <Grid container style={{ padding: '20px 0' }}>
                                        <Typography className={classes.title}>
                                            {`Visão geral de ${sellerToPropagate.sellerId}`}
                                        </Typography>
                                    </Grid>

                                    <Grid container alignItems="center" justify="space-between">
                                        <IndicatorsBox 
                                            title="Coleções"
                                            value={sellerToPropagate.collectionsLength ? sellerToPropagate.collectionsLength : 0} 
                                            loading={false} 
                                        />
                                        <IndicatorsBox 
                                            title="Preço Mínimo" 
                                            value={sellerToPropagate.minimumPrice} 
                                            loading={false} 
                                        />
                                        <IndicatorsBox 
                                            title="Produtos forçados" 
                                            value={sellerToPropagate.integrateProductsLength ? sellerToPropagate.integrateProductsLength : 0} 
                                            loading={false} 
                                        />
                                        <IndicatorsBox 
                                            title="Produtos Excluídos" 
                                            value={sellerToPropagate.deleteProductsLength ? sellerToPropagate.deleteProductsLength : 0} 
                                            loading={false} 
                                        />
                                        <IndicatorsBox 
                                            title="Tabela preço" 
                                            value={sellerToPropagate.tablePrice} 
                                            loading={false} 
                                        />
                                    </Grid>
                                </>
                            : 
                                null
                            }

                            {integrationRulesData.length === 1 ? 
                                null 
                            : 
                                <>
                                    <Grid container style={{ padding: '15px 0' }} alignItems='center' justify='space-between' direction='row'>
                                        <Typography className={classes.title}>
                                            Carregar informações
                                        </Typography>

                                        <Typography className={classes.subTitle}>
                                            {`Foram selecionadas ${integrationRulesData.length} lojas`}
                                        </Typography>
                                    </Grid>

                                    <Grid container xs={12} justify='flex-start' alignItems='center' direction='row'>
                                        <Grid item>
                                            <TextField
                                                id="outlined-select-currency"
                                                size='small'
                                                select
                                                value={sellerToPropagate}
                                                placeholder="Selecionar seller"
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                style={{ width: '300px' }}
                                                >
                                                {integrationRulesData.map((option) => (
                                                    <MenuItem key={option.sellerId} value={option}>
                                                        {option.sellerId}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </>
                            }

                            <Grid 
                                container 
                                style={{ 
                                    width: '100%', 
                                    margin: '30px 0px 10px 0px', 
                                    background: '#b59494' 
                                }} 
                                alignItems="center"
                            >
                                <Divider light />
                            </Grid>

                            <Grid container style={{ padding: '15px 0' }} alignItems='center' justify='space-between' direction='row'>
                                <Typography className={classes.title}>
                                    Configurações
                                </Typography>

                                <Grid item>
                                    <Button onClick={() => setOpenModal(true)} variant="contained" style={{ background: '#373435' }}>alterar regras</Button>
                                </Grid>
                            </Grid>

                            <Grid container justify='space-between' alignItems='center' direction='row' spacing={2}>
                                <Grid item xs={6}>
                                    <TextFieldIntegrationRules 
                                        rows={1}
                                        objectToRender={collections}
                                        title={'Coleções'}
                                        stateToChange={setCollections}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextFieldIntegrationRules 
                                        rows={1}
                                        objectToRender={minimumPrice}
                                        title={'Preço Mínimo'}
                                        stateToChange={setMinimumPrice}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextFieldIntegrationRules 
                                        rows={1}
                                        objectToRender={tablePrice}
                                        title={'Tabela Preço'}
                                        stateToChange={setTablePrice}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container justify='space-between' alignItems='center' direction='row' spacing={2} style={{ padding: '12px 0' }}>
                                <Grid item xs={6}>
                                    <TextFieldIntegrationRules 
                                        rows={16}
                                        objectToRender={integrateProducts}
                                        title={'Forçar produtos'}
                                        stateToChange={setIntegrateProducts}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextFieldIntegrationRules 
                                        rows={16}
                                        objectToRender={deleteProducts}
                                        title={'Excluir produtos'}
                                        stateToChange={setDeleteProducts}
                                    />
                                </Grid>
                            </Grid>
                            <ModalIntegrationRules open={openModal} setOpen={setOpenModal} data={integrationRulesData}  obj={objectToSend()}/>
                        </ThemeProvider>
                    </div>
                    <Footer />
                </div>
            }
        </>
    );
}