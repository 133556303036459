import {
  makeStyles,
  createMuiTheme
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

export const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: '#fff',
        },
      },
      notchedOutline: {
        borderColor: '#fff'
      }
    },
    MuiSelect: {
      iconOutlined: {
        color: '#fff'
      },
      outlined: {
        color: '#fff'
      }
    },
    MuiInputLabel: {
      outlined: {
        color: '#fff'
      },
      marginDense: {
        color: '#fff'
      }
    },
    MuiTextField: {
      root: {
        margin: 8,
        width: '25ch'
      }
    }
  },
});