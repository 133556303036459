import { 
  makeStyles,
  createMuiTheme
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  BoxDialog: {
    background: '#212121',
    color: '#fff',
  },
  Button: {
    background: '#373435', 
    color: '#fff', 
    borderColor: '#fff',
    boxShadow: 'none',
    minWidth: 150,
    margin: '0 10px',

    '&:hover': {
      background: '#5c5758',
    }
  },
  Title: {
    textAlign: 'center', 
    fontSize: 16, 
    flex: 1
  },
  ItemHeader: {
    display: 'flex', 
    alignItems: 'center', 
    flexDirection: 'column'
  },
  textFieldContentFullwidth: {
    width: '100%',
    padding: '10px'
  },
  textFieldContent: {
    width: '50%',
    padding: '10px'
  }
}));

export const materialTheme = createMuiTheme({
    overrides: {
        MuiDialog: {
            paperFullWidth: {
                background: '#212121'
            }
        }
    },
});