const TOTAL = 5;

const DELAY = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const AUTHORIZATIONTOKENFUNCTION = async () => {
  for(let i = 0; i < TOTAL; i++){
    try{
      const token = localStorage.getItem('@maisomni:token');

      if(token != null){
        return token;
      }
    }catch{
      await DELAY(1000);
    }
  }


  return localStorage.getItem('@maisomni:token');
}

export const AUTHORIZATIONTOKEN = localStorage.getItem('@maisomni:token');
export const SELLERMANAGEMENTTOKEN = localStorage.getItem('@maisomni:sellerManagementToken');
export const ENCRIPTPASS = '3487c9329e3ac2bff02894383e82acba';