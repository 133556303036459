import React from 'react';

import {
  Switch,
  Grid
} from '@material-ui/core';

export default function ControlledSwitches({title, field, data, setData, originalArray}) {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);

    if(!event.target.checked){
      const array = data.filter(item => {
        return item.configuration !== field;
      })

      setData(array);
    }

    if(event.target.checked){
      const objectToAdd = originalArray.find(item => {
        return item.configuration === field;
      })

      if(objectToAdd !== undefined){
        const newArray = data.map(item => {return item});
        newArray.push(objectToAdd)
        setData(newArray)
      }
    }
  };

  return (
    <Grid item container style={{ padding: '0px 10px' }} justify='space-between' alignItems='center' direction='row'>
      <Grid item>
        {title}
      </Grid>
      <Grid item>
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Grid>
    </Grid>
  );
}
