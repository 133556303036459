import React from 'react';

import {  
  Grid,
  Typography
} from '@material-ui/core';

import { 
  useStyles 
} from './styles';

export default function TableBusinessIndicators({ 
  titleTable, 
  data 
}) {
  const classes = useStyles();

  return (
    <> 
      <Grid container className={classes.BoxTable}>
        <Grid 
          item 
          container 
          justify="space-between" 
          direction="row" 
          style={{ margin: '15px 0px' }}
        >
          <Grid 
            item 
            className={classes.boxItem} 
            style={{ 
              textAlign: 'left', 
              width: '12%', 
              paddingLeft: '5px' 
            }}
          >
            <Typography 
              className={classes.ItemTypography} 
              style={{ fontWeight: 700 }}
            >
              {titleTable}
            </Typography>
          </Grid>
          <Grid item className={classes.boxItem} style={{ width: '9%' }}>
            <Typography className={classes.ItemTypography}>
              TMA
            </Typography>
          </Grid>
          <Grid item className={classes.boxItem}>
            <Typography className={classes.ItemTypography}>
              Capturado
            </Typography>
          </Grid>
          <Grid item className={classes.boxItem}>
            <Typography className={classes.ItemTypography}>
              Faturado
            </Typography>
          </Grid>
          <Grid item className={classes.boxItem}>
            <Typography className={classes.ItemTypography}>
              Pendente
            </Typography>
          </Grid>
          <Grid item className={classes.boxItem}>
            <Typography className={classes.ItemTypography}>
              Cancelado
            </Typography>
          </Grid>
          <Grid item className={classes.boxItem} style={{ width: '17%' }}>
            <Typography 
              className={classes.ItemTypography} 
              style={{ fontSize: 14 }}
            >
              Cancelamento
            </Typography>
          </Grid>
        </Grid>

        
        {data && data.map(item => {
          return (
            <Grid 
              item 
              container 
              justify="space-between" 
              direction="row" 
              style={{ margin: '15px 0px' }}
            >
              <Grid 
                item 
                className={classes.boxItem} 
                style={{ 
                  textAlign: 'left', 
                  width: '12%', 
                  paddingLeft: '5px' 
                }}
              >
                <Typography className={classes.ItemTypography}>
                  {item.Title}
                </Typography>
              </Grid>
              <Grid item className={classes.boxItem} style={{ width: '9%' }}>
                <Typography className={classes.ItemTypography}>
                  {`${item.TMA} h`}
                </Typography>
              </Grid>
              <Grid item className={classes.boxItem}>
                <Typography className={classes.ItemTypography}>
                  {item.capturado}
                </Typography>
              </Grid>
              <Grid item className={classes.boxItem}>
                <Typography className={classes.ItemTypography}>
                  {item.faturado}
                </Typography>
              </Grid>
              <Grid item className={classes.boxItem}>
                <Typography className={classes.ItemTypography}>
                  {item.pendente}
                </Typography>
              </Grid>
              <Grid item className={classes.boxItem}>
                <Typography className={classes.ItemTypography}>
                  {item.cancelado}
                </Typography>
              </Grid>
              <Grid item className={classes.boxItem} style={{ width: '17%' }}>
                <Typography className={classes.ItemTypography}>
                  {`${item.cancelamento} %`}
                </Typography>
              </Grid>                    
            </Grid>
          )
        })}
      </Grid>
    </>
  );
}