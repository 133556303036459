import React, { useState, useEffect } from 'react';

/**
 * Styles imports 
*/

import { 
  useStyles, 
  materialTheme 
} from './styles';

/**
 * Libraries imports 
*/

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Material UI imports 
*/

import {
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Typography,
    ThemeProvider
} from '@material-ui/core';

/**
 * Components imports 
*/

import TextFieldIntegrationRules from '../../components/TextFieldIntegrationRules';
import AutoCompleteChooseBrand from '../../components/AutoCompleteChooseBrand';
import AutoCompleteChooseItem from '../../components/AutoCompleteChooseItem';
import AutoCompleteChooseMultipleItems from '../../components/AutoCompleteChooseMultipleItems';
import LoadingModal from '../LoadingModal';

/**
 * Context imports 
*/

import { useLogistic } from '../../context/logisticContext';

/**
 * Services imports 
*/

import { updateShippingPolicies } from '../../services/logisticSetup/shippingPolicies';
import { updateDocks } from '../../services/logisticSetup/docks';
import { updateWarehouses } from '../../services/logisticSetup/warehouses';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalEditSetupStore({ 
  open, 
  setOpen,
  atributtes,
  brand,
  type
}) {
    const classes = useStyles();
    const { getShippingPoliciesByBrandId } = useLogistic();
    const [loadingModalOpen, setLoadingModalOpen] = useState(false);

    const [idActive, setIdActive] = useState("");
    const [idToChoose, setIdToChoose] = useState("");

    //Commom
    const [idVtex, setIdVtex] = useState("");
    const [name, setName] = useState("");

    //ShippingPolicies
    const [method, setmethod] = useState("");

    //Docks
    const [costTime, setCostTime] = useState(0);
    const [costTimeOverhead, setCostTimeOverhead] = useState(0);
    const [dockShippingPolicie, setDockShippingPolicie] = useState([]);

    //warehouses
    const [cost, setCost] = useState(0);
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState("");

    //Traz a lista de todos os shippingPolicies
    const [allShippingPolicies, setAllShippingPolicies] = useState([]);

    useEffect(() => {
        if(atributtes.length !== 0){
            const itemsToSelect = atributtes.map(item => {
                return {
                    key: type === 3 ? item.dock.idVtex : item.idVtex,
                    value: type === 3 ? item.dock.idVtex :item.idVtex
                };
            });

            setIdToChoose(itemsToSelect);
        }
    }, [atributtes, type]);

    useEffect(() => {
        if(type === 2){
            const activeObject = atributtes.find(item => item.idVtex === idActive.key);

            if(activeObject !== undefined){
                setIdVtex(activeObject.idVtex)
                setName(activeObject.name)
                setCostTime(activeObject.costTime)
                setCostTimeOverhead(activeObject.costTimeOverhead);
    
                getShippingPoliciesByBrandId(brand)
                .then(response => {
                    const shippingPolicies = response.map(item => {
                        return {
                            key: item.idVtex,
                            value: item.idVtex
                        }
                    })

                    setDockShippingPolicie(activeObject.shippingPolicies);
                    setAllShippingPolicies(shippingPolicies);
                })
            }
        }

        if(type === 1){
            const activeObject = atributtes.find(item => item.idVtex === idActive);

            if(activeObject !== undefined){
                setIdVtex(activeObject.idVtex)
                setName(activeObject.name)
                setmethod(activeObject.shippingMethod)
            }
        }

        if(type === 3){
            const activeObject = atributtes.find(item => item.dock.idVtex === idActive);

            if(activeObject !== undefined){
                setIdVtex(activeObject.dock.idVtex)
                setCost(activeObject.cost)
                setDays(activeObject.days)
                setHours(activeObject.hours)
            }
        }
        
    }, [idActive, type, brand, atributtes, getShippingPoliciesByBrandId]);

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    const onClose = () => {
        setIdVtex("");
        setName("");
        setOpen(false);
    }
    
    const onSubmit = async () => {
        setLoadingModalOpen(true);

        try{
            if(type === 1){
                await updateShippingPolicies(idVtex, name, method, brand);
            }
    
            if(type === 2){
                await updateDocks(idVtex, name, costTime, costTimeOverhead, brand);
            }
    
            if(type === 3){
                await updateWarehouses(idVtex, days, hours, cost, brand);
            }
    
            setIdVtex("");
            setName("");
            setOpen(false);
    
            setLoadingModalOpen(false);
            toast.success("configurações realizadas com sucesso");
            await timeout(2500);
            window.location.reload();

        }catch(err){
            setLoadingModalOpen(false);
            toast.error(err.response.data.error);
        }
    }

    return (
        <>
            <ThemeProvider theme={materialTheme}>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => onClose()}
                    aria-labelledby="modalEditTitle"
                    aria-describedby="modalEditDescription"
                    fullWidth={true}
                >  
                    <DialogTitle 
                        className={classes.BoxDialog} 
                        style={{ textAlign: 'center', fontSize: 18 }} 
                        id="modalEditTitle"
                    >
                        <Grid container alignItems="center" justify="space-between" direction="row">
                            <Grid item className={classes.ItemHeader}>
                                <Typography className={classes.Title}>
                                    {`Editar ${type === 1? 'Transportadoras' : type === 2 ? 'Docas' : 'Docas vinculadas ao estoque'}`}
                                </Typography>
                            </Grid>              
                        </Grid>
                    </DialogTitle>
                    <DialogContent className={classes.BoxDialog}>
                        <DialogContentText id="modalEditDescription">
                            {type === 1 ?
                                <Grid container direction='column' justify='space-between' alignItems='center'>
                                    <Grid item className={classes.textFieldContentFullwidth}>
                                        <AutoCompleteChooseBrand 
                                            title={"Id da transportadora"}
                                            itemsToChoose={idToChoose}
                                            brand={idActive}
                                            setBrand={setIdActive}
                                            saveOnStorageFlag={false}
                                        />
                                    </Grid>
                                    <Grid item className={classes.textFieldContentFullwidth}>
                                        <TextFieldIntegrationRules 
                                            rows={1}
                                            objectToRender={name}
                                            title={'Nome'}
                                            stateToChange={setName}
                                        />
                                    </Grid>
                                    <Grid item className={classes.textFieldContentFullwidth}>
                                        <TextFieldIntegrationRules 
                                            rows={1}
                                            objectToRender={method}
                                            title={'Método de envio'}
                                            stateToChange={setmethod}
                                        />
                                    </Grid>
                                </Grid>
                            : type === 2 ?
                                <>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item className={classes.textFieldContentFullwidth}>
                                            <AutoCompleteChooseItem
                                                title={"Id da doca"}
                                                itemsToChoose={idToChoose}
                                                item={idActive}
                                                setItem={setIdActive}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item className={classes.textFieldContent}>
                                            <TextFieldIntegrationRules 
                                                rows={1}
                                                objectToRender={costTime}
                                                title={'custo'}
                                                stateToChange={setCostTime}
                                            />
                                        </Grid>
                                        <Grid item className={classes.textFieldContent}>
                                            <TextFieldIntegrationRules 
                                                rows={1}
                                                objectToRender={costTimeOverhead}
                                                title={'custo indireto'}
                                                stateToChange={setCostTimeOverhead}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item className={classes.textFieldContentFullwidth}>
                                            <TextFieldIntegrationRules 
                                                rows={1}
                                                objectToRender={name}
                                                title={'Nome'}
                                                stateToChange={setName}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item className={classes.textFieldContentFullwidth}>
                                            <AutoCompleteChooseMultipleItems 
                                                title={"Transportadora associada"}
                                                itemsToChoose={allShippingPolicies}
                                                setItems={setDockShippingPolicie}
                                                defaultValueFlag={true}
                                                defaultValue={dockShippingPolicie.filter(item => item.idVtex)}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            :
                                <>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item className={classes.textFieldContentFullwidth}>
                                            <AutoCompleteChooseBrand 
                                                title={"Id da doca"}
                                                itemsToChoose={idToChoose}
                                                brand={idActive}
                                                setBrand={setIdActive}
                                                saveOnStorageFlag={false}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item className={classes.textFieldContent}>
                                            <TextFieldIntegrationRules 
                                                rows={1}
                                                objectToRender={days}
                                                title={'Dias'}
                                                stateToChange={setDays}
                                            />
                                        </Grid>
                                        <Grid item className={classes.textFieldContent}>
                                            <TextFieldIntegrationRules 
                                                rows={1}
                                                objectToRender={hours}
                                                title={'Horas'}
                                                stateToChange={setHours}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item className={classes.textFieldContentFullwidth}>
                                            <TextFieldIntegrationRules 
                                                rows={1}
                                                objectToRender={cost}
                                                title={'Custo'}
                                                stateToChange={setCost}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        <Grid 
                            style={{ marginTop: 60 }} 
                            container 
                            justify="flex-end" 
                            alignItems="center"
                        >
                            <Grid item>
                                <Button className={classes.Button} onClick={() => onClose()}>
                                    Voltar
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button className={classes.Button} onClick={() => onSubmit()}>
                                    Confirmar
                                </Button>
                            </Grid>
                        </Grid>
                        </DialogContentText>
                    </DialogContent> 
                </Dialog>
            </ThemeProvider>

        <LoadingModal
          open={loadingModalOpen}
          title={"Ajustando configurações"}
        />

        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={false}
        />
      </>
    );
}