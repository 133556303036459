import api from "../../sellerManagementApi";
import { SELLERMANAGEMENTTOKEN, ENCRIPTPASS } from "../../../consts/services";
import CryptoJS from "crypto-js";

function decryptToken() {
  const bytes = CryptoJS.AES.decrypt(SELLERMANAGEMENTTOKEN, ENCRIPTPASS);
  return bytes.toString(CryptoJS.enc.Utf8);
}

export const getAllDocks = async (brandId) => {
  const response = await api.get(`/shippingStrategy/docks/${brandId}`, {
    headers: { authorization: decryptToken() },
  });

  return response.data;
};

export const addDocks = async (
  id,
  name,
  dockTime,
  timeFakeOverhead,
  brandId
) => {
  const response = await api.post(
    `/shippingStrategy/docks/${brandId}`,
    {
      id: id,
      name: name,
      dockTime: dockTime,
      timeFakeOverhead: timeFakeOverhead,
    },
    {
      headers: { authorization: decryptToken() },
    }
  );

  return response.data;
};

export const updateDocks = async (
  id,
  name,
  dockTime,
  timeFakeOverhead,
  brandId
) => {
  const response = await api.put(
    `/shippingStrategy/docks/${brandId}`,
    {
      id: id,
      name: name,
      dockTime: dockTime,
      timeFakeOverhead: timeFakeOverhead,
    },
    {
      headers: { authorization: decryptToken() },
    }
  );

  return response.data;
};

export const deleteDocks = async (id, brandId) => {
  const response = await api.delete(
    `/shippingStrategy/docks/${brandId}`,
    {
      data: { id: id },
    },
    {
      headers: { authorization: decryptToken() },
    }
  );

  return response.data;
};

export const createDock = async (sellerId, allDocks) => {
  const response = await api.post(
    `/logistic/docks/${sellerId}`,
    {
      allDocks: allDocks,
    },
    {
      headers: {
        authorization: decryptToken(),
      },
    }
  );

  return response.data;
};

//SaleChannel vinculation to dock

export const addSaleChannelsOnDocks = async (
  idDock,
  saleChannelArray,
  brandId
) => {
  const response = await api.post(
    `/shippingStrategy/dockSaleChannel/${brandId}`,
    {
      idDock: idDock,
      saleChannelArray: saleChannelArray,
    },
    {
      headers: { authorization: decryptToken() },
    }
  );

  return response.data;
};

export const deleteSaleChannelsOnDocks = async (
  idDock,
  saleChannelArray,
  brandId
) => {
  const response = await api.delete(
    `/shippingStrategy/dockSaleChannel/${brandId}`,
    {
      idDock: idDock,
      saleChannelArray: saleChannelArray,
    },
    {
      headers: { authorization: decryptToken() },
    }
  );

  return response.data;
};

//ShippingPolciie vinculation to dock

export const addShippingPolicieOnDocks = async (
  idDock,
  shippingPoliciesArray,
  brandId
) => {
  const response = await api.post(
    `/shippingStrategy/dockShippingPolicies/${brandId}`,
    {
      idDock: idDock,
      shippingPoliciesArray: shippingPoliciesArray,
    },
    {
      headers: { authorization: decryptToken() },
    }
  );

  return response.data;
};

export const deleteShippingPolicieOnDocks = async (
  idDock,
  shippingPoliciesArray,
  brandId
) => {
  const response = await api.delete(
    `/shippingStrategy/dockShippingPolicies/${brandId}`,
    {
      idDock: idDock,
      shippingPoliciesArray: shippingPoliciesArray,
    },
    {
      headers: { authorization: decryptToken() },
    }
  );

  return response.data;
};
