import React, { useEffect, useState } from 'react';

import { 
  useAuth 
} from '../../context/authContext';

import { 
  Autocomplete 
} from '@material-ui/lab';

import { 
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon
} from '@material-ui/icons';

import {
  ThemeProvider,
  Checkbox,
  TextField
} from '@material-ui/core';

import {
  themeMobile 
} from './styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AutoCompleteFilterBrands({ 
  UsersBrands, 
  setSelectedBrands, 
  defaultValue 
}) {

  const { user } = useAuth();
  const [brands , setBrands] = useState([]);

  useEffect(() => {
    setBrands(UsersBrands);
  }, [UsersBrands]);

  return (
    <>
      <ThemeProvider theme={themeMobile}>
        <Autocomplete
          multiple
          limitTags={2}
          size="small"
          onChange={(event, newValue) => {
            const array = newValue.map(item => item.rede_lojas);
            setSelectedBrands(array);
          }}
          id="checkboxes-tags-demo"
          options={brands}
          defaultValue={defaultValue ? 
              defaultValue
            :
              [{
                "name": user.marca,
                "rede_lojas": user.rede
              }]
          }
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
                {option.name}
            </React.Fragment>
          )}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Marcas"/>
          )}
        />
      </ThemeProvider>
    </>
  );
}