import api from "../../sellerManagementApi";
import { SELLERMANAGEMENTTOKEN, ENCRIPTPASS } from "../../../consts/services";
import CryptoJS from "crypto-js";

function decryptToken() {
  const bytes = CryptoJS.AES.decrypt(SELLERMANAGEMENTTOKEN, ENCRIPTPASS);
  return bytes.toString(CryptoJS.enc.Utf8);
}

export const getAllWarehouses = async (brandId) => {
  const response = await api.get(`/shippingStrategy/warehouses/${brandId}`, {
    headers: { authorization: decryptToken() },
  });

  return response.data;
};

export const addWarehouses = async (id, days, hours, cost, brandId) => {
  const response = await api.post(
    `/shippingStrategy/warehouses/${brandId}`,
    {
      id: id,
      days: days,
      hours: hours,
      cost: cost,
    },
    {
      headers: { authorization: decryptToken() },
    }
  );

  return response.data;
};

export const updateWarehouses = async (id, days, hours, cost, brandId) => {
  const response = await api.put(
    `/shippingStrategy/warehouses/${brandId}`,
    {
      id: id,
      days: days,
      hours: hours,
      cost: cost,
    },
    {
      headers: { authorization: decryptToken() },
    }
  );

  return response.data;
};

export const deleteWarehouses = async (id, brandId) => {
  const response = await api.delete(
    `/shippingStrategy/warehouses/${brandId}`,
    {
      data: { id: id },
    },
    {
      headers: { authorization: decryptToken() },
    }
  );

  return response.data;
};

export const createWareHouse = async (sellerId, allDocks) => {
  const response = await api.post(
    `/logistic/warehouse/${sellerId}`,
    {
      allDocks: allDocks,
    },
    {
      headers: {
        authorization: decryptToken(),
      },
    }
  );

  return response.data;
};
