import { 
  makeStyles,
  createMuiTheme
} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  boxItem: {
    textTransform: 'lowercase', 
    color: '#fff', 
    width: '13%',
  },
  Boxwhite: {
    color: '#fff'
  },
  play: {
    marginRight: '10px', 
    color: '#42a31c'
  },
  pause: {
    marginRight: '10px', 
    color: '#d62b2b'
  },
  store: {
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    color: '#fff'
  },
  filial: {
    color: '#808080', 
    fontSize: 12, 
    fontWeight: 500
  },
  boxStoreTypography: {
    display: 'flex', 
    flexDirection: 'column', 
    maxWidth: '160px'
  },
  selectedIndicatorBox: {
    textTransform: 'lowercase', 
    color: '#222', 
    width: '100%'
  },
  selectedIndicatorGrid: {
    display: 'flex', 
    padding: '15px 0', 
    color: '#fff'
  }
}));

export const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: '#fff',
        },
      },
      notchedOutline: {
        borderColor: '#fff'
      }
    },
    MuiSelect: {
      iconOutlined: {
        color: '#fff'
      },
      outlined: {
        color: '#fff'
      }
    },
    MuiInputLabel: {
      outlined: {
        color: '#fff'
      },
      marginDense: {
        color: '#fff'
      }
    },
    MuiTextField: {
      root: {
        margin: 8,
        width: '25ch'
      }
    }
  },
});