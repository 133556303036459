import React, { useEffect, useState, useCallback } from 'react';

/**
 * Libraries Imports
*/

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Material-Ui Imports
*/

import Skeleton from '@material-ui/lab/Skeleton';

import {  
    Grid,
    Typography,
    Button,
    Divider
} from '@material-ui/core';

/**
 *  Components Imports
*/

import RealocationDistribution from '../../components/RealocationDistribution';
import AutoCompleteChooseBrand from '../../components/AutoCompleteChooseBrand';
import Footer from '../../components/Footer';
import RealocationSignals from '../../components/realocationSignals';
import LoadingModal from '../../components/LoadingModal';

/**
 *  Styles Imports
*/

import {
    useStyles 
} from './styles';

import { 
    PageBackground, 
    pageGrid 
} from '../../stylesCSS';

/**
 *  Consts Imports
*/

import { 
    SomaGroupBrands 
} from '../../consts/brands';

/**
 *  Services Imports
*/

import { 
    getOrchestratorManagementDataByBrandId,
} from '../../services/orchestrator';

import { 
    ajustCapacityNumberScenarios, 
    ajustDeflatorNumberScenarios 
} from '../../services/scenarios';

/**
 *  Principal Function Component
*/

export default function ScenariosSetup(props) {
    const classes = useStyles(props);

    const [loading, setLoading] = useState(true);
    const [loadingModalOpen, setLoadingModalOpen] = useState(false);

    /**
     * Seller management states 
    */

    const [AllManagementData, setAllManagementData] = useState({});

    //Capacity States
    const [ConservativeCapacity, setConservativeCapacity] = useState(0);
    const [NormalCapacity, setNormalCapacity] = useState(0);
    const [AggressiveCapacity, setAggressiveCapacity] = useState(0);

    //Deflator States
    const [ConservativeDeflatorMinPercent, setConservativeDeflatorMinPercent] = useState(0);
    const [ConservativeDeflatorMaxPercent, setConservativeDeflatorMaxPercent] = useState(0);

    const [NormalDeflatorMinPercent, setNormalDeflatorMinPercent] = useState(0);
    const [NormalDeflatorMaxPercent, setNormalDeflatorMaxPercent] = useState(0);

    const [AggressiveDeflatorMinPercent, setAggressiveDeflatorMinPercent] = useState(0);
    const [AggressiveDeflatorMaxPercent, setAggressiveDeflatorMaxPercent] = useState(0);

    /**
     * Brand state
    */

    const SOMABRANDS = SomaGroupBrands.map(item => {
        return {
            key: item.brandName,
            value: item.brandId
        };
    });

    const [brand, setBrand] = useState(() => {
        const brandName = localStorage.getItem(`@maisomni:favoriteBrand`) ? localStorage.getItem(`@maisomni:favoriteBrand`) : "";
        const item = SOMABRANDS.find(item => item.key === brandName);
        return item !== undefined ? item.value : "";
    });

    /**
     * Data Arrays 
    */

    const deflatorDistributionScenarios = [
        {
            lineTitle: "Conservador",
            firstlineState: ConservativeDeflatorMinPercent,
            firstlineSetState: setConservativeDeflatorMinPercent,
            lastlineState: ConservativeDeflatorMaxPercent,
            lastlineSetState: setConservativeDeflatorMaxPercent
        },
        {
            lineTitle: "Normal",
            firstlineState: NormalDeflatorMinPercent,
            firstlineSetState: setNormalDeflatorMinPercent,
            lastlineState: NormalDeflatorMaxPercent,
            lastlineSetState: setNormalDeflatorMaxPercent
        },
        {
            lineTitle: "Agressivo",
            firstlineState: AggressiveDeflatorMinPercent,
            firstlineSetState: setAggressiveDeflatorMinPercent,
            lastlineState: AggressiveDeflatorMaxPercent,
            lastlineSetState: setAggressiveDeflatorMaxPercent
        },
    ]

    const capacityDistributionScenarios = [
        {
            lineTitle: "Conservador",
            firstlineState: ConservativeCapacity,
            firstlineSetState: setConservativeCapacity,
        },
        {
            lineTitle: "Normal",
            firstlineState: NormalCapacity,
            firstlineSetState: setNormalCapacity,
        },
        {
            lineTitle: "Agressivo",
            firstlineState: AggressiveCapacity,
            firstlineSetState: setAggressiveCapacity,
        },
    ]

    /**
     *  Get data Functions 
    */

    const getManagementData = useCallback(() => {
        setLoading(true);

        getOrchestratorManagementDataByBrandId(brand)
        .then(response => {
            const deflators = response.deflatorPatternScenario;
            const capacities = response.capacityPatternScenario;

            //Deflator updates
            setConservativeDeflatorMinPercent(deflators.conservative.minPercentage);
            setConservativeDeflatorMaxPercent(deflators.conservative.maxPercentage);
        
            setNormalDeflatorMinPercent(deflators.normal.minPercentage);
            setNormalDeflatorMaxPercent(deflators.normal.maxPercentage);
        
            setAggressiveDeflatorMinPercent(deflators.aggressive.minPercentage);
            setAggressiveDeflatorMaxPercent(deflators.aggressive.maxPercentage);

            //Capacity updates
            setConservativeCapacity(capacities.conservative)
            setNormalCapacity(capacities.normal)
            setAggressiveCapacity(capacities.aggressive);  
            
            setAllManagementData({
                deflators: deflators,
                capacities: capacities
            })
        })
        .catch(err => {
            toast.error(err.response.data.error);
        }).finally(() => {
            setLoading(false);
        })
    }, [brand]);

    /**
     *  Update data Functions 
    */

    const updateCapacityScenario = useCallback(async () => {
        try{
            setLoadingModalOpen(true);

            if(ConservativeCapacity !== AllManagementData.capacities.conservative){
                await ajustCapacityNumberScenarios('conservative', ConservativeCapacity, brand);
            }

            if(NormalCapacity !== AllManagementData.capacities.normal){
                await ajustCapacityNumberScenarios('normal', NormalCapacity, brand);
            }

            if(AggressiveCapacity !== AllManagementData.capacities.aggressive){
                await ajustCapacityNumberScenarios('aggressive', AggressiveCapacity, brand);
            }

            setLoadingModalOpen(false);
            toast.success(`Alteração dos valores dos cenários de capacidade realizadas com sucesso`);

            getManagementData();
        }catch(err){
            setLoadingModalOpen(false);
            toast.error(`Erro ao alterar os valores dos cenários de capacidade`);
        }
    }, [AllManagementData, ConservativeCapacity, NormalCapacity, AggressiveCapacity, brand, getManagementData]);

    const updateDeflatorScenario = useCallback(async () => {
        try{
            const managementDeflators = AllManagementData.deflators;
            setLoadingModalOpen(true);

            if(ConservativeDeflatorMinPercent !== managementDeflators.conservative.minPercentage || ConservativeDeflatorMaxPercent !== managementDeflators.conservative.maxPercentage){
                await ajustDeflatorNumberScenarios('conservative', ConservativeDeflatorMinPercent, ConservativeDeflatorMaxPercent, brand);
            }

            if(NormalDeflatorMinPercent !== managementDeflators.normal.minPercentage || NormalDeflatorMaxPercent !== managementDeflators.normal.maxPercentage){
                await ajustDeflatorNumberScenarios('normal', NormalDeflatorMinPercent, NormalDeflatorMaxPercent, brand);
            }

            if(AggressiveDeflatorMinPercent !== managementDeflators.aggressive.minPercentage || AggressiveDeflatorMaxPercent !== managementDeflators.aggressive.maxPercentage){
                await ajustDeflatorNumberScenarios('aggressive', AggressiveDeflatorMinPercent, AggressiveDeflatorMaxPercent, brand);
            }

            setLoadingModalOpen(false);
            toast.success(`Alteração dos valores dos cenários de deflator realizadas com sucesso`);

            getManagementData();
        }catch(err){
            setLoadingModalOpen(false);
            toast.error(`Erro ao alterar os valores dos cenários de deflator`);
        }
    }, [AllManagementData, ConservativeDeflatorMinPercent, ConservativeDeflatorMaxPercent, NormalDeflatorMinPercent, NormalDeflatorMaxPercent, AggressiveDeflatorMinPercent, AggressiveDeflatorMaxPercent, brand, getManagementData]);

    /**
     * Update render component 
    */

    useEffect(() => {
        getManagementData();
    }, [brand, getManagementData]);

    return(
        <>
            <div style={PageBackground}>
                <div style={pageGrid}>
                    <Grid container justify='space-between' alignItems='center' className={classes.headerContainer}>
                        <Grid item container className={classes.autoWidth}>
                            <Typography className={classes.title}>
                                Cenários do orquestrador
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider className={classes.dividerPrincipal} />

                    <Grid container xs={12} spacing={2} direction='row' alignItems='center' justify='space-between' className={classes.headerContainer}>
                        <Grid item className={classes.contentTextField}>
                            <AutoCompleteChooseBrand 
                                title={"Marca"}
                                itemsToChoose={SOMABRANDS}
                                brand={brand}
                                setBrand={setBrand}
                                storageName={"@maisomni:favoriteBrand"}
                            />
                        </Grid>
                    </Grid>

                    <Divider className={classes.dividerPrincipal} />

                    <Grid container justify='space-between' alignItems='center' className={classes.headerContainer}>
                        <Grid item container className={classes.autoWidth}>
                            <Typography className={classes.subtitle}>
                                Cenários
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container xs={12} spacing={2} style={{ margin: '5px 0' }} justify="space-between" alignItems='flex-start' direction='row'>
                        
                        <Grid item xs={6}>
                            {loading === true ? 
                                <Skeleton 
                                    variant="rect" 
                                    className={classes.skeletonContainer} 
                                    width="100%" 
                                    height={'auto'} 
                                />
                            :
                                <Grid container item justify='flex-end' alignItems='flex-end' direction='column'>
                                    <RealocationDistribution
                                        principalTitle={"Capacidade"}
                                        dataArray={capacityDistributionScenarios}
                                    />

                                    <Button onClick={updateCapacityScenario} variant="contained" size="medium" className={classes.createStoreButton}>
                                        Salvar
                                    </Button>
                                </Grid>
                            }
                        </Grid>

                        <Grid item xs={6}>
                            {loading === true ? 
                                <Skeleton 
                                    variant="rect" 
                                    className={classes.skeletonContainer} 
                                    width="100%" 
                                    height={'auto'} 
                                />
                            :
                                <Grid container item justify='flex-end' alignItems='flex-end' direction='column'>
                                    <RealocationSignals
                                        principalTitle={"Deflatores"}
                                        firstTitle={"Mínimo"}
                                        lastTitle={"Máximo"}
                                        dataArray={deflatorDistributionScenarios}
                                    />

                                    <Button onClick={updateDeflatorScenario} variant="contained" size="medium" className={classes.createStoreButton}>
                                        Salvar
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    
                </div>
                <Footer />

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover={true}
                />

                <LoadingModal
                    open={loadingModalOpen}
                    title={"Atualizando Dados..."}
                />
            </div>
        </>
    );
}