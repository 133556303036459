import {
    makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    title: {
        fontWeight: 600,
        fontSize: 30,
        color: '#fff',
    },
    autoWidth: {
        width: 'auto'
    },
    headerContainer: {
        padding: '15px 0',
    },
    Typography: {
        color: '#fff',
    },
    TextContainer: {
        paddingTop: '15px',
        color: '#fff'
    },
    principalContentTextField: {
        width: '30%',
    },
    secondaryContentTextField: {
        width: '18%',
    },
    dividerPrincipal: {
        background: '#373435',
        height: '3px'
    },
    button: {
        background: '#373435', 
        color: '#fff', 
        minWidth: 160, 
        width: 'auto', 
        height: 40,
        marginLeft: 10,

        '&:hover': { 
            border: '1px solid #fff',
            background: '#2b2b2b', 
        }
    },
    buttonSelected: {
        background: '#2b2b2b', 
        color: '#fff', 
        minWidth: 160, 
        width: 'auto', 
        height: 40,
        marginLeft: 10,
        border: '2px solid #fff'
    },
    skeleton: {
        background: '#373435',
        borderRadius: 8
    }
}));