import React, { useEffect, useState } from 'react';
import HomeDesk from '../../PagesDesktop/supervisorDashboard';
import HomeApp from '../../PagesMobile/supervisorDashboard';

const Pages = () => {
  const [isDesk, setDesk] = useState(true);
  useEffect(() => {
    setDesk(window.innerWidth > 768);
  }, []);
  if(isDesk === true){
    return <HomeDesk />
  }
  else{
    return <HomeApp />
  }
};

export default Pages;