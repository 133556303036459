import { createMuiTheme } from "@material-ui/core/styles";

export const styles = {
  root: {
    width: '100%',
    margin: '0 auto'
  },
  slideContainer: {
    paddingRight: '10px',
    width: 'auto'
  },
  slide: {
    padding: 15,
    minHeight: 100,
    color: '#fff',
  },
  slide1: {
    backgroundColor: '#FEA900',
  },
  slide2: {
    backgroundColor: '#B3DC4A',
  },
  slide3: {
    backgroundColor: '#6AC0FF',
  },
}

export const theme = createMuiTheme({
  overrides: {
    MuiChip: {
      outlinedPrimary: {
        color: '#fff',
        border: '1px solid #fff'
      }
    }
  },
});