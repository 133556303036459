import React from 'react';

import { 
  Autocomplete 
} from '@material-ui/lab';

import { 
  theme 
} from './styles';

import {
  Checkbox,
  TextField,
  ThemeProvider
} from '@material-ui/core';

import { 
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon
} from '@material-ui/icons';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags({ 
  supervisors, 
  setSelectedSupervisor, 
  defaultValue, 
  width
}) {

  return (
    <>
      <ThemeProvider theme={theme}>
        <Autocomplete
          multiple
          limitTags={2}
          size="small"
          onChange={(event, newValue) => {
            const array = newValue.map(item => item.supervisora);
            setSelectedSupervisor(array);
          }}
          id="checkboxes-tags-demo"
          options={supervisors}
          defaultValue={defaultValue ? 
              defaultValue
            :
              []
          }
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
                {option.name}
            </React.Fragment>
          )}
          style={{ width: width ? width : 500 }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Supervisores"/>
          )}
        />
      </ThemeProvider>
    </>
  );
}